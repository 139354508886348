import { Component, OnInit, Input, ViewChild, ViewEncapsulation, OnDestroy, computed, model, signal } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { forkJoin, Observable, Subject } from 'rxjs';
import { Client } from 'src/app/models/client';
import { DataSwapParams } from 'src/app/models/dataswap-params';
import { IntegrationIdentifier } from 'src/app/models/integration-identifier';
import { ClientIntegrationService } from 'src/app/services/client-integration.service';
import { LBADataswapService } from 'src/app/services/lba-dataswap.service';
import { Integration } from '../../models/integration';
import { IntegrationType } from '../../models/integration-type';
import { User } from '../../models/user';
import { DestinationEndpoint } from '../../models/destination-endpoint';
import { MonacoPageComponent } from '../monaco-editor/monaco-editor.component';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { ToastService } from '../../services/toast.service';
import { Toast, ToastType } from '../../models/toast';
import { timingSafeEqual } from 'crypto';
import { IdentifierService } from 'src/app/services/identifier.service';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { IntegrationInformation } from 'src/app/models/integration-information';
import { saveAs } from '@progress/kendo-file-saver';
import { DatePipe } from '@angular/common';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
const today = new Date();

@Component({
  selector: 'app-lba-dataswap',
  templateUrl: './lba-dataswap.component.html',
  styleUrls: ['./lba-dataswap.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ClientIntegrationService, LBADataswapService]
})
export class LBADataswapComponent implements OnInit, OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();

  @ViewChild('monaco') public monaco: MonacoPageComponent;
  @ViewChild('monaco1') public monaco1: MonacoPageComponent;
  @ViewChild('monaco2') public monaco2: MonacoPageComponent;
  @ViewChild('monaco3') public monaco3: MonacoPageComponent;

  constructor(
    private identifierService: IdentifierService,
    private lbaDataswapService: LBADataswapService,
    private toastService: ToastService,
    private route: ActivatedRoute,
    user: User
  ) {
    this.activeUser = user;
   }

  private sub: any;
  @Input() client: Client;
  @Input() integration: Integration;
  @Input() integrationIdentifiers: IntegrationIdentifier[];
  @Input() isInTab: boolean = false;
  credentialsLoaded: boolean = false;

  useAllOptions: boolean = true;
  monacoData0: string = '';
  monacoData1: string = '';
  monacoData2: string = '';
  monacoData3: string = '';
  displayMonaco0: boolean = false;
  displayMonaco1: boolean = false;
  displayMonaco2: boolean = false;
  displayMonaco3: boolean = false;
  exportType: string = 'file';

  clients: Client[] = [];
  selectedClient: Client;
  clientNameList: string[];
  clientSelected: boolean;
  filteredClients: Observable<string[]>;
  clientsFiltered: Observable<Client[]>;
  clientFormControl = new UntypedFormControl();

 // displayedIntegrationNameList: IntegrationInformation[];
  activeUser: User;
  selectedIntegration: Integration;
  integrationSelected: boolean;
  clientIntegrations: Integration[] = [];
  integrationsFiltered: Observable<Integration[]>;
  displayedIntegrationList: Integration[];
  integrationFormControl = new UntypedFormControl();

  destination: string;
  destinationListOptions = [];
  destinationList = [];
  destinationAllowedList = ["CSDataSwap", "LGDataSwap"]

  destinationFormControl = new UntypedFormControl();

  fileTypeList: string[] = ['applic', 'empl', 'emplia', 'branch', 'gl'];
  fileTypeInputValue = signal<string>('applic');
  filteredFileTypeOptions = computed(() => {
    const filterValue = this.fileTypeInputValue().toLowerCase();
    return this.fileTypeList.filter(option => option.toLowerCase().includes(filterValue));
  });


  latestNo: string;
  latestNoOptions = ['1', '2', '3'];
  latestNoOptionsGroup = ['1', '2', '3'];

  csDataSwapRoles: string[] = [
    "LOSTalker.Integration.CompenSafe.DataSwap",
    "LOSTalker.Integration.CompenSafe"
  ];

  lgDataSwapRoles: string[] = [
    "LOSTalker.Integration.LimeGear.DataSwap",
    "LOSTalker.Integration.LimeGear"
  ];

  loanNumber: string = '';

  messageGetLoan: string;
  messageGetFile: string;
  successGetLoan: boolean;
  successGetFile: boolean;

  //integrationIdentifier: IntegrationIdentifier;
  selectedIntegrationIds: IntegrationInformation[];
  selectedIdentifier: IntegrationInformation;

  clientAndIntegrationProvided: boolean = false;

  selectByLTClient: boolean = false;

  //integrationIdentifierFullList: IntegrationIdentifier[];
  displayedIntegrationInfoList: IntegrationInformation[] = [];
  integrationInformationList: IntegrationInformation[] = [];
  integrationInfoFilteredList: Observable<IntegrationInformation[]>;
  integrationInfoListFormControl = new UntypedFormControl();
  validDestination: boolean = false;
  loadingFinished: boolean = false;

  idSortDesc: SortDescriptor[] = [{ field: "identifierNameValue", dir: 'asc' }];

  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

  ngOnInit(): void {
    this.selectByLTClient = this.isInTab;
    if (undefined == this.client && undefined == this.integration) {
      this.identifierService.getIntegrationInformationForDataSwap()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(integrationInfo => {
          this.integrationInformationList = integrationInfo.filter(i => i.locked == false);
          this.configureIdentifierList();
      }, error => {
          this.loadingFinished = true;
      });
    }
    else {
      this.clients.push(this.client);
      this.clientIntegrations.push(this.integration);
      if (this.integrationIdentifiers != undefined && this.integrationIdentifiers.length > 0) {
        this.clientAndIntegrationProvided = true;
        this.resetMonaco();
        this.integrationInformationList = this.integrationIdentifiers.map(id => new IntegrationInformation(this.integration, id));
        this.configureIdentifierList();
      }
      else {
        this.clientAndIntegrationProvided = true;
        this.resetMonaco();
        this.identifierService.getIntegrationIdentifiers(this.integration.integrationId)
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(identifiers => {
            this.integrationInformationList = identifiers.map(id => new IntegrationInformation(this.integration, id));
            this.configureIdentifierList();
          }, error => {
            this.loadingFinished = true;
          });
      }
    }
  }

  configureIdentifierList(): void {

    if (this.integrationInformationList.length > 0) {

      this.integrationInformationList = orderBy(this.integrationInformationList, this.idSortDesc);

      if (!this.client) {
        this.clients = this.integrationInformationList.map(i => new Client(i.clientId, i.clientName));
        this.clients = this.clients.filter((v, i, a) => a.map(x => x.clientId).indexOf(v.clientId) === i);
      }

      if (this.destinationAllowedList.length > 0) {
        this.integrationInformationList = this.integrationInformationList.filter(x => this.destinationAllowedList.indexOf(x.destinationName) != -1);
      }

      if (!this.activeUser.checkAccess(this.csDataSwapRoles)) {
        this.integrationInformationList = this.integrationInformationList.filter(d => d.destinationName !== "CSDataSwap");
      }
      else {
        this.destinationListOptions.push("CompenSafe");
      }

      if (!this.activeUser.checkAccess(this.lgDataSwapRoles)) {
        this.integrationInformationList = this.integrationInformationList.filter(d => d.destinationName !== "LGDataSwap");
      }
      else {
        this.destinationListOptions.push('NexusVision');
      }

      this.clientsFiltered = this.clientFormControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this.filterClients(name) : this.clients.slice())
      );

      this.integrationsFiltered = this.integrationFormControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this.filterIntegrationNames(name) : this.displayedIntegrationList.slice())
      );

      this.integrationInfoFilteredList = this.integrationInfoListFormControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this.filterIntegrationIdentifierValues(name) : this.displayedIntegrationInfoList.slice())
      );

      if (this.client) {
        this.selectClient(this.client);
      }

      if(this.integration){
        this.selectIntegration(this.integration);
      }

      if (this.integrationInformationList.length == 1) {
        this.selectedIdentifier = this.integrationInformationList[0];
        this.setDestinationFromDestinationName();
      }
      else if (this.destinationListOptions.length == 1) {
        this.selectDestination(this.destinationListOptions[0]);
      }
    }
    else {
      this.toastService.toastCreate("No Available Identifiers Found for DataSwap Request", 'Danger', { autoClose: true, keepAfterRouteChange: false });
    }

    this.loadingFinished = true;
  }

  updateData(event: any, index: number) {
    if (index == 0) {
      this.monacoData0 = String(event);
    }
    else if (index == 1) {
      this.monacoData1 = String(event);
    }
    else if (index == 2) {
      this.monacoData2 = String(event);
    }
    else if (index == 3) {
      this.monacoData3 = String(event);
    }
  }

  // get FileType input
  getFileTypeValue(): string {
    return this.fileTypeInputValue();
  }

  // Update FileType from Input
  updateFileTypeInput(value: string) {
    this.fileTypeInputValue.set(value);
    this.resetMonaco();
  }

  // clear FileType Input Form Field
  clearFileTypeInput() {
    this.fileTypeInputValue.set('');
    this.resetMonaco();
  }

  // update FileType from dropdown
  onFileTypeOptionSelected(event: MatAutocompleteSelectedEvent) {
    const selectedValue = event.option.value;
    this.fileTypeInputValue.set(selectedValue);
    this.resetMonaco();
  }

  setLatestNo(latestNo: string): void {
    this.latestNo = latestNo;
    this.resetMonaco();
  }

  getIntegrations(): void {
    if (this.selectedClient) {
      let selectedIntegrations = this.integrationInformationList.filter(x => x.clientId == this.selectedClient.clientId);
      //remove duplicate integration information records
      this.clientIntegrations = selectedIntegrations.map(i => new Integration(i));
      this.clientIntegrations = this.clientIntegrations.filter((v, i, a) => a.map(x => x.integrationId).indexOf(v.integrationId) === i);
      this.displayedIntegrationList = this.clientIntegrations;
      this.integrationsFiltered = this.integrationFormControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.integrationName),
        map(integrationName => integrationName ? this.filterIntegrationNames(integrationName) : this.displayedIntegrationList.slice())
      );
    }
    else {
      var s = 'Invalid Client.';
      this.toastService.toastCreate(s, 'Danger', { autoClose: true, keepAfterRouteChange: false });
      this.selectedIdentifier = null;
    }


  }

  setDestinationFromDestinationName() {
    if (this.selectedIdentifier?.destinationName == 'LGDataSwap') {
      this.destination = 'NexusVision';
    }
    else if (this.selectedIdentifier?.destinationName == 'CSDataSwap') {
      this.destination = 'CompenSafe';
    }
    else{
      var s = 'Invalid destination.';
      this.toastService.toastCreate(s, 'Danger', { autoClose: true, keepAfterRouteChange: false });
    }
  }

  selectIdentifier(i: IntegrationInformation): void {
    this.selectedIdentifier = i;
    if(this.selectedIdentifier.identifierKeyValue == undefined){
      this.identifierService.getIntegrationIdentifierByIntegrationAndDestination(this.selectedIdentifier.integrationId, this.selectedIdentifier.destinationName)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(data => {
          this.selectedIdentifier.identifierKeyValue = data.identifierKeyValue;
          this.setDestinationFromDestinationName();
          this.resetMonaco();
          this.credentialsLoaded = true;
        });
    }
    else {
      this.setDestinationFromDestinationName();
      this.resetMonaco();
      this.credentialsLoaded = true;
    }
  }

  selectDestination(d: string) {
    this.selectedIdentifier = undefined;
    this.destination = d;
    let dest;
    if (this.destination == 'NexusVision') {
      dest = "LGDataSwap";
    }
    else if (this.destination == 'CompenSafe') {
      dest = 'CSDataSwap';
    }

    if (!this.integrationInfoListFormControl.value != this.integrationInfoListFormControl.defaultValue) {
      this.integrationInfoListFormControl.setValue('');
    }

    this.integrationInformationList = orderBy(this.integrationInformationList, this.idSortDesc);

    this.displayedIntegrationInfoList = this.integrationInformationList.filter(ii => ii.destinationName == dest);

    if (this.integrationInfoListFormControl.value != this.integrationInfoListFormControl.defaultValue){

      this.integrationInfoFilteredList = this.integrationInfoListFormControl.valueChanges.pipe(
        startWith(''),
        map(value => (value != undefined && typeof value === 'string') ? value : value.name),
        map(name => name ? this.filterIntegrationIdentifierValues(name) : this.displayedIntegrationInfoList.slice())
      );
    }

    this.validDestination = true;
  }

  selectClient(c: Client) {
    this.resetMonaco();
    this.selectedClient = c;
    this.clientSelected = true;
    this.selectedIntegration = null;
    this.getIntegrations();
  }

  selectIntegration(i: Integration) {
    this.resetMonaco();
    this.selectedIntegration = i;
    this.integrationSelected = true;
    this.integrationInformationList = orderBy(this.integrationInformationList, this.idSortDesc);
    this.displayedIntegrationInfoList = this.integrationInformationList.filter(ii => ii.integrationId == this.selectedIntegration.integrationId);
    if(this.displayedIntegrationInfoList.length == 1){
      this.selectIdentifier(this.displayedIntegrationInfoList[0]);
    }
    else {
      if (this.integrationInfoListFormControl.value != this.integrationInfoListFormControl.defaultValue) {

        this.integrationInfoFilteredList = this.integrationInfoListFormControl.valueChanges.pipe(
          startWith(''),
          map(value => (value != undefined && typeof value === 'string') ? value : value.name),
          map(name => name ? this.filterIntegrationIdentifierValues(name) : this.displayedIntegrationInfoList.slice())
        );
      }
    }
  }

  editClient() {
    this.clearVars();
  }

  onTabSelect() {
    this.clearVars();
  }

  clearVars() {
    if (undefined == this.client && undefined == this.integration) {
      this.selectedClient = null;
      this.selectedIntegration = null;
      this.clientSelected = false;
      this.integrationSelected = false;
    }

    this.monacoData0 = '';
    this.monacoData1 = '';
    this.monacoData2 = '';
    this.monacoData3 = '';
    this.integrationFormControl.setValue('');
    this.clientFormControl.setValue('');
    this.integrationInfoListFormControl.setValue('');
    this.displayMonaco0 = false;
    this.displayMonaco1 = false;
    this.displayMonaco2 = false;
    this.displayMonaco3 = false;

    if (this.integrationInformationList?.length != 1) {
      this.selectedIdentifier = null;
    }

    if (this.destinationListOptions.length > 1) {
      this.validDestination = false;
      this.destination = '';
    }
  }

  editIntegration() {
    this.integration = null;
    this.integrationSelected = false;

    this.monacoData0 = '';
    this.monacoData1 = '';
    this.monacoData2 = '';
    this.monacoData3 = '';
    this.integrationFormControl.setValue('');
    this.integrationInfoListFormControl.setValue('');
    this.displayMonaco0 = false;
    this.displayMonaco1 = false;
    this.displayMonaco2 = false;
    this.displayMonaco3 = false;

    if (this.integrationInformationList?.length != 1) {
      this.selectedIdentifier = null;
    }
  }

  editDestination() {
    this.destination = '';
    this.selectedIdentifier = null;
  }

  resetMonaco() {
    this.displayMonaco0 = false;
    this.displayMonaco1 = false;
    this.displayMonaco2 = false;
    this.displayMonaco3 = false;
    this.monacoData0 = '';
    this.monacoData1 = '';
    this.monacoData2 = '';
    this.monacoData3 = '';
  }

  invokeGetFile() {
    this.exportType = 'file';
    let validRequest = true;
    let ft = this.getFileTypeValue();

    this.setDestinationFromDestinationName();

    if (this.selectedClient == null && this.selectByLTClient) {
      var s = 'Invalid Client';
      this.toastService.toastCreate(s, 'Danger', { autoClose: true, keepAfterRouteChange: false });
      validRequest = false;
    }
    else if (this.selectedIntegration == null && this.selectByLTClient) {
      var s = 'Invalid Integration';
      this.toastService.toastCreate(s, 'Danger', { autoClose: true, keepAfterRouteChange: false });
      validRequest = false;
    }
    else if (this.selectedIdentifier == null) {
      var s = 'Invalid Identifier';
      this.toastService.toastCreate(s, 'Danger', { autoClose: true, keepAfterRouteChange: false });
      validRequest = false;
    }
    else if (ft == null || ft == '') {
      var s = 'File Type cannot be blank.';
      this.toastService.toastCreate(s, 'Danger', { autoClose: true, keepAfterRouteChange: false });
      validRequest = false;
    }
    else if (this.latestNo == null) {
      if (this.useAllOptions == true) {
        this.latestNo = '1';
      }
      else {
        var s = 'Latest No cannot be blank.';
        this.toastService.toastCreate(s, 'Danger', { autoClose: true, keepAfterRouteChange: false });
        validRequest = false;
      }
    }
    else if (this.destination == null) {
      var s = 'Destination cannot be blank.';
      this.toastService.toastCreate(s, 'Danger', { autoClose: true, keepAfterRouteChange: false });
      validRequest = false;
    }

    if (!validRequest) {
      this.successGetFile = false;
      return;
    }

    this.successGetFile = true;

    let dataSwapParams = new DataSwapParams();
    dataSwapParams.clientShortName = this.selectedIdentifier.identifierNameValue;
    dataSwapParams.company = this.selectedIdentifier.identifierNameValue;
    dataSwapParams.fileType = ft;
    dataSwapParams.latestNo = this.latestNo;
    dataSwapParams.swapKey = this.selectedIdentifier.identifierKeyValue;
    if (this.useAllOptions) {
      this.getAllFile(dataSwapParams);
    }
    else {
      if (this.destination == 'NexusVision') {
        this.lbaDataswapService.limegearGetFile(dataSwapParams)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((response) => {
            this.monacoData0 = response.responseElement;
            this.displayMonaco0 = true;
          }
        );
      }
      else if (this.destination == 'CompenSafe') {
        this.lbaDataswapService.compensafeGetFile(dataSwapParams)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((response) => {
            this.monacoData0 = response.responseElement;
            this.displayMonaco0 = true;
          }
        );
      }
    }

  }

  changeSelectionMethod() {
    this.clearVars();

    if (this.selectByLTClient) {
      this.integrationsFiltered = this.integrationFormControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this.filterIntegrationNames(name) : this.displayedIntegrationList.slice())
      );
    }
    else {
      this.integrationInfoFilteredList = this.integrationInfoListFormControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this.filterIntegrationIdentifierValues(name) : this.displayedIntegrationInfoList.slice())
      );
    }
  }

  invokeGetLoan() {
    this.exportType = 'loan';
    let validRequest = true;

    this.setDestinationFromDestinationName();

    if (this.selectedClient == null && this.selectByLTClient) {
      var s = 'Invalid Client.';
      this.toastService.toastCreate(s, 'Danger', { autoClose: true, keepAfterRouteChange: false });
      validRequest = false;
    }
    else if (this.selectedIntegration == null && this.selectByLTClient) {
      var s = 'Invalid Integration';
      this.toastService.toastCreate(s, 'Danger', { autoClose: true, keepAfterRouteChange: false });
      validRequest = false;
    }
    else if (this.selectedIdentifier == null) {
      var s = 'Invalid Identifier';
      this.toastService.toastCreate(s, 'Danger', { autoClose: true, keepAfterRouteChange: false });
      validRequest = false;
    }
    else if (this.loanNumber == null || this.loanNumber == '') {
      var s = 'Loan Number cannot be blank.';
      this.toastService.toastCreate(s, 'Danger', { autoClose: true, keepAfterRouteChange: false });
      validRequest = false;
    }
    else if (this.latestNo == null) {
      if (this.useAllOptions == true) {
        this.latestNo = '1';
      }
      else {
        var s = 'Latest No cannot be blank.';
        this.toastService.toastCreate(s, 'Danger', { autoClose: true, keepAfterRouteChange: false });
        validRequest = false;
      }
    }
    else if (this.destination == null) {
      var s = 'Destination cannot be blank.';
      this.toastService.toastCreate(s, 'Danger', { autoClose: true, keepAfterRouteChange: false });
      validRequest = false;
    }

    if (!validRequest) {
      this.successGetLoan = false;
      this.toastService.toastCreate('Bad request', 'Danger', { autoClose: true, keepAfterRouteChange: false });
      return;
    }

    this.successGetFile = true;
    let dataSwapParams = new DataSwapParams();
    dataSwapParams.clientShortName = this.selectedIdentifier.identifierNameValue;
    dataSwapParams.company = this.selectedIdentifier.identifierNameValue;
    dataSwapParams.loanNo = this.loanNumber;
    dataSwapParams.latestNo = this.latestNo;
    dataSwapParams.swapKey = this.selectedIdentifier.identifierKeyValue;

    if (this.useAllOptions) {
      this.getAllLoan(dataSwapParams);
    }
    else {
      if (this.destination == 'NexusVision') {
        this.lbaDataswapService.limegearGetLoan(dataSwapParams)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((response) => {
            this.monacoData0 = response.responseElement;
            this.displayMonaco0 = true;
          }
        );
      }
      else if (this.destination == 'CompenSafe') {
        this.lbaDataswapService.compensafeGetLoan(dataSwapParams)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((response) => {
            this.monacoData0 = response.responseElement;
            this.displayMonaco0 = true;
          }
        );
      }
    }

  }

  getAllLoan(p: DataSwapParams) {
    this.monacoData1 = '';
    this.monacoData2 = '';
    this.monacoData3 = '';

    let p1 = JSON.parse(JSON.stringify(p));
    p1.latestNo = '1';
    let p2 = JSON.parse(JSON.stringify(p));
    p2.latestNo = '2';
    let p3 = JSON.parse(JSON.stringify(p));
    p3.latestNo = '3';

    this.setDestinationFromDestinationName();

    if (this.destination == 'NexusVision') {

      this.lbaDataswapService.limegearGetLoan(p1)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (response) => {
          this.monacoData1 = response.responseElement;
          this.displayMonaco1 = true;
        },
        (error) => {
          this.toastService.toastCreate('Get Loan Latest #1 error.', 'Danger', { autoClose: true, keepAfterRouteChange: false })
        }
      );

      this.lbaDataswapService.limegearGetLoan(p2)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (response) => {
          this.monacoData2 = response.responseElement;
          this.displayMonaco2 = true;
        },
        (error) => {
          this.toastService.toastCreate('Get Loan Latest #2 error.', 'Danger', { autoClose: true, keepAfterRouteChange: false })
        }
      );

      this.lbaDataswapService.limegearGetLoan(p3)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (response) => {
          this.monacoData3 = response.responseElement;
          this.displayMonaco3 = true;
        },
        (error) => {
          this.toastService.toastCreate('Get Loan Latest #3 error.', 'Danger', { autoClose: true, keepAfterRouteChange: false })
        }
      );

    }
    else if (this.destination == 'CompenSafe') {

      this.lbaDataswapService.compensafeGetLoan(p1)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (response) => {
          this.monacoData1 = response.responseElement;
          this.displayMonaco1 = true;
        },
        (error) => {
          this.toastService.toastCreate('Get Loan Latest #1 error.', 'Danger', { autoClose: true, keepAfterRouteChange: false })
        }
      );
      p.latestNo = '2';
      this.lbaDataswapService.compensafeGetLoan(p2)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (response) => {
          this.monacoData2 = response.responseElement;
          this.displayMonaco2 = true;
        },
        (error) => {
          this.toastService.toastCreate('Get Loan Latest #2 error.', 'Danger', { autoClose: true, keepAfterRouteChange: false })
        }
      );
      p.latestNo = '3';
      this.lbaDataswapService.compensafeGetLoan(p3)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (response) => {
          this.monacoData3 = response.responseElement;
          this.displayMonaco3 = true;
        },
        (error) => {
          this.toastService.toastCreate('Get Loan Latest #3 error.', 'Danger', { autoClose: true, keepAfterRouteChange: false })
        }
      );

    }

  }

  getAllFile(p: DataSwapParams) {
    this.monacoData1 = '';
    this.monacoData2 = '';
    this.monacoData3 = '';

    this.setDestinationFromDestinationName();

    let p1 = JSON.parse(JSON.stringify(p));
    p1.latestNo = '1';
    let p2 = JSON.parse(JSON.stringify(p));
    p2.latestNo = '2';
    let p3 = JSON.parse(JSON.stringify(p));
    p3.latestNo = '3';

    if (this.destination == 'NexusVision') {

      this.lbaDataswapService.limegearGetFile(p1)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (response) => {
          this.monacoData1 = response.responseElement;
          this.displayMonaco1 = true;
        },
        (error) => {
          this.toastService.toastCreate('Get File Latest #1 error.', 'Danger', { autoClose: true, keepAfterRouteChange: false })
        }
      );

      this.lbaDataswapService.limegearGetFile(p2)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (response) => {
          this.monacoData2 = response.responseElement;
          this.displayMonaco2 = true;
        },
        (error) => {
          this.toastService.toastCreate('Get File Latest #2 error.', 'Danger', { autoClose: true, keepAfterRouteChange: false })
        }
      );

      this.lbaDataswapService.limegearGetFile(p3)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (response) => {
          this.monacoData3 = response.responseElement;
          this.displayMonaco3 = true;
        },
        (error) => {
          this.toastService.toastCreate('Get File Latest #3 error.', 'Danger', { autoClose: true, keepAfterRouteChange: false })
        }
      );

    }
    else if (this.destination == 'CompenSafe') {
      this.lbaDataswapService.compensafeGetFile(p1)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (response) => {
          this.monacoData1 = response.responseElement;
          this.displayMonaco1 = true;
        },
        (error) => {
          this.toastService.toastCreate('Get File Latest #1 error.', 'Danger', { autoClose: true, keepAfterRouteChange: false })
        }
      );

      this.lbaDataswapService.compensafeGetFile(p2)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (response) => {
          this.monacoData2 = response.responseElement;
          this.displayMonaco2 = true;
        },
        (error) => {
          this.toastService.toastCreate('Get File Latest #2 error.', 'Danger', { autoClose: true, keepAfterRouteChange: false })
        }
      );

      this.lbaDataswapService.compensafeGetFile(p3)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (response) => {
          this.monacoData3 = response.responseElement;
          this.displayMonaco3 = true;
        },
        (error) => {
          this.toastService.toastCreate('Get File Latest #3 error.', 'Danger', { autoClose: true, keepAfterRouteChange: false })
        }
      );

    }
  }

  displayFnClient(x: Client): string {
    return x && x.name ? x.name : '';
  }

  displayFnIntegration(x: IntegrationInformation): string {
    return x && x.integrationName ? x.integrationName : '';
  }

  displayFnIdentifier(x: IntegrationIdentifier): string {
    return x && x.identifierNameValue && x.identifierIdValue ? x.identifierIdValue + " - " + x.identifierNameValue : '';
  }

  private filterClients(name: string): Client[] {
    const filterValue = name.toLowerCase();
    return this.clients.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  private filterIntegrationNames(name: string): Integration[] {
    const filterValue = name.toLowerCase();
    return this.displayedIntegrationList.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  private filterIntegrationIdentifierValues(name: string): IntegrationInformation[] {
    if(name == undefined){
      return this.displayedIntegrationInfoList;
    }

    const filterValue = name.toLowerCase();
    return this.displayedIntegrationInfoList.filter(option => option.identifierNameValue.toLowerCase().includes(filterValue) || option.identifierIdValue.toLowerCase().includes(filterValue));
  }


  setLoanNumber(loanNumber: string): void {
    this.loanNumber = loanNumber;
    this.resetMonaco();
  }

  changeGetAllOptionsCheck(x: boolean) {
    this.useAllOptions = !this.useAllOptions;
  }

  public checkAccess(role: string[]): boolean {
    if (this.activeUser.checkAccess(role)) {
      return true;
    } else {
      return false;
    }
  }

  saveFile(): void {
    if (this.displayMonaco0 && this.monacoData0 != "" && this.monaco != undefined) {
      this.processSaveFile(0, this.monacoData0);
    }

    if (this.displayMonaco1 && this.monacoData1 != "" && this.monaco1 != undefined) {
      this.processSaveFile(1, this.monacoData1);
    }

    if (this.displayMonaco2 && this.monacoData2 != "" && this.monaco2 != undefined) {
      this.processSaveFile(2, this.monacoData2);
    }

    if (this.displayMonaco3 && this.monacoData3 != "" && this.monaco3 != undefined) {
      this.processSaveFile(3, this.monacoData3);
    }
  }

  saveAllFiles(): void {
    if (this.displayMonaco1 && this.monacoData1 != "") {
      this.processSaveFile(1, this.monacoData1);
    }

    if (this.displayMonaco2 && this.monacoData2 != "") {
      this.processSaveFile(2, this.monacoData2);
    }

    if (this.displayMonaco3 && this.monacoData3 != "") {
      this.processSaveFile(3, this.monacoData3);
    }
  }

  processSaveFile(editor: number = 1, data: string = "") {
    let fileName: string = "";
    let ft = this.getFileTypeValue();

    if (!this.selectByLTClient) {
      let clientName = this.selectedIdentifier.identifierNameValue;
      clientName = clientName.replace(/[^a-zA-Z0-9_-]/g,"");
      fileName += clientName;
    }
    else {
      let clientName = this.selectedClient.clientIntegrationAbbrName;
      clientName = clientName.replace(/[^a-zA-Z0-9_-]/g,"");
      fileName += clientName;
    }

    if (this.exportType == 'loan') {
      fileName += "-loan-" + this.loanNumber;

    }
    else if (this.exportType == 'file') {
      fileName += "-" + ft;
    }

    const datepipe: DatePipe = new DatePipe('en-us');
    let formattedDate = datepipe.transform(today, 'yyyyMMddHHmmssZ');
    fileName += "-" + editor + "-" + formattedDate + ".xml";

    const blob = new Blob([data],{type: "application/xml"});
    saveAs(blob, fileName);

    this.toastService.toastCreate("File saved to Downloads. Filename: " + fileName, 'Info', { autoClose: true, keepAfterRouteChange: false });
  }

}
