import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { ErrorComponent } from './components/error/error.component';
import { ClientsComponent } from './components/clients/clients.component';
import { UserComponent } from './components/user/user.component';
import { ChangeLogsComponent } from './components/changelogs/changelogs.component';
import { DefaultConfigsComponent } from './components/default-configs/default-configs.component';
import { EditDefaultConfigsComponent } from './components/edit-default-configs/edit-default-configs.component';
import { AddDefaultConfigsComponent } from './components/add-default-configs/add-default-configs.component';
import { EditConfigComponent } from './components/edit-config/edit-config.component';
import { ErrorListComponent } from './components/error-list/error-list.component';
import { ErrorMessagesv2Component } from './components/error-messages-v2/error-messages-v2.component';
import { GenericEndpointsComponent } from './components/generic-endpoints/generic-endpoints.component';
import { EditGenericEndpointsComponent } from './components/edit-generic-endpoints/edit-generic-endpoints.component';
import { ServerIntegrationsComponent } from './components/serverintegrations/serverintegrations.component';
import { ServersComponent } from './components/servers/servers.component';
import { AddClientComponent } from './components/add-client/add-client.component';
import { SwaggerInterfaceComponent } from './components/swagger-interface/swagger-interface.component';
import { SwaggerInterfaceV2Component } from './components/swagger-interface-v2/swagger-interface-v2.component';
import { AddIntegrationComponent } from './components/add-integration/add-integration.component';
import { AddIntegrationIdentifierComponent } from './components/add-integration-identifier/add-integration-identifier.component';
import { BaseMappingComponent } from './components/base-mapping/base-mapping.component';
import { ReportsComponent } from './components/reports/reports.component';
import { ReportViewComponent } from './components/report-view/report-view.component';
import { IntegrationReportViewComponent } from './components/report-view/integration-report-view/integration-report-view.component';
import { UndeleteComponent } from './components/report-view/undelete/undelete.component';

import { FastfetchComponent } from './components/fastfetch/fastfetch.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { ExcelPasteDirective } from './directives/excel-paste.directive';
import { LBADataswapComponent } from './components/lba-dataswap/lba-dataswap.component';

import { RoleGuard } from './guards/role-guard';
import { EditDefaultConfigGuard } from './guards/edit-default-config-guard';
import { EditGenericEndpointsGuard } from './guards/edit-generic-endpoints-guard';
import { EditConfigGuard } from './guards/edit-config-guard';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { AddBaseMapping } from './components/add-base-mapping/add-base-mapping.component';
import { EditBaseMappingComponent } from './components/edit-base-mapping/edit-base-mapping.component';
import { EditBaseMappingGuard } from './guards/edit-base-mapping-guard';
import { EditClientComponent } from './components/edit-client/edit-client.component';
import { ErrorReportComponent } from './components/report-view/error-report/error-report.component';
import { ClientContractReportComponent } from './components/report-view/client-contract-report/client-contract-report.component';
import { ClientConfigurationSecretsComponent } from './components/client-configuration-secrets/client-configuration-secrets.component';
import { InvoicedLoansReportComponent } from './components/invoiced-loans-report/invoiced-loans-report.component';
import { FieldPathsViewComponent } from './components/field-paths-view/field-paths-view.component';
import { InvoicingMetricsReportComponent } from './components/invoicing-metrics-report/invoicing-metrics-report.component';
import { LosTokenViewComponent } from './components/los-token-view/los-token-view.component';
import { SQLQueriesComponent } from './components/sql-queries/sql-queries.component';
import { EditSQLQueryComponent } from './components/edit-sql-query/edit-sql-query.component';
import { AddSQLQueryComponent } from './components/add-sql-query/add-sql-query.component';
import { IntegrationBulkReportComponent } from './components/report-view/integration-bulk-report/integration-bulk-report.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      title: 'Home'
    }
  },
  {
    path: 'home',
    component: HomeComponent,
    data: {
      title: 'Home'
    }
  },
  {
    path: 'clients',
    component: ClientsComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Clients',
      requiredRoles: ['LOSTalker.Client']
    }
  },
  {
    path: 'userData',
    component: UserComponent,
    data: {
      title: 'User Information'
    }
  },
  {
    path: 'changelogs',
    component: ChangeLogsComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Change Logs',
      requiredRoles: ['LOSTalker.Admin']
    }
  },
  {
    path: 'defaultConfigs',
    component: DefaultConfigsComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Default Configs',
      requiredRoles: ['LOSTalker.Integration']
    }
  },
  {
    path: 'addDefaultConfigs',
    component: AddDefaultConfigsComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Add Default Config',
      requiredRoles: ['LOSTalker.Integration']
    }
  },
  {
    path: 'editDefaultConfigs/:name',
    component: EditDefaultConfigsComponent,
    canActivate: [RoleGuard],
    canDeactivate: [EditDefaultConfigGuard],
    data: {
      title: 'Edit Default Config',
      requiredRoles: ['LOSTalker.Integration']
    }
  },
  {
    path: 'editConfig/:client',
    component: EditConfigComponent,
    canActivate: [RoleGuard],
    canDeactivate: [EditConfigGuard],
    data: {
      title: 'EditClients',
      requiredRoles: ['LOSTalker.Integration']
    }
  },
  {
    path: 'editConfig/:client/:integration',
    component: EditConfigComponent,
    canActivate: [RoleGuard],
    canDeactivate: [EditConfigGuard],
    data: {
      title: 'EditClients',
      requiredRoles: ['LOSTalker.Integration']
    }
  },
  {
    path: 'editConfig/:client/:integration/:tab',
    component: EditConfigComponent,
    canActivate: [RoleGuard],
    canDeactivate: [EditConfigGuard],
    data: {
      title: 'EditClients',
      requiredRoles: ['LOSTalker.Integration']
    }
  },
  {
    path: 'errorList',
    component: ErrorListComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Integration Errors',
      requiredRoles: ['LOSTalker.Integration.CompenSafe', 'LOSTalker.Integration.LimeGear']
    }
  },
  {
    path: 'errorMessagesv2',
    component: ErrorMessagesv2Component,
    canActivate: [RoleGuard],
    data: {
      title: 'Error Messages v2',
      requiredRoles: ['LOSTalker.Integration.CompenSafe', 'LOSTalker.Integration.LimeGear']
    }
  },
  {
    path: 'generic-endpoints',
    component: GenericEndpointsComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Generic Endpoints',
      requiredRoles: ['LOSTalker.BaseMapping']
    }
  },
  {
    path: 'editGenericEndpoint/:id',
    component: EditGenericEndpointsComponent,
    canActivate: [RoleGuard],
    canDeactivate: [EditGenericEndpointsGuard],
    data: {
      title: 'Edit Generic Endpoints',
      requiredRoles: ['LOSTalker.BaseMapping']
    }
  },
  {
    path: 'editBaseMapping/:baseMappingId',
    component: EditBaseMappingComponent,
    canActivate: [RoleGuard],
    canDeactivate: [EditBaseMappingGuard],
    data: {
      title: 'Edit Base Mappings',
      requiredRoles: ['LOSTalker.BaseMapping']
    }
  },
  {
    path: 'addClient',
    component: AddClientComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Add Client',
      requiredRoles: ['LOSTalker.Admin']
    }
  },
  {
    path: 'editClient',
    component: EditClientComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Edit Client',
      requiredRoles: ['LOSTalker.Admin']
    }
  },
  {
    path: 'addIntegration',
    component: AddIntegrationComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Add Integration',
      requiredRoles: ['LOSTalker.Admin']
    }
  },
  {
    path: 'addIntegration/:name',
    component: AddIntegrationComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Add Integration',
      requiredRoles: ['LOSTalker.Admin']
    }
  },
  {
    path: 'addIntegrationIdentifier',
    component: AddIntegrationIdentifierComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Add Integration Identifier',
      requiredRoles: ['LOSTalker.DevOps']
    }
  },
  {
    path: 'addBaseMapping',
    component: AddBaseMapping,
    canActivate: [RoleGuard],
    data: {
      title: 'Add Base Mapping',
      requiredRoles: ['LOSTalker.DevOps']
    }
  },
  {
    path: 'servers',
    component: ServersComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Servers',
      requiredRoles: ['LOSTalker.DevOps']
    }
  },
  {
    path: 'serverintegrations/:name',
    component: ServerIntegrationsComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Server Integrations',
      requiredRoles: ['LOSTalker.Integration']
    }
  },
  {
    path: 'baseMapping',
    component: BaseMappingComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Base Mapping',
      requiredRoles: ['LOSTalker.BaseMapping']
    }
  },
  {
    path: 'baseMapping/:id',
    component: BaseMappingComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Base Mapping',
      requiredRoles: ['LOSTalker.BaseMapping']
    }
  },
  {
    path: 'sql-queries',
    component: SQLQueriesComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Custom SQL Queries',
      requiredRoles: ['LOSTalker.Admin']
    }
  },
  {
    path: 'editQuery/:id',
    component: EditSQLQueryComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Edit Custom SQL Queries',
      requiredRoles: ['LOSTalker.Admin']
    }
  },
  {
    path: 'addQuery',
    component: AddSQLQueryComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Add Custom SQL Query',
      requiredRoles: ['LOSTalker.Admin']
    }
  },
  {
    path: 'dataswap',
    component: LBADataswapComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'DataSwap',
      requiredRoles: ['LOSTalker.Integration.CompenSafe.DataSwap', 'LOSTalker.Integration.LimeGear.DataSwap']
    }
  },
  {
    path: 'fastfetch',
    component: FastfetchComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Fast Fetch',
      requiredRoles: ['LOSTalker.Integration.CompenSafe.FastFetch', 'LOSTalker.Integration.LimeGear.FastFetch']
    }
  },
  {
    path: 'notfound',
    component: NotfoundComponent,
    data: {
      title: 'Not Found'
    }
  },
  {
    path: 'swagger-interface',
    component: SwaggerInterfaceComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Swagger API Interface',
      requiredRoles: ['LOSTalker.DevOps']
    }
  },
  {
    path: 'swagger-interface-v2',
    component: SwaggerInterfaceV2Component,
    canActivate: [RoleGuard],
    data: {
      title: 'V2 API Swagger Interface',
      requiredRoles: ['LOSTalker.Admin']
    }
  },
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Reports',
      requiredRoles: ['LOSTalker.DevOps']
    }
  },
  {
    path: 'report-view',
    component: ReportViewComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Report View',
      requiredRoles: ['LOSTalker.DevOps']
    }
  },
  {
    path: 'report-view/:reportId',
    component: ReportViewComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Report View',
      requiredRoles: ['LOSTalker.DevOps']
    }
  },
  {
    path: 'integration-report-view/:id',
    component: IntegrationReportViewComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Integration Report View',
      requiredRoles: ['LOSTalker.DevOps']
    }
  },
  //{ path: 'report-view/:name', component: ReportViewComponent, data: { title: 'Report View' } },
  {
    path: 'undelete',
    component: UndeleteComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Undelete Clients and Integrations',
      requiredRoles: ['LOSTalker.DevOps']
    }
  },
  {
    path: 'errorsFilter',
    component: ErrorReportComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Error Filter',
      requiredRoles: ['LOSTalker.DevOps']
    }
  },
  {
    path: 'contractsFilter',
    component: ClientContractReportComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Client Contract Filter',
      requiredRoles: ['LOSTalker.DevOps']
    }
  },
  {
    path: 'admin-dashboard',
    component: AdminDashboardComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Admin Dashboard',
      requiredRoles: ['LOSTalker.Admin']
    }
  },
  {
    path: 'client-secrets',
    component: ClientConfigurationSecretsComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Client Configuration Secrets',
      requiredRoles: ['LOSTalker.Admin']
    }
  },
  {
    path: 'invoiced-loans-report',
    component: InvoicedLoansReportComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Invoiced Loans Report',
      requiredRoles: ['LOSTalker.Client', 'LOSTalker.Client.InvoicingReports']
    }
  },
  {
    path: 'invoiced-loans-report/:contractid',
    component: InvoicedLoansReportComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Invoiced Loans Report',
      requiredRoles: ['LOSTalker.Client', 'LOSTalker.Client.InvoicingReports']
    }
  },
  {
    path: 'invoiced-loans-report/:contractid/:invoiceperiodid',
    component: InvoicedLoansReportComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Invoiced Loans Report',
      requiredRoles: ['LOSTalker.Client', 'LOSTalker.Client.InvoicingReports']
    }
  },
  {
    path: 'invoiced-metrics-report',
    component: InvoicingMetricsReportComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Invoiced Metrics Report',
      requiredRoles: ['LOSTalker.Client', 'LOSTalker.Client.InvoicingReports']
    }
  },
  {
    path: 'invoiced-metrics-report/:contractid',
    component: InvoicingMetricsReportComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Invoiced Metrics Report',
      requiredRoles: ['LOSTalker.Client', 'LOSTalker.Client.InvoicingReports']
    }
  },
  {
    path: 'field-paths',
    component: FieldPathsViewComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Encompass API Field Paths',
      requiredRoles: ['LOSTalker.Client', 'LOSTalker.Client.LOSData']
    }
  },
  {
    path: 'los-tokens-mappings',
    component: LosTokenViewComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'LOS Tokens and Mappings',
      requiredRoles: ['LOSTalker.Admin']
    }
  },
  {
    path: 'integration-bulk-report',
    component: IntegrationBulkReportComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Integration Bulk Reports',
      requiredRoles: ['LOSTalker.Admin']
    }
  },
  {
    path: 'integration-bulk-report/:reportId',
    component: IntegrationBulkReportComponent,
    canActivate: [RoleGuard],
    data: {
      title: 'Integration Bulk Reports',
      requiredRoles: ['LOSTalker.Admin']
    }
  },
  {
    path: '**',
    component: ErrorComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
