import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { State, DataSourceRequestState, SortDescriptor, isCompositeFilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Observable, Subscription, Subject, forkJoin, iif } from 'rxjs';
import { takeUntil, mergeMap } from "rxjs/operators";
import { Integration } from '../../../models/integration';
import { Report } from '../../../models/report';
import { Client } from 'src/app/models/client';
import { IntegrationError } from '../../../models/integration-error';
import { IntegrationErrorResponse } from '../../../models/integration-error-response';
import { ClientIntegrationService } from "../../../services/client-integration.service";
import { IntegrationErrorsService } from '../../../services/integration-errors.service';
import { ErrorListGridV2Component } from '../../error-list-grid-v2/error-list-grid-v2.component';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { ToastService } from '../../../services/toast.service';
import { Server } from 'src/app/models/server';
import { ServerService } from 'src/app/services/server.service';
import { TypeConstantService } from 'src/app/services/type-constant.service';
import { DestinationEndpoint } from '../../../models/destination-endpoint';
import { ServiceError } from 'src/app/models/service-error';
import { ErrorCategory } from 'src/app/models/error-category';
import { CurrentStatus } from 'src/app/models/currentStatus';
import { ImportType } from 'src/app/models/import-type';
import { ErrorSearchFilter } from 'src/app/models/error-search-filter';
import { ReportErrorSearchFilter } from 'src/app/models/report-error-search-filter';
import { ReportService } from 'src/app/services/report.service';
import { ReportConstants } from 'src/app/models/report-constants';
import { ReportErrorSearchFilterComponent } from '../../report-error-search-filter/report-error-search-filter.component';

@Component({
  selector: 'app-integration-error-report',
  templateUrl: './integration-error-report.component.html',
  styleUrls: ['./integration-error-report.component.scss'],
  providers: [ClientIntegrationService, IntegrationErrorsService, ReportService]
})
export class IntegrationErrorReportComponent implements OnInit, OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();
  @Input() currentReportToShow: Report;
  @Input() reportConstants: ReportConstants;

  @ViewChild("reportErrorsGridComponent") public reportErrorsGridComponent: ErrorListGridV2Component;
  @ViewChild("reportErrorsFilter") public reportErrorsFilter: ReportErrorSearchFilterComponent;

  tabIndex: number = 0;
  dataLoading: boolean = true;
  errorsPopulated: boolean = false;

  fullErrorsList: IntegrationError[] = [];
  filteredErrorList: IntegrationError[]= [];
  selectedErrorIds: Map<string, Date>;
  filteredErrorIds: Map<string, Date>;
  errorGroups: Map<string, Date>[];

  public categories: ErrorCategory[];
  public statuses: CurrentStatus[];

  integrations: Integration[];
  clients: Client[];
  servers: Server[];
  integrationsMap: Map<string, Integration>;
  totalErrors: number = 0;
  errorGroupSize: number = 500;
  defaultErrorWindow: number = 90;

  errorSearchFilter: ReportErrorSearchFilter;
  defaultErrorSearchFilter: ReportErrorSearchFilter;

  defaultErrorSorting: SortDescriptor[] = [{ field: "receivedDate", dir: 'desc' }];

  hasCategories: boolean = false;
  hasStatuses: boolean = false;
  gettingClients: boolean = false;
  firstGroupReturned: boolean = false;
  allGroupsReturned: boolean = false;
  gridFullyPopulated: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private clientService: ClientIntegrationService,
    private reportService: ReportService,
    private toastService: ToastService,
    private errorLoggingService: IntegrationErrorsService) {

  }

  ngOnInit(): void {
    this.defaultErrorSearchFilter = {
      numberOfDays: this.defaultErrorWindow,
      startReceivedDate: new Date(),
      reportId: this.currentReportToShow.reportId,
      dataSproc: this.currentReportToShow.dataSproc
    };

    this.defaultErrorSearchFilter.startReceivedDate.setDate((new Date().getDate() - this.defaultErrorWindow))

    this.errorSearchFilter = Object.assign({}, this.defaultErrorSearchFilter)

    this.resetAll();
    this.getTypeConstants();

    this.getClients();
    this.getIntegrations();
    this.getAllErrors();
  }

  getAllErrors(): void {
    this.dataLoading = true;
    this.errorsPopulated = false;

    this.reportService.getIntegrationErrorIdsForReport(this.errorSearchFilter)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (data) => {
          this.selectedErrorIds = data;
          this.getErrorsByIdList();
        },
        (error) => {
          this.dataLoading = false;
          this.errorsPopulated = true;
          this.toastService.toastCreate("Loading Integration Errors Failed: " + error, "Warning", { autoClose: true, keepAfterRouteChange: false });
        }
      );
  }

  getFilteredErrorIdListAndUpdateGrid(): void {
    this.reportService.getIntegrationErrorIdsForReport(this.errorSearchFilter)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (data) => {
          this.filteredErrorIds = data;
          let originalIds = [];
          let newIds = [];
          this.selectedErrorIds.forEach(e => originalIds.push(e["key"]));
          this.filteredErrorIds.forEach(e => newIds.push(e["key"]));

          let missingErrors = newIds.filter(i => !originalIds.includes(i));
          if (missingErrors?.length > 0) {
            console.log("Found " + missingErrors.length + " Missing Errors from Full List with current filter, retrieving now...");
            this.getMissingErrorsByIdList(missingErrors);
          }
          else {
            this.updateReportFromFilter();
          }
        },
        (error) => {
          this.dataLoading = false;
          this.errorsPopulated = true;
          this.toastService.toastCreate("Loading Integration Errors Failed: " + error, "Warning", { autoClose: true, keepAfterRouteChange: false });
        }
      );
  }

  getMissingErrorsByIdList(errorGroup: string[]): void{
    this.errorLoggingService.getErrorsByErrorIdList(errorGroup)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (data) => {
          this.fullErrorsList = this.fullErrorsList.concat(data);
          console.log("Returned " + data?.length + " Missing Errors to add to Full List with current filter");
          this.reportErrorsGridComponent?.mapData();
          this.updateReportFromFilter();
          this.allGroupsReturned = true;
          this.reportErrorsFilter?.setAllErrorsReturned()
        },
        (error) => {
          this.dataLoading = false;
          this.errorsPopulated = true;
          this.toastService.toastCreate("Loading First Integration Error Group Failed: " + error, "Warning", { autoClose: true, keepAfterRouteChange: false });
        }
      );
  }

  updateReportFromFilter(){
    this.reportErrorsGridComponent.filterByReportFilters(this.errorSearchFilter);
    this.reportErrorsGridComponent?.refreshFromParent();
    this.errorsPopulated = true;
    this.firstGroupReturned = true;
    this.dataLoading = false;
  }

  getErrorsByIdList(): void {
    let errorList = this.selectedErrorIds.keys();
    //this.totalErrors = errorList.length;
    this.errorGroups = this.createGroups(Array.from(this.selectedErrorIds), this.errorGroupSize);
    let firstErrorGroup = [...this.errorGroups[0].keys()];
    this.getFirstErrorGroup(firstErrorGroup);
  }

  createGroups(arr: [string, Date][], perGroup: number): Map<string, Date>[] {
    let rc: Map<string, Date>[] = [];
    const numGroups = Math.ceil(arr.length / perGroup);
    let tmpArr = new Array(numGroups)
      .fill('')
      .map((_, i) => arr.slice(i * perGroup, (i + 1) * perGroup));

    tmpArr.forEach(i => rc.push(new Map(i.map(x => [x["key"], x["value"]] as [string, Date]))));
    return rc;
  }

  sendUpdatedDataToTable(e: any) {
    this.getErrorsByIdList();
  }

  getFirstErrorGroup(errorGroup: string[]): void {
    this.errorLoggingService.getErrorsByErrorIdList(errorGroup)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (data) => {
          this.fullErrorsList = this.fullErrorsList.concat(data);
          this.errorsPopulated = true;
          this.firstGroupReturned = true;
          this.dataLoading = false;
          this.errorsPopulated = true;
          this.reportErrorsGridComponent?.refresh();

          this.getOtherErrorGroupsInBackground();
        },
        (error) => {
          this.dataLoading = false;
          this.errorsPopulated = true;
          this.toastService.toastCreate("Loading First Integration Error Group Failed: " + error, "Warning", { autoClose: true, keepAfterRouteChange: false });
        }
      );
  }

  getOtherErrorGroupsInBackground(): void {
    for (let i = 1; i < this.errorGroups.length; i++) {
      this.errorLoggingService.getErrorsByErrorIdList(Array.from([...this.errorGroups[i].keys()]))
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(
          (data) => {
            this.fullErrorsList = this.fullErrorsList.concat(data);
            if(i == (this.errorGroups.length - 1)){
              this.allGroupsReturned = true;
              this.reportErrorsFilter?.setAllErrorsReturned()
            }
          },
          (error) => {
            this.dataLoading = false;
            this.errorsPopulated = true;
            this.toastService.toastCreate("Loading Integration Error Group " + i + " Failed: " + error, "Warning", { autoClose: true, keepAfterRouteChange: false });
          }
        );
    }
  }

  reloadGridForFullPopulation(event: boolean): void {
    this.dataLoading = true;
    this.errorsPopulated = false;
    this.reportErrorsGridComponent.refreshFromParent();
    this.errorsPopulated = true;
    this.dataLoading = false;
    this.reportErrorsFilter.setAllErrorsDisplayed();
  }

  filterErrorsReport(event: ReportErrorSearchFilter): void {
    //If these Conditions are met (ie. Previous Filter was Default Filter), all Filtered errors should already be in the report
    if (this.errorSearchFilter.startReceivedDate <= event.startReceivedDate && this.errorSearchFilter.endReceivedDate >= event.endReceivedDate
      && this.errorSearchFilter.destinationEndpointId == undefined && this.errorSearchFilter.importTypeId == undefined
      && this.errorSearchFilter.errorCategoryId == undefined && this.errorSearchFilter.currentStatusId == undefined && this.errorSearchFilter.integrationId == undefined){
        this.reportErrorsGridComponent.filterByReportFilters(event);
        this.errorSearchFilter = event;
    }
    else{
      this.errorSearchFilter = event;
      this.reportErrorsFilter.allErrorsDisplayed = false;
      this.reportErrorsFilter.allErrorsReturned = false;
      this.dataLoading = true;
      this.getFilteredErrorIdListAndUpdateGrid();
    }
  }



  getTypeConstants(): void {
    this.errorLoggingService.getAllErrorCategories()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(r => {
        this.categories = r;
        this.hasCategories = true;
      },
        (error) => {
          this.hasCategories = false;
        });

    this.errorLoggingService.getAllCurrentStatuses()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(r => {
        this.statuses = r;
        this.hasStatuses = true;
      },
        (error) => {
          this.hasStatuses = false;
        });
  }

  getIntegrations(): void {
    this.clientService.getAllIntegrations()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (g) => {
          this.integrationsMap = new Map(g.map(k => [k.integrationId, k] as [string, Integration]));
          this.integrations = g;
        });
  }

  clearFilters(event: boolean): void {
    this.reportErrorsGridComponent.clearFilters();
  }

  getClients(): void {
    this.gettingClients = true;
    this.clientService.getAllClients().subscribe(
      clients => {
        this.clients = clients;
        this.gettingClients = false;
      },
      error => {
        this.gettingClients = false;
      }
    );
  }

  resetAll(): void {
    this.tabIndex = 0;
    this.dataLoading = true;
    this.errorsPopulated = false;
    this.fullErrorsList = [];
    this.integrations = [];
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
