import { Component, OnInit, OnDestroy, Input, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { Observable, Subscription, Subject, forkJoin } from "rxjs";
import { State } from "@progress/kendo-data-query";
import { takeUntil } from 'rxjs/operators';
import { BaseMappingService } from "../../services/base-mapping.service";

import { GenericEndpoint } from "../../models/generic-endpoint";
import { User } from "../../models/user";
import { MonacoPageComponent } from "../monaco-editor/monaco-editor.component";
import { ToastService } from '../../services/toast.service';
import { GenericEndpointJavaScript } from 'src/app/models/generic-endpoint-javascript';
import { LOSTalkerTitleService } from "../../services/lostalker-title-service.service";
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router'
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-generic-endpoints',
  templateUrl: './edit-generic-endpoints.component.html',
  styleUrls: ['./edit-generic-endpoints.component.scss'],
  providers: [BaseMappingService, LOSTalkerTitleService]
})
export class EditGenericEndpointsComponent implements OnInit, OnDestroy {

  @ViewChild('confirmDeleteDialog', { static: true }) confirmDialog: TemplateRef<any>;

  constructor(
      private baseMappingService: BaseMappingService,
      private toastService: ToastService,
      private route: ActivatedRoute,
      private location: Location,
      private user: User,
      private titleService: LOSTalkerTitleService,
      private dialog: MatDialog,
      private router: Router
  ) { }

  componentDestroyed$: Subject<boolean> = new Subject();
  @ViewChild('monaco') public child: MonacoPageComponent;
  @ViewChild('monacoHistory') public historyChild: MonacoPageComponent;
  genericEndpoint: GenericEndpoint;
  showHistory: boolean = false;
  baseHistory: GenericEndpoint[];
  javascriptBaseHistory: GenericEndpointJavaScript[];
  selectedHistory: GenericEndpoint;
  javascriptSelectedHistory: GenericEndpointJavaScript;
  selectedJavascript: GenericEndpointJavaScript;
  sub: any;
  name: string;
  js: string = "javascript";
  xml: string = "xml";
  mappingSelected: boolean = false;
  mappingChanged: boolean = false;
  checked: boolean = true;
  historyChanged: boolean = false;
  historyChecked: boolean = true;
  monacoData: string = '<xml></xml>';
  monacoHistoryData: string = '';
  monacoHistoryLanguage: string = 'xml';
  monacoOriginalData: string = '<xml/>';
  historyData = '';
  historyCurrentData = '';
  javascriptList: GenericEndpointJavaScript[]

  originalBaseMappingXMLConfiguration: string = '<xml/>';
  originalBaseMappingJS: string = '';
  pageLoading: boolean = true;

  useV2Logic: boolean = false;

  ngOnInit(): void {

    if(environment.enableAPIVersioning && environment.apiVersion == "v2")
    {
      this.useV2Logic = true;
    }

    this.sub = this.route.params.subscribe(params => {
      this.baseMappingService.getGenericEndpointById(params['id']).subscribe((bm) => {
        this.genericEndpoint = bm;

        if (this.genericEndpoint != null) {
          this.titleService.setUpTitle('Edit Generic Endpoint - '
          + this.genericEndpoint.name + " - " + this.genericEndpoint.destination + " - " + this.genericEndpoint.importType);
        }

        this.monacoOriginalData = bm.xmlConfiguration;

        if (true === this.checked) {
          this.monacoData = bm.xmlConfiguration;

        } else {
          this.monacoData = bm.javaScript;
        }

        this.originalBaseMappingXMLConfiguration = this.genericEndpoint.xmlConfiguration;
        this.originalBaseMappingJS = this.genericEndpoint.javaScript;

        if(this.useV2Logic)
          {
            this.baseMappingService.getGenericEndpointHistoryById(this.genericEndpoint.genericEndpointId)
              .subscribe((endpointHistory) => {
                this.baseHistory = endpointHistory;
                this.showHistoryDropDown();
                this.pageLoading = false;
              }
              , error => {
                this.pageLoading = false;
              }
            );
          }
          else
          {
            this.javascriptList = [{genericEndpointId: this.genericEndpoint.genericEndpointId, javaScriptId: this.genericEndpoint.javaScriptId, javaScript: this.genericEndpoint.javaScript, updatedDate: new Date()}]

            forkJoin({
              endpointHistory: this.baseMappingService.getGenericEndpointHistory(this.genericEndpoint.name),
              endpointJavaScriptHistory: this.baseMappingService.getGenericEndpointJavaScriptHistory(this.genericEndpoint.name)})
              .subscribe(({endpointHistory, endpointJavaScriptHistory}) => {
                this.baseHistory = endpointHistory;
                this.javascriptBaseHistory = endpointJavaScriptHistory;
                this.showHistoryDropDown();
                this.pageLoading = false;
              }
              , error => {
                this.pageLoading = false;
              }
            );
          }

      }, error => {
        this.pageLoading = false;
      });
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  toggleDataXMLJS(changingToXML: boolean): void {
    if (true === changingToXML) {
      this.genericEndpoint.javaScript = this.monacoData;
      this.child?.changeLanguage("xml");
      this.monacoData = this.genericEndpoint.xmlConfiguration;
    } else {
      this.genericEndpoint.xmlConfiguration = this.monacoData;
      this.child?.changeLanguage("javascript");
      this.monacoData = this.genericEndpoint.javaScript;
    }
  }

  toggleDataHistoryXMLJS(changingToXML: boolean): void {
    if (true === changingToXML) {
      this.selectedHistory = null;
      this.monacoOriginalData = null;
      this.monacoHistoryData = null;
    } else {
      this.selectedHistory = null;
      this.selectedJavascript = null;
      this.monacoOriginalData = null;
      this.monacoHistoryData = null;
    }
  }

  goBack(): void {
    this.location.back();
  }

  canDeactivate(): boolean {
    let canLeave = true;

    if (true == this.checked) {
      this.genericEndpoint.xmlConfiguration = this.monacoData;
    } else {
      this.genericEndpoint.javaScript = this.monacoData;
    }

    if (this.originalBaseMappingXMLConfiguration != this.genericEndpoint.xmlConfiguration) {
      canLeave = false;
    }

    if (this.originalBaseMappingJS != this.genericEndpoint.javaScript) {
      canLeave = false;
    }

    return canLeave;
  }

  checkAccess(role: string[]): boolean {
    //User requires DevOps.Write or Admin

    return this.user.checkAccess(role);
  }

  getValidName(n: string): void {
    if (n.includes("%2F") || n.includes("/")) {
      this.name = n.replace("%2F", "_");
      this.name = n.replace("/", "_");
    }
    else {
      this.name = n;
    }
  }


  updateDataModel($event) {
    this.monacoData = $event;
  }

  updateGenericEndpoint(): void {

    if (true === this.checked) {
      this.genericEndpoint.xmlConfiguration = this.monacoData;

    } else {
      this.genericEndpoint.javaScript = this.monacoData;
    }

    this.genericEndpoint.updatedDate = new Date();

    this.baseMappingService.updateGenericEndpoint(this.genericEndpoint)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(value => {
          // on success
          this.toastService.toastCreate("Successfully updated " + this.genericEndpoint.name + " generic endpoint.", "Success", {
            autoClose: false
          });
          this.originalBaseMappingJS = this.genericEndpoint.javaScript;
          this.originalBaseMappingXMLConfiguration = this.genericEndpoint.xmlConfiguration;
      });
  }

  showHistoryDropDown(): void {
    this.showHistory = true;
  }

  selectBaseHistory(genericEndpoint: GenericEndpoint): void {
    this.selectedHistory = genericEndpoint;

    this.historyCurrentData = this.genericEndpoint.xmlConfiguration;
    this.historyData = this.selectedHistory.xmlConfiguration;

    if (this.historyChanged) {
      this.historyChanged = false;
    }
    else {
      this.historyChanged = true;
    }
  }

  selectBaseJavaScriptHistory(genericEndpointJavaScript: GenericEndpointJavaScript): void {
    this.selectedJavascript = genericEndpointJavaScript;

    this.historyCurrentData = this.selectedJavascript.javaScript;

    if (this.historyChanged) {
      this.historyChanged = false;
    }
    else {
      this.historyChanged = true;
    }
  }

  selectJsGenericEndpointCompare(genericEndpointJavaScript: GenericEndpointJavaScript): void {
    this.javascriptSelectedHistory = genericEndpointJavaScript;
    this.historyData = this.javascriptSelectedHistory.javaScript;

    if (this.historyChanged) {
      this.historyChanged = false;
    }
    else {
      this.historyChanged = true;
    }
  }
  
  selectCompareJavaScript(genericEndpoint: GenericEndpoint): void {
    this.selectedHistory = genericEndpoint;
    this.historyCurrentData = this.genericEndpoint.javaScript;
    this.historyData = genericEndpoint.javaScript;

    if (this.historyChanged) {
      this.historyChanged = false;
    }
    else {
      this.historyChanged = true;
    }
  }

  // could reload page and omit this method
  selectBaseMapping(b: GenericEndpoint): void {
    this.genericEndpoint = b;


    if (true === this.checked) {
      this.monacoData = b.xmlConfiguration;

    } else {
      this.monacoData = b.javaScript;
    }

    this.mappingSelected = true;
    if (this.mappingChanged) {
      this.mappingChanged = false;
    }
    else {
      this.mappingChanged = true;
    }
    this.selectedHistory = null;

  }

  updateGenericEndpointFromHistory(historyType: string): void {

    var updatedDate;

    if(historyType == 'xml') {
      this.genericEndpoint.xmlConfiguration = this.selectedHistory.xmlConfiguration;
      updatedDate = this.selectedHistory.updatedDate;
    } else if(historyType == 'js') {
      this.genericEndpoint.javaScript = this.javascriptSelectedHistory.javaScript;
      updatedDate = this.javascriptSelectedHistory.updatedDate;
    } else return;

    if (this.historyChecked) {
      this.monacoData = this.genericEndpoint.xmlConfiguration;
    } else {
      this.monacoData = this.genericEndpoint.javaScript;
    }

    this.baseMappingService.updateGenericEndpoint(this.genericEndpoint)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        () => {
          // on success
          this.toastService.toastCreate("Successfully reverted " + this.genericEndpoint.name + " to " + updatedDate + ".", "Success", {
            autoClose: false
          });
          this.originalBaseMappingJS = this.genericEndpoint.javaScript;
          this.originalBaseMappingXMLConfiguration = this.genericEndpoint.xmlConfiguration;

          this.selectBaseMapping(this.genericEndpoint);
        });
    // update page
    this.selectedHistory = null;
    this.javascriptSelectedHistory = null;
    this.historyChanged = false;
    this.showHistory = false;
  }

  // Dialog actions

  closeDialog(): void {
    this.dialog.closeAll();
  }

  confirmDelete(event: any, genericEndpointId: string): void {
      this.dialog.open(this.confirmDialog);
  }

  deleteGenericEndpoint(): void {
    this.dialog.closeAll();
    this.baseMappingService.deleteGenericEndpoint(this.genericEndpoint)
    .subscribe(value => {
      // on success

      // navigate to the generic endpoints page
      this.router.navigate(["/generic-endpoints"]);

      // show successful deletion of endpoint
      this.toastService.toastCreate("Successfully deleted  " + this.genericEndpoint.name + " generic endpoint.", "Success", {
        autoClose: false
      });


    })
  }

  enableAPIConversion: boolean;
  apiName: string;
  convertingToAPI: boolean;

  isSDKEndpoint(): boolean {
    return !this.genericEndpoint?.name?.toLowerCase().includes("api");
  }

  createAPIGenericEndpoint(): void {
    this.convertingToAPI = true;

    var lowerCaseName = this.genericEndpoint?.name?.toLowerCase();

    if(lowerCaseName?.includes("byte"))
    {
      this.toastService.toastCreate("Byte endpoints not currently supported.", "Warning", {
        autoClose: false
      });
      this.convertingToAPI = false;
      return;
    }
    if(lowerCaseName?.includes("csv")
      || lowerCaseName?.includes("kpi")
      || lowerCaseName?.includes("completedloan")
      || lowerCaseName?.includes("integra")
      || lowerCaseName?.includes("lendingqb")
      || lowerCaseName?.includes("pclender")
      || lowerCaseName?.includes("mortgagebot"))
    {
      this.toastService.toastCreate("This endpoint does not have an API version to convert to.", "Warning", {
        autoClose: false
      });
      this.convertingToAPI = false;
      return;
    }

    this.baseMappingService.convertEndpointToAPI(this.genericEndpoint, this.apiName)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (id) => {
          this.router.navigate(["/editGenericEndpoint/" + id]);
        }
      )

  }
}
