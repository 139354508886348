<h1 mat-dialog-title *ngIf="viewingClient && finishedLoading">Client Information</h1>
<h1 mat-dialog-title *ngIf="editingClient">Edit Client</h1>
<h1 mat-dialog-title *ngIf="mergingClient">Merge Client</h1>
<h1 mat-dialog-title *ngIf="mergeClientConfirm">Confirm Merge</h1>

<mat-dialog-content>
  <div *ngIf="viewingClient && finishedLoading" class="">

      <h5>Name: {{client.name}}</h5>
      <br>

      <h5>Integration Abbreviated Name: {{client.clientIntegrationAbbrName}}</h5>
      <br>

      <h5>CompenSafe Internal Name: {{client.compensafeInternalName}}</h5>
      <br>

      <h5>Aliases: {{client.aliases}}</h5>
      <br>

      <button
          mat-raised-button (click)="setEditing()" [disabled]="!checkAccess(['LOSTalker.Admin'])">
          Edit
      </button>

      <button style="float: left;"
          mat-raised-button (click)="setMerging()" [disabled]="!checkAccess(['LOSTalker.Admin'])">
          Merge
      </button>

      <button style="float: left;" *ngIf="!clientInformation" [disabled]="addingClientInfo || !checkAccess(['LOSTalker.Admin'])"
          mat-raised-button (click)="addClientInformation()">
          Populate Client Information
      </button>

      <button style="float: right;"
          mat-raised-button matDialogClose>
          Close
      </button>

  </div>

  <div *ngIf="editingClient">

      <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Client Name</mat-label>
          <input matInput [(ngModel)]="clientName">
      </mat-form-field>
      <br>

      <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Integration Abbreviated Name</mat-label>
          <input matInput [(ngModel)]="clientIntegrationAbbrName">
      </mat-form-field>
      <br>

      <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>CompenSafe Internal Name</mat-label>
          <input matInput [(ngModel)]="compensafeInternalName">
      </mat-form-field>
      <br>

      <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Aliases</mat-label>
          <input matInput [(ngModel)]="aliases" />
      </mat-form-field>
      <br>

      <div *ngIf="clientInformation">
          Update Client Information
          <kendo-switch
              [(ngModel)]="updateClientInfo"
          ></kendo-switch>
          <br><br>
      </div>

      <button
          mat-raised-button matDialogClose (click)="updateClient()" [disabled]="!checkAccess(['LOSTalker.Admin'])">
          Save Changes
      </button>
      <button
          mat-raised-button (click)="cancel()" style="float: right;">
          Back
      </button>
  </div>

  <div *ngIf="mergingClient">

      <div>
          <div class="client-box">

              <h5>Name: {{client.name}}</h5>

              <h5>Integration Abbreviated Name: {{client.clientIntegrationAbbrName}}</h5>

              <h5>CompenSafe Internal Name: {{client.compensafeInternalName}}</h5>

              <h6>Integrations: </h6>

              <div>
                  <h6 *ngFor="let integration of integrations" style="float: left; padding-right: 10px; padding-left: 10px;">{{integration.name}}</h6>
              </div>



          </div>
          <div class="center">
              <br/>
              <br/>
              <br/>
              <h2>Merge into</h2>
              <br/>
              <br/>
          </div>
          <div class="client">

              <mat-form-field appearance="fill" style="min-width: 325px;">
                  <mat-label>Select Client To Merge</mat-label>
                  <input type="text"
                        matInput
                        [matAutocomplete]="auto"
                        [formControl]="myControl">
                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                                    (optionSelected)="selectMergeClient($event.option.value)"
                                    [displayWith]="displayClientName">

                      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                          {{option.name}}
                      </mat-option>
                  </mat-autocomplete>
              </mat-form-field>

              <div *ngIf="mergeClientSelected" class="client-box">

                  <h5>Name: {{mergeClient.name}}</h5>

                  <h5>Integration Abbreviated Name: {{mergeClient.clientIntegrationAbbrName}}</h5>

                  <h5>CompenSafe Internal Name: {{mergeClient.compensafeInternalName}}</h5>

                  <h6>Integrations: </h6>

                  <div *ngFor="let integration of mergeClientIntegrations">
                      <h6 style="float: left; padding-right: 10px; padding-left: 10px;">{{integration.name}}</h6>
                  </div>

              </div>

              <br>
              <br>
          </div>
      </div>

      <button
          mat-raised-button (click)="submit()" [disabled]="!mergeClientSelected || !checkAccess(['LOSTalker.Admin'])">
          Submit
      </button>

      <button
          mat-raised-button (click)="cancel()" style="float: right;">
          Back
      </button>
  </div>

  <div *ngIf="mergeClientConfirm">

      <div>

          <h4>The client {{client.name}} will be <b>deleted</b>, <br>
              and all integrations will be moved to <br>
              {{mergeClient.name}}, resulting in this client.</h4>

          <br/>
          <br/>

          <div class="client-box">

              <h5>Name: {{mergeClient.name}}</h5>

              <h5>Integration Abbreviated Name: {{mergeClient.clientIntegrationAbbrName}}</h5>

              <h5>CompenSafe Internal Name: {{mergeClient.compensafeInternalName}}</h5>

              <h6>Integrations: </h6>

              <div *ngFor="let integration of mergeClientIntegrations">
                  <h6 style="float: left; padding-right: 10px; padding-left: 10px;">{{integration.name}}</h6>
              </div>

              <div *ngFor="let integration of integrations">
                  <h6 style="float: left; padding-right: 10px; padding-left: 10px;">{{integration.name}}</h6>
              </div>

          </div>

          <br/>
          <br/>
          <br/>

          <h4>Are you sure you wish to continue?</h4>

          <br/>
          <br/>

      </div>

      <button
          mat-raised-button matDialogClose (click)="mergeClients()" [disabled]="!mergeClientSelected || !checkAccess(['LOSTalker.Admin'])">
          Submit
      </button>

      <button
          mat-raised-button (click)="cancel()" style="float: right;">
          Back
      </button>

  </div>
</mat-dialog-content>
