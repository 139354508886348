<ng-container *ngIf="isInTab">
  <h3 class="title-center pt-2">Add Custom SQL Query</h3>
  <div class="pb-2 ps-2" style="float: right;">
    <button mat-raised-button (click)="toggleCancelEditIdentifier()">Cancel</button>
  </div>
</ng-container>

<ng-container *ngIf="!isInTab">
  <h1 class="title-center pb-2">Add Custom SQL Query</h1>
</ng-container>

<div *ngIf="isInTab && integration">
  <div *ngIf="!queryType">
    <mat-form-field appearance="fill" class="med-wide-text-box">
      <mat-label>Query Type</mat-label>
      <input type="text" matInput [matAutocomplete]="auto" [formControl]="queryTypeFormControl">
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
        (optionSelected)="selectQueryType($event.option.value)" [displayWith]="displayFnQueryType">
        <mat-option *ngFor="let option of filteredQueryTypes | async" [value]="option">
          {{option.queryTypeName}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <div *ngIf="queryType" class="flexWrapTabDest">
    <h5>Query Type: {{queryType.queryTypeName}}</h5>
    <div class="pb-2 pl-2">
      <button mat-raised-button (click)="editQueryType()">Change</button>
    </div>
  </div>
</div>

<div class="flexWrap">

  <div class="flexLeftCol" *ngIf="!isInTab">
    <ng-container *ngIf="!client">
      <mat-form-field appearance="fill" class="med-wide-text-box">
        <mat-label>Client</mat-label>
        <input type="text" matInput [matAutocomplete]="auto" [formControl]="clientFormControl">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
          (optionSelected)="selectClient($event.option.value)" [displayWith]="displayFnClient">
          <mat-option *ngFor="let option of filteredClients | async" [value]="option">
            {{option.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="client && !isInTab">
      <div class="flexFormLine">
        <ng-container *ngIf="isInTab">
          <h4>Client: {{client.name}}</h4>
        </ng-container>

        <ng-container *ngIf="!isInTab">
          <h2>Client: {{client.name}}</h2>
        </ng-container>

        <div class="pb-2">
          <button mat-raised-button (click)="editClient()">Change</button>
        </div>
      </div>
      <div *ngIf="!hasIntegration">
        <mat-form-field appearance="fill" class="med-wide-text-box">
          <mat-label>Integration</mat-label>
          <input type="text" matInput [matAutocomplete]="auto" [formControl]="integrationFormControl">
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
            (optionSelected)="selectIntegration($event.option.value)" [displayWith]="displayFnIntegration">
            <mat-option *ngFor="let option of filteredIntegrations | async" [value]="option">
              {{option.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </ng-container>

    <div *ngIf="hasIntegration && !isInTab" class="flexFormLine">
      <h5>Integration: {{integration.name}}</h5>
      <div class="pb-2">
        <button mat-raised-button (click)="editIntegration()">Change</button>
      </div>
    </div>

    <div *ngIf="hasIntegration && !hasQueryType">
      <mat-form-field appearance="fill" class="med-wide-text-box">
        <mat-label>Query Type</mat-label>
        <input type="text" matInput [matAutocomplete]="auto" [formControl]="queryTypeFormControl">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
          (optionSelected)="selectQueryType($event.option.value)" [displayWith]="displayFnQueryType">
          <mat-option *ngFor="let option of filteredQueryTypes | async" [value]="option">
            {{option.queryTypeName}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div *ngIf="hasIntegration && hasQueryType" class="flexFormLine">
      <h5>QueryType: {{queryType.queryTypeName}}</h5>
      <div class="pb-2">
        <button mat-raised-button (click)="editQueryType()">Change</button>
      </div>    
    </div>
    <br/>
    <div *ngIf="hasIntegration && hasQueryType">
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Query Name</mat-label>
        <input matInput [(ngModel)]="queryName">
      </mat-form-field>
    </div>
  </div>

  <div [ngClass]="{'flexRightCol': !isInTab, 'flexWrapTabInner': isInTab}" *ngIf="queryType">
    <app-monaco-editor #monaco
        (textChange)="monacoData = $event"
        [editorData]="monacoData"
        [lang]="'sql'"
        [editAccess]="true"
    ></app-monaco-editor>
    <br/>
    <div class="submit">
      <button id="btnSaveQuery"
        kendoButton (click)="addCustomQuery()" [primary]="true" [disabled]="savingQuery">
        Add Custom Query
      </button>
      <div class="k-i-loading" *ngIf="savingQuery"></div>
    </div>
  </div>

</div>

<div style="margin: auto; margin-top:0%; text-align: center;" *ngIf="pageLoading">
  <div class="k-i-loading"></div>
</div> 

<div style="margin: auto; margin-top:0%; text-align: center;" *ngIf="!pageLoading && !queryTypes">
  <div class="no-records-message">Loading failed.</div>
</div> 