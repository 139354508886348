import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from '../../models/user';
import { Observable } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { forkJoin } from 'rxjs';
import { ClientIntegrationService } from 'src/app/services/client-integration.service';


@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavMenuComponent implements OnInit {

  url: string = '';
  appComponent: AppComponent;
  serverError: boolean = false;
  showV2API: boolean = false;
  constructor(private http: HttpClient, public user: User, private root: AppComponent) {
    //, private clientIntegrationService: ClientIntegrationService
    this.appComponent = root;
    this.showV2API = environment.enableAPIVersioning;
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occured', error) // for testing purposes only, need to handle
    return Promise.reject(error.message || error);
  }

  ngOnInit(): void {
    //this.checkAllServersLastDataSent();
  }

  checkAccess(role: string[]): boolean {
    //User requires DevOps.Write or Admin

    return this.user.checkAccess(role);
  }

  logout(): void {
    this.root.logout(true);
  }

  //Thinking adding last data sent check to nav menu could cause too many issues for the moment

  // public checkAllServersLastDataSent() {
  //   forkJoin({
  //     serverList: this.clientIntegrationService.getAllServersInformation(), 
  //     map: this.clientIntegrationService.getAllServersLastDataSent()}).subscribe(({map, serverList}) => {
  //       for(let key in map) {
  //         map[key] = new Date(map[key]);

  //         let serverAlertTimespan = serverList.find(s => s.serverId == key).serverAlertTimespan;
          
  //         // Check if current time is far enough ahead of lastdatasent
  //         if(map[key].getTime() < Date.now() - (serverAlertTimespan * 60 * 1000)) {
  //           this.serverError = true;
  //           break;
  //         }
  //       }
  //   });

  // }

  //setWidth(navWidth: number) {
  //  this.navWidth = navWidth;
  //}
  //setIsMobile(val: boolean) {
  //  this.isMobile = val;
  //}
  //setMobileOpened(val: boolean) {
  //  //this.isMobileMenuOpened = val;
  //}

}
