import { Component, Input, Output, OnInit, TemplateRef, EventEmitter } from "@angular/core";
import { GridDataResult } from "@progress/kendo-angular-grid";
import { forkJoin, Observable } from "rxjs";
import { Client } from "src/app/models/client";
import { ClientContract } from "src/app/models/client-contract";
import { ClientInformation } from "src/app/models/client-information";
import { DestinationEndpoint } from "src/app/models/destination-endpoint";
import { IntegrationIdentifier } from "src/app/models/integration-identifier";
import { User } from "src/app/models/user";
import { ClientContractService } from "src/app/services/client-contract.service";
import { ClientInformationService } from "src/app/services/client-information.service";
import { ClientIntegrationService } from "src/app/services/client-integration.service";
import { IdentifierService } from "src/app/services/identifier.service";
import { ToastService } from "src/app/services/toast.service";
import { TypeConstantService } from "src/app/services/type-constant.service";
import { Router } from "@angular/router";
import { IntegrationMapping } from "src/app/models/integration-mapping";
import { Integration } from "src/app/models/integration";
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-client-contracts',
  templateUrl: "./client-contracts.component.html",
  styleUrls: ['./client-contracts.component.scss'],
  providers: [ ClientIntegrationService ]
})
export class ClientContractComponent implements OnInit {

  // Constant variables
  @Input() client: Client;
  @Input() integrations: Integration[];
  @Input() selectedIntegrationId: string;
  @Input() integrationIdentifiers: IntegrationIdentifier[];
  @Input() clientContracts: ClientContract[];
  @Output() outputContract = new EventEmitter<ClientContract>();
  clientInfo: ClientInformation;
  destinationEndpoints: DestinationEndpoint[] = [];
  currentMappedDestinations: DestinationEndpoint[] = [];

  contractIdentifier: IntegrationIdentifier = null;
  selectedContract:ClientContract = null;
  availableIdentifiers: IntegrationIdentifier[] = [];

  // Status Variables
  contractsLoading: boolean = true;
  addingContract: boolean = false;
  editingContract: boolean = false;
  identifierMismatch: boolean = false;
  reprocessingInvoicePeriods: boolean = false;
  finishedLoading: boolean = false;
  viewingContract: boolean = true;
  savingClientContract: boolean = false;
  disablingContract: boolean = false;
  enablingContract: boolean = false;
  editingContractStartDate: boolean = false;
  editingBillingStartDate: boolean = false;
  editingContractTermDate: boolean = false;
  editingContractReinstatedDate: boolean = false;
  deleteContractTermDate: boolean = false;
  deleteContractReinstatedDate: boolean = false;
  currentCompletedField: string = "";

  constructor(
    private toastService: ToastService,
    private clientContractService: ClientContractService,
    private identifierService: IdentifierService,
    private clientInformationService: ClientInformationService,
    private clientIntegrationService: ClientIntegrationService,
    private typeConstantService: TypeConstantService,
    private router: Router,
    private user: User
  ) {}

  ngOnInit(): void {

    forkJoin({
      info: this.clientInformationService.getClientInformationByClientId(this.client.clientId),
      destinations: this.typeConstantService.getAllDestinationEndpoints()
    })
    .subscribe(({info, destinations}) => {
      this.destinationEndpoints = destinations;
      this.clientInfo = info;
      if(this.integrationIdentifiers.length == 0 || this.clientInfo == null)
      {
        this.viewingContract = false;
        this.contractsLoading = false;
      }
      else
      {
        this.setupIdentifiers();
      }
    });
  }

  public ngOnDestroy(): void {}

  setCancel() {
    this.cancel();
    this.reloadContracts();
    this.savingClientContract = false;
    this.deleteContractTermDate = false;
    this.deleteContractReinstatedDate = false;
    this.editingBillingStartDate = false;
    this.editingContractStartDate = false;
    this.editingContractTermDate = false;
    this.editingContractReinstatedDate = false;
  }

  cancel() {
    this.disablingContract = false;
    this.enablingContract = false;
    this.addingContract = false;
    this.editingContract = false;
    this.reprocessingInvoicePeriods = false;
    this.viewingContract = true;
    this.selectedContract = null;


    for (let i = 0; i < this.availableIdentifiers.length; i++) {
      for (let j = 0; j < this.clientContracts.length; j++) {
        if (this.availableIdentifiers[i].identifierIdValue == this.clientContracts[j].identifierIdValue
          && this.availableIdentifiers[i].destinationId == this.clientContracts[j].destinationEndpointId) {
          this.availableIdentifiers.splice(i, 1);
          i--;
          break;
        }
      }
    }
  }

  setAdding() {
    this.editingContractStartDate = true;
    this.editingBillingStartDate = true;
    this.viewingContract = false;
    this.savingClientContract = false;
    this.addingContract = true;
    this.currentCompletedField = "";
    this.selectedContract = {} as ClientContract;
    this.selectedContract.integrationMappings = [];
  }

  setEditing(c: ClientContract) {
    this.selectedContract = new ClientContract(c);
    this.viewingContract = false;
    this.editingContract = true;
    this.savingClientContract = false;

    this.initializeContractVariables();

    //If identifier id is the same as current, re add to available identifiers
    for (let identifier of this.integrationIdentifiers) {
      if (identifier.identifierIdValue == this.selectedContract.identifierIdValue) {
        this.availableIdentifiers.push(identifier);
      }
    }
  }

  goToLoans(c: ClientContract) {
    this.router.navigate(["/invoiced-loans-report", c.clientContractId]);
  }

  goToMetrics(c: ClientContract) {
    this.router.navigate(["/invoiced-metrics-report", c.clientContractId]);
  }

  selectionChangeHandler(event: any): void {
    this.selectedContract = event.selectedRows[0].dataItem;
  }

  selectContract(c: ClientContract): void {
    this.selectedContract = new ClientContract(c);
  }

  isNullOrEmptyString(x: any) {
    return x === null || x === undefined || x.trim() === "";
  }

  addClientContract(): void {
    this.savingClientContract = true;

    if (this.contractIdentifier == null || this.currentCompletedField == null) {
      this.toastService.toastCreate("Enter all contract information before adding.", "Warning", {
        autoClose: false
      });
      this.savingClientContract = false;
      return;
    }

    this.selectedContract.clientInformationId = this.clientInfo.clientInformationId;
    this.selectedContract.updatedDate = new Date();
    this.selectedContract.losTalkerClientId = this.client.clientId;
    this.selectedContract.clientShortName = this.client.compensafeInternalName;
    this.selectedContract.clientFullName = this.client.name;

    if (this.selectedContract.integrationMappings.findIndex(i => i.integrationId == this.contractIdentifier.integrationId) == -1) {
      let integrationMapping: IntegrationMapping = {
        clientContractId: this.selectedContract.clientContractId,
        integrationId: this.contractIdentifier.integrationId,
        integrationMappingId: "00000000-0000-0000-0000-000000000000"
      }

      this.selectedContract.integrationMappings.push(integrationMapping);
    }

      this.clientContractService.insertClientContract(this.selectedContract)
      .subscribe(() => {
        this.outputContract.emit(this.selectedContract);
        this.cancel();
        this.reloadContracts();
        this.savingClientContract = false;
        this.editingBillingStartDate = false;
        this.editingContractStartDate = false;
        this.editingContractTermDate = false;
        this.editingContractReinstatedDate = false;
      });
  }

  getMappedIntegrationsDisplay(mappedIntId: IntegrationMapping[]): string {
    if (mappedIntId != null && mappedIntId.length == 1) {
      var selectedInt = this.integrations.find(i => i.integrationId == mappedIntId[0].integrationId);
      if (selectedInt != null) {
        return selectedInt.name;
      }
    }
    else if (mappedIntId != null && mappedIntId.length > 1){
      return mappedIntId.length + " Integrations"
    }
    return "Not Mapped";
  }

  getIntegrationName(integrationId: string){
    var selectedInt = this.integrations.find(i => i.integrationId == integrationId);
    if (selectedInt != null) {
      return selectedInt.name;
    }
  }

  addMapping(c: ClientContract): void {
    this.savingClientContract = true;
    this.selectedContract = new ClientContract(c);

    if (this.selectedIntegrationId != undefined){

      if(this.selectedContract.integrationMappings.find(i => i.integrationId == this.selectedIntegrationId) != undefined){
        this.toastService.toastCreate("Integration Mapping for Current Integration already exists. No new mapping added.", "Warning", {
          autoClose: false
        });
      }
      else{

        let mapping: IntegrationMapping = {
          integrationMappingId: "00000000-0000-0000-0000-000000000000",
          integrationId: this.selectedIntegrationId,
          clientContractId: this.selectedContract.clientContractId
        }

        this.clientContractService.insertIntegrationMapping(mapping).subscribe(() => {
          this.cancel();
          this.reloadContracts();
          this.savingClientContract = false;
          this.editingBillingStartDate = false;
          this.editingContractStartDate = false;
          this.editingContractTermDate = false;
          this.editingContractReinstatedDate = false;
        });
      }
    }
    else {
      this.toastService.toastCreate("Integration not Correctly passed in to Client Contract component", "Warning", {
        autoClose: false
      });
    }
  }

  deleteIntegrationMapping(integrationMap: IntegrationMapping) {
    this.savingClientContract = true;
    this.clientContractService.deleteIntegrationMapping(integrationMap).subscribe(() => {
      this.selectedContract.integrationMappings = this.selectedContract.integrationMappings.filter(i => i.integrationId != integrationMap.integrationId);
      this.savingClientContract = false;
      this.clientContracts[this.clientContracts.findIndex(c => c.clientContractId == this.selectedContract.clientContractId)] = this.selectedContract;

      this.toastService.toastCreate("Integration mapping deleted for: " + integrationMap.integrationId, "Success", {
        autoClose: true
      });
    });
  }

  updateClientContract(): void {
    this.savingClientContract = true;

    if (this.contractIdentifier == null || this.currentCompletedField == null) {
      this.toastService.toastCreate("Required Contract fields not populated", "Warning", {
        autoClose: false
      });
      this.savingClientContract = false;
      return;
    }

    forkJoin({
      identifier: this.identifierService.updateIntegrationIdentifier(this.contractIdentifier),
      contractReturn: this.clientContractService.updateClientContract(this.selectedContract)
    })
    .subscribe(({identifier, contractReturn}) => {
      this.outputContract.emit(this.selectedContract);
      this.cancel();
      this.reloadContracts();
      this.savingClientContract = false;
      this.deleteContractTermDate = false;
      this.deleteContractReinstatedDate = false;
      this.editingBillingStartDate = false;
      this.editingContractStartDate = false;
      this.editingContractTermDate = false;
      this.editingContractReinstatedDate = false;
    });
  }

  reloadContracts(): void {
    this.contractsLoading = true;

    forkJoin({
      identifiers: this.identifierService.getIntegrationIdentifiersByClient(this.client.clientId),
      contracts: this.clientContractService.getAllClientContractsByClient(this.client.clientId)
    })
    .subscribe(({identifiers, contracts}) => {
      this.clientContracts = contracts;
      
      this.integrationIdentifiers = identifiers;
      this.setupIdentifiers()
    });
  }

  setupIdentifiers(): void {
    this.availableIdentifiers = this.integrationIdentifiers.slice();

    let allCurrentDestinationIds = this.clientContracts.map(x => x.destinationEndpointId);
    this.availableIdentifiers = this.availableIdentifiers.filter(x => allCurrentDestinationIds.indexOf(x.destinationId) == -1);

    for (let i = 0; i < this.availableIdentifiers.length; i++) {
      for (let j = 0; j < this.clientContracts.length; j++) {
        if (this.availableIdentifiers[i].identifierIdValue == this.clientContracts[j].identifierIdValue) {
          this.availableIdentifiers.splice(i, 1);
          i--;
          break;
        }
      }
    }
    this.contractsLoading = false;
  }

  setEnabling(c: ClientContract): void {
    this.selectedContract = new ClientContract(c);
    this.viewingContract = false;
    this.disablingContract = false;
    this.enablingContract = true;
    this.savingClientContract = false;

    this.initializeContractVariables();
  }

  enableContract(): void {
    this.savingClientContract = true;
    this.clientContractService.undeleteClientContract(this.selectedContract).subscribe(() => {
      this.cancel();
      this.reloadContracts();
      this.savingClientContract = false;
    });
  }

  setReprocessInvoicePeriods(c: ClientContract): void {
    if (c.productionInvoiceReport == true) {
      this.toastService.toastCreate("Contract is a Production Invoice. Reprocessing Invoice Periods is not allowed.", "Danger");
    }
    else {
      this.selectedContract = c;
      this.viewingContract = false;
      this.reprocessingInvoicePeriods = true;

      this.initializeContractVariables();
    }
  }

  reprocessInvoicePeriods(): void {
    this.savingClientContract = true;
    this.clientContractService.setRecreateInvoicesForAuditPeriod(this.selectedContract.clientContractId, true).subscribe(() => {
      this.selectedContract.recreateInvoicesForAuditPeriod = true;
      this.cancel();
      this.reloadContracts();
      this.savingClientContract = false;
    });
  }

  setDisabling(c: ClientContract): void {
    this.selectedContract = new ClientContract(c);
    this.viewingContract = false;
    this.disablingContract = true;
    this.enablingContract = false;
    this.savingClientContract = false;

    this.initializeContractVariables();
  }

  disableContract(): void {
    this.savingClientContract = true;
    this.clientContractService.deleteClientContract(this.selectedContract).subscribe(() => {
      this.cancel();
      this.reloadContracts();
      this.savingClientContract = false;
    });
  }

  getDestinationEndpointString(destinationId): string {
    let str = "Destination Endpoint Error: Destination Not Found";
    for(let destination of this.destinationEndpoints)
    {
      if(destination.destinationEndpointId == destinationId)
      {
        str = destination.endpointName;
      }
    }
    return str;
  }

  checkAccess(role: string[]): boolean {
    return this.user.checkAccess(role);
  }

  initializeContractVariables(): void {
    this.selectedContract.clientInformationId = this.clientInfo.clientInformationId;
    this.selectedContract.losTalkerClientId = this.client.clientId;
    this.selectedContract.clientShortName = this.client.compensafeInternalName;
    this.selectedContract.clientFullName = this.client.name;
    this.selectedContract.endpointName = this.destinationEndpoints.find(d => d.destinationEndpointId = this.selectedContract.destinationEndpointId).endpointName;

    for(let identifier of this.integrationIdentifiers)
    {
      if(identifier.identifierIdValue == this.selectedContract.identifierIdValue
        && identifier.destinationId == this.selectedContract.destinationEndpointId)
      {
        this.contractIdentifier = identifier;
        break;
      }
    }



    this.currentCompletedField = this.selectedContract.currentCompletedField;
  }

  isCurrentIntegrationMapped(c: ClientContract): boolean {
    var rc = false;
    if (c.integrationMappings != undefined && this.selectedIntegrationId != undefined) {
      rc = c.integrationMappings.find(i => i.integrationId == this.selectedIntegrationId) != undefined;
    }

    return rc;
  }

  selectNewIdentifier(): void {
    //this.contractIdentifier = event;
    this.selectedContract.identifierNameValue = this.contractIdentifier.identifierNameValue;
    this.selectedContract.identifierKeyValue = this.contractIdentifier.identifierKeyValue;
    this.selectedContract.identifierIdValue = this.contractIdentifier.identifierIdValue;
    this.selectedContract.destinationEndpointId = this.contractIdentifier.destinationId;
    this.selectedContract.endpointName = this.contractIdentifier.destinationName;
  }
}

