import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BaseMappingService } from 'src/app/services/base-mapping.service';
import { forkJoin, Subject } from "rxjs";

import { ToastService } from '../../services/toast.service';

import { takeUntil } from "rxjs/operators";
import { MonacoPageComponent } from '../monaco-editor/monaco-editor.component';
import { GenericEndpoint } from 'src/app/models/generic-endpoint';
import { ClientIntegrationService } from 'src/app/services/client-integration.service';
import { ImportType } from 'src/app/models/import-type';
import { DestinationEndpoint } from 'src/app/models/destination-endpoint';
import { BaseMapping } from 'src/app/models/base-mapping';
import { TypeConstantService } from 'src/app/services/type-constant.service';

@Component({
    selector: 'app-add-base-mapping',
    templateUrl: './add-base-mapping.component.html',
    styleUrls: ['./add-base-mapping.component.scss'],
    providers: [ ClientIntegrationService ]
})
export class AddBaseMapping implements OnInit {

    baseMappingTypes: string[] = ["NonGeneric Base Mapping", 
                                  "Generic Endpoint"]
    baseMappingType: string = "";

    importTypes: ImportType[];
    importType: ImportType;

    destinations: DestinationEndpoint[];
    destination: DestinationEndpoint;

    baseMappingId: string = "";
    baseMappingName: string = "";
    baseMappingXmlConfig: string = "";
    baseMappingJavaScript: string = "";

    invalidInput: boolean = false;
    
    surefire3BaseMappingList: BaseMapping[];
    loanBaseMappingList: BaseMapping[];
    employeeBaseMappingList: BaseMapping[];
    branchBaseMappingList: BaseMapping[];
    totalExpertBaseMappingList: BaseMapping[];

    genericBaseMappingList: GenericEndpoint[];

    csDataSwapDestinationId: number;
    lgDataSwapDestinationId: number;
    surefire3DestinationId: number;
    noDestinationDestinationId: number;
    totalExpertDestinationId: number;
    simpleNexusDestinationId: number;

    loanImportTypeId: number;
    branchImportTypeId: number;
    employeeImportTypeId: number;
    completedLoanImportTypeId: number;

    componentDestroyed$: Subject<boolean> = new Subject();
    monacoData: string;

    checked: boolean = true;
    savingBaseMapping: boolean = false;
    baseMappingsLoading: boolean = true;

    constructor(
        protected baseMappingService: BaseMappingService,
        protected typeConstantService: TypeConstantService,
        private toastService: ToastService, 
        private router: Router
    ) { }

    @ViewChild('monaco') public child: MonacoPageComponent;

    ngOnInit(): void {
        //Need to update this page, one base mapping list, and one list for each type constant
        forkJoin({
            destinationsData: this.typeConstantService.getAllDestinationEndpoints(),
            importTypesData: this.typeConstantService.getAllImportTypes(),
            genericBaseMappingsData: this.baseMappingService.getGenericEndpoints()
        })
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(({ destinationsData, importTypesData, genericBaseMappingsData}) => {
            this.destinations = destinationsData;

            for(let destination of this.destinations)
            {
                if (destination.endpointName == 'CSDataSwap')
                {
                    this.csDataSwapDestinationId = destination.destinationEndpointId
                }
                else if (destination.endpointName == 'LGDataSwap')
                {
                    this.lgDataSwapDestinationId = destination.destinationEndpointId
                }
                else if (destination.endpointName == 'Surefire3')
                {
                    this.surefire3DestinationId = destination.destinationEndpointId
                }
                else if (destination.endpointName == 'NoDestination')
                {
                    this.noDestinationDestinationId = destination.destinationEndpointId
                }
                else if (destination.endpointName == 'TotalExpert')
                {
                    this.totalExpertDestinationId = destination.destinationEndpointId
                }
                else if (destination.endpointName == 'SimpleNexus')
                {
                    this.simpleNexusDestinationId = destination.destinationEndpointId
                }
            }

            this.importTypes = importTypesData;

            for(let importType of this.importTypes)
            {
                if (importType.typeDescription == 'Loan')
                {
                    this.loanImportTypeId = importType.importTypeId
                }
                else if (importType.typeDescription == 'Branch')
                {
                    this.branchImportTypeId = importType.importTypeId
                }
                else if (importType.typeDescription == 'Employee')
                {
                    this.employeeImportTypeId = importType.importTypeId
                }
                else if (importType.typeDescription == 'CompletedLoan')
                {
                    this.completedLoanImportTypeId = importType.importTypeId
                }
            }

            this.genericBaseMappingList = genericBaseMappingsData;

            this.baseMappingService.getAllBaseMappings()
                .pipe(takeUntil(this.componentDestroyed$))
                .subscribe( (nonGenericBaseMappings) => {

                this.surefire3BaseMappingList = [];
                this.loanBaseMappingList = [];
                this.employeeBaseMappingList = [];
                this.branchBaseMappingList = [];
                this.totalExpertBaseMappingList = [];

                for(let endpoint of nonGenericBaseMappings) {
                    if(endpoint.destinationEndpointId == this.csDataSwapDestinationId)
                    {
                        //CSDataSwap
                        if(endpoint.importTypeId == this.loanImportTypeId)
                        {
                            //Loan
                            this.loanBaseMappingList.push(endpoint);
                        }
                        else if(endpoint.importTypeId == this.branchImportTypeId)
                        {
                            //Branch
                            this.branchBaseMappingList.push(endpoint);
                        }
                        else if(endpoint.importTypeId == this.employeeImportTypeId)
                        {
                            //Employee
                            this.employeeBaseMappingList.push(endpoint);
                        }
                    }
                    else if(endpoint.destinationEndpointId == this.surefire3DestinationId)
                    {
                        //Surefire3
                        this.surefire3BaseMappingList.push(endpoint);
                    }
                    else if(endpoint.destinationEndpointId == this.totalExpertDestinationId)
                    {
                        //TotalExpert
                        this.totalExpertBaseMappingList.push(endpoint);
                    }
                }  

                this.baseMappingsLoading = false; 

            }, error => {
                this.baseMappingsLoading = false; 
            });
        }, error => {
            this.baseMappingsLoading = false; 
        });

    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true)
        this.componentDestroyed$.complete()
    }

    checkValidFields(): boolean {

        let validField = null;


        if(this.baseMappingType == "Generic Endpoint") {
            
            if  (   //Allow totalexpert loan
                    (this.destination.destinationEndpointId == this.totalExpertDestinationId && this.importType.importTypeId == this.loanImportTypeId) ||
                    //Allow surefire3 loan
                    (this.destination.destinationEndpointId == this.surefire3DestinationId && this.importType.importTypeId == this.loanImportTypeId) ||
                    //Allow CSDataSwap
                    (this.destination.destinationEndpointId == this.csDataSwapDestinationId) ||
                    //Allow LGDataSwap
                    (this.destination.destinationEndpointId == this.lgDataSwapDestinationId) ||
                    //Allow SimpleNexus
                    (this.destination.destinationEndpointId == this.simpleNexusDestinationId)
                )
            {
                validField = true;
            }

            for (let c of this.genericBaseMappingList)
            {
                if (c.name === this.baseMappingName)
                {
                    validField = false;
                }
            }
            
            if(validField == false)
            {
                this.toastService.toastCreate("Invalid Base Mapping Name", "Warning", {
                    keepAfterRouteChange: true
                });
            }

            if(validField == null)
            {
                this.toastService.toastCreate("Unrecognized Generic Base Mapping Destination and Type", "Warning", {
                    keepAfterRouteChange: true
                });
                validField = false;
            }

            return validField;
        }

        if(this.destination.endpointName == "CSDataSwap")
        {
            //CSDataSwap
            if(this.importType.typeDescription == "Loan")
            {
                //Loan
                validField = true;
                for (let c of this.loanBaseMappingList)
                {
                    if (c.name === this.baseMappingName)
                    {
                        validField = false;
                    }
                }
            }
            else if(this.importType.typeDescription == "Branch")
            {
                //Branch
                validField = true;
                for (let c of this.branchBaseMappingList)
                {
                    if (c.name === this.baseMappingName)
                    {
                        validField = false;
                    }
                }
            }
            else if(this.importType.typeDescription == "Employee")
            {
                //Employee
                validField = true;
                for (let c of this.employeeBaseMappingList)
                {
                    if (c.name === this.baseMappingName)
                    {
                        validField = false;
                    }
                }
            }
        }
        else if(this.destination.endpointName == "Surefire3" && this.importType.typeDescription == "Loan")
        {
            //Surefire3
            validField = true;
            for (let c of this.surefire3BaseMappingList)
            {
                if (c.name === this.baseMappingName)
                {
                    validField = false;
                }
            }
        }
        else if(this.destination.endpointName == "TotalExpert" && this.importType.typeDescription == "Loan")
        {
            //TotalExpert
            validField = true;
            for (let c of this.totalExpertBaseMappingList)
            {
                if (c.name === this.baseMappingName)
                {
                    validField = false;
                }
            }
        }

        if(validField == false)
        {
            this.toastService.toastCreate("Invalid Base Mapping Name", "Warning", {
                keepAfterRouteChange: true
            });
        }

        if(validField == null)
        {
            this.toastService.toastCreate("Unrecognized NonGeneric Base Mapping Destination and Type", "Warning", {
                keepAfterRouteChange: true
            });
            validField = false;
        }
        
        return validField;

    }

    goToEditBaseMapping(): void {
        this.toastService.toastCreate("Successfully added base mapping.", "Success", {
            keepAfterRouteChange: true
        });

        if(this.baseMappingType == "Generic Endpoint")
            this.router.navigate(['/editGenericEndpoint', this.baseMappingId]);
        else
            this.router.navigate(['/editBaseMapping', this.baseMappingId]);
    }

    addBaseMapping(): void {
        this.baseMappingsLoading = true;
        this.savingBaseMapping = true;
        if (!this.checkValidFields()) 
        {
            this.savingBaseMapping = false;
            return;
        }
        
        if (true === this.checked) {
            this.baseMappingXmlConfig = this.monacoData;
        } else {
            this.baseMappingJavaScript = this.monacoData;
        }

        if (this.baseMappingType == "NonGeneric Base Mapping") {
            let baseMapping: BaseMapping = {
                name: this.baseMappingName,
                xmlConfig: this.baseMappingXmlConfig,
                javaScript: this.baseMappingJavaScript,
                destinationEndpointId: this.destination.destinationEndpointId,
                importTypeId: this.importType.importTypeId,
                updatedDate: new Date()
            }
            this.baseMappingService.insertBaseMapping(baseMapping).subscribe(v => 
                {
                    this.baseMappingId = v; 
                    this.goToEditBaseMapping();
                }, 
                error => {
                    this.savingBaseMapping = false;
                    this.baseMappingsLoading = false;
                });
        } 
        else if (this.baseMappingType == "Generic Endpoint") { 
            let baseMapping: GenericEndpoint = {
                name: this.baseMappingName,
                xmlConfiguration: this.baseMappingXmlConfig,
                javaScript: this.baseMappingJavaScript,
                importTypeId: this.importType.importTypeId,
                destinationId: this.destination.destinationEndpointId,
                updatedDate: new Date()
            }
            this.baseMappingService.insertGenericEndpoint(baseMapping).subscribe(v => 
                { 
                    this.baseMappingId = v; 
                    this.goToEditBaseMapping(); 
                }, 
                error => { 
                    this.savingBaseMapping = false;
                    this.baseMappingsLoading = false;
                });
        } else {
            this.toastService.toastCreate("Invalid Base Mapping Type", "Warning", {
                keepAfterRouteChange: true
            }); 
            this.savingBaseMapping = false;
            this.baseMappingsLoading = false;
        }
    }

    toggleDataXMLJS(changingToXML: boolean) : void {
        if (true === changingToXML) {
            this.baseMappingJavaScript = this.monacoData;
            this.child?.changeLanguage("xml");
            this.monacoData = this.baseMappingXmlConfig;
        } else {
            this.baseMappingXmlConfig = this.monacoData;
            this.child?.changeLanguage("javascript");
            this.monacoData = this.baseMappingJavaScript;
        }
    }
}
