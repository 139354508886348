import { DestinationEndpoint } from "./destination-endpoint";
import { IntegrationMapping } from "./integration-mapping";

export class ClientContract {
    clientContractId?: string;
    clientInformationId: string;
    contractStartDate: Date;
    contractTermDate?: Date;
    billingStartDate?: Date;
    destinationEndpointId: number;
    endpointName?: string;
    identifierIdValue: string;
    identifierNameValue: string;
    identifierKeyValue: string;
    updatedDate: Date;
    comments?: string;
    invoiceDayOfMonth: number;
    useFirstBusinessDay?: boolean;
    clientFullName?: string;
    clientShortName?: string;
    clientDeleted?: boolean;
    losTalkerClientId?: number;
    disabled: boolean;
    recreateInvoicesForAuditPeriod: boolean;
    productionInvoiceReport: boolean;
    currentCompletedField: string;
    integrationMappingId?: string;
    integrationMappings?: IntegrationMapping[];
    contractReinstatedDate?: Date;
    salesForceId?: string;
    sfCustomerNumber?: string;

    destinationEndpoint?: DestinationEndpoint;

    constructor(init: Partial<ClientContract>) {
        this.clientContractId = init.clientContractId;
        this.clientInformationId = init.clientInformationId;
        if (init.contractStartDate != null) {
            this.contractStartDate = new Date(init.contractStartDate);
        }

        if (init.contractTermDate != null) {
            this.contractTermDate = new Date(init.contractTermDate);
        }
        else {
            this.contractTermDate = null;
        }

        if (init.contractReinstatedDate != null) {
          this.contractReinstatedDate = new Date(init.contractReinstatedDate);
        }
        else {
            this.contractReinstatedDate = null;
        }

        if (init.billingStartDate != null) {
            this.billingStartDate = new Date(init.billingStartDate);
        }
        else {
            this.billingStartDate = null;
        }
        this.destinationEndpointId = init.destinationEndpointId;
        this.endpointName = init.endpointName;
        this.identifierIdValue = init.identifierIdValue;
        this.identifierNameValue = init.identifierNameValue;
        this.identifierKeyValue = init.identifierKeyValue;
        this.productionInvoiceReport = init.productionInvoiceReport;

        if (init.updatedDate != null) {
            this.updatedDate = new Date(init.updatedDate);
        }

        this.comments = init.comments ?? "";
        this.invoiceDayOfMonth = init.invoiceDayOfMonth;
        this.useFirstBusinessDay = init.useFirstBusinessDay ?? false;
        this.clientFullName = init.clientFullName;
        this.clientShortName = init.clientShortName;
        this.losTalkerClientId = init.losTalkerClientId ?? 0;
        this.clientDeleted = init.clientDeleted;
        this.disabled = init.disabled ?? false;
        this.recreateInvoicesForAuditPeriod = init.recreateInvoicesForAuditPeriod ?? false;
        this.integrationMappingId = init.integrationMappingId;
        this.integrationMappings = init.integrationMappings;
        this.salesForceId = init.salesForceId;
        this.sfCustomerNumber = init.sfCustomerNumber;

        this.currentCompletedField = init.currentCompletedField ?? "";

        if (init.destinationEndpoint != null) {
          this.destinationEndpoint = init.destinationEndpoint;
        }
        else {
          this.destinationEndpoint = null;
        }
    }
}
