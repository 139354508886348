export class LOSToken {
  losTokenId : string;
  dataSourceTypeId: string;
  createdDate : Date;
  tokenCreatedDate : Date;
  expirationDate : Date;
  losUserName : string;
  losServerName : string;

  // on webModel but from LOSTokenMapping or other table
  clientId : number;
  integrationId : string;

  constructor(init?: Partial<LOSToken>) {
    this.losTokenId = init?.losTokenId;
    this.dataSourceTypeId = init?.dataSourceTypeId;
    this.losUserName = init?.losUserName;
    this.losServerName = init.losServerName;
    this.integrationId = init?.integrationId;
    this.clientId = init?.clientId;

    if (init.createdDate != null) {
      this.createdDate = new Date(init.createdDate)
    }

    if (init.tokenCreatedDate != null) {
      this.tokenCreatedDate = new Date(init.tokenCreatedDate)
    }

    if (init.expirationDate != null) {
      this.expirationDate = new Date(init.expirationDate)
    }
  }
}
