import { LOSToken } from "./los-token";

export class LOSTokenData extends LOSToken {
  integrationName: string;
  losTokenMappingId: number;

  constructor(init?: Partial<LOSTokenData>) {
    super(init);
    this.integrationName = init?.integrationName ?? "";
    this.losTokenMappingId = init?.losTokenMappingId ?? -1;
  }
}
