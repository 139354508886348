import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { stringify } from "querystring";
import { forkJoin, Observable, of } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { Client } from "src/app/models/client";
import { ClientInformation } from "src/app/models/client-information";
import { Integration } from "src/app/models/integration";
import { SearchTerm } from "src/app/models/search-term";
import { User } from "src/app/models/user";
import { ClientIntegrationService } from "src/app/services/client-integration.service";
import { ToastService } from "src/app/services/toast.service";
import { DataSourceType } from '../../models/data-source-type';
import { TypeConstantService } from 'src/app/services/type-constant.service';

@Component({
  selector: 'app-edit-integration',
  templateUrl: "./edit-integration.component.html",
  styleUrls: ['./edit-integration.component.scss'],
  providers: [ ClientIntegrationService ]
})

export class EditIntegrationComponent implements OnInit {

  @Input() integration: Integration;
  @Input() integrations: Integration[];
  @Output() newIntegration = new EventEmitter<Integration>();

  dataSourceTypes: DataSourceType[] = [];
  selectedDataSourceType: DataSourceType;

  integrationName: string;
  dataSourceTypeId: string;
  errorRetentionDays: number;

  myControl = new UntypedFormControl();

  finishedLoading: boolean = false;
  editingIntegration: boolean = false;
  viewingIntegration: boolean = true;

  constructor(
    private clientIntegrationService: ClientIntegrationService,
    private typeConstantService: TypeConstantService,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private router: Router,
    private user: User
  ) {
  }

  ngOnInit(): void {
    this.integrationName = this.integration.name;
    this.dataSourceTypeId = this.integration.dataSourceTypeId;
    this.errorRetentionDays = this.integration.errorRetentionDays;

    this.typeConstantService.getAllDataSourceTypes()
    .subscribe((sourceTypes) => {
      this.dataSourceTypes = sourceTypes;
      this.selectedDataSourceType = this.dataSourceTypes.find((x) => x.dataSourceTypeId == this.dataSourceTypeId);

      this.finishedLoading = true;
    });
  }

  //Utility Methods Start
  checkAccess(role: string[]): boolean {
    return this.user.checkAccess(role);
  }

  setEditing() {
    this.viewingIntegration = false;
    this.editingIntegration = true;
  }

  cancel() {
    this.editingIntegration = false;
    this.integrationName = this.integration.name;
    this.dataSourceTypeId = this.integration.dataSourceTypeId;
    this.viewingIntegration = true;
  }

  updateIntegration() {
    if(this.checkValidFields()) {
      this.integration.name = this.integrationName;
      this.integration.dataSourceTypeId = this.selectedDataSourceType.dataSourceTypeId;
      this.integration.errorRetentionDays = this.errorRetentionDays;
  
      this.clientIntegrationService.updateIntegration(this.integration).subscribe(() => {
        //I don't think this emitter is doing anything since nothing picks it up on editConfig component
        //Still, the editConfig updates the integration whether this is here or not
        //My best guess is the integration input here updates the integration on the parent component
        this.newIntegration.emit(this.integration); 
        this.toastService.toastCreate("Successfully Updated Integration Name/DataSourceType", "Success", {
          autoClose: false
        }); 
      });
    }
    else {
      this.toastService.toastCreate("Invalid Input: This integration name is already in use.", "Warning", {
        autoClose: false
      });
    }
  }

  checkValidFields(): boolean {
    if(this.integrationName == this.integration.name){
      //integration name is unchanged, so return true for existing valid name
      return true;
    }

    for (let c of this.integrations) {
      if (c.name === this.integrationName) {
        return false;
      }
    }

    return true;
  }

  dataSourceTypeChange(event: any): void {
    this.selectedDataSourceType = event.value;
  }

  public ngOnDestroy(): void {}
}
