<h1 class="title-center">Base Mappings</h1>
<br />
<div>
  <kendo-expansionpanel #surefire3BaseMappingPanel title="Surefire 3 Base Mappings" (action)="triggerExpansion('sf3baseMapping')" [expanded]="surefire3BaseMappingPanelExpanded">
    <div class="content">
      <kendo-grid [data]="surefire3BaseMappingGrid"
                  [pageSize]="20"
                  [sortable]="true"
                  [pageable]="true"
                  [selectable]="true"
                  [scrollable]="'scrollable'"
                  (selectionChange)="selectionChange($event)">
        <kendo-grid-column field="name" title="Name" [width]="200"></kendo-grid-column>
        <kendo-grid-column field="updatedDate" title="Updated Date" [width]="200">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.updatedDate | date :'MM/dd/yyyy h:mm a'}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="baseMappingId" title="Go To" [width]="50" [filterable]="false" [sortable]="false">
          <ng-template kendoGridCellTemplate let-dataItem>
            <a [routerLink]="['/editBaseMapping', dataItem.baseMappingId]" target="_blank">
              <button mat-flat-button color="primary" (click)="stopPropagation($event)">Navigate</button>
            </a>
          </ng-template>
        </kendo-grid-column>
        <ng-template kendoGridNoRecordsTemplate>
          <div class="empty_spacing_for_norecords">
            <div class="k-i-loading" *ngIf="!sf3Loaded"></div>
            <div class="no-records-message" *ngIf="sf3Loaded && surefire3BaseMappingGrid">No records found.</div>
            <div class="no-records-message" *ngIf="sf3Loaded && !surefire3BaseMappingGrid">Loading failed.</div>
          </div>
        </ng-template>
      </kendo-grid>
    </div>
  </kendo-expansionpanel>
  <kendo-expansionpanel #loanBaseMappingPanel title="Loan Base Mappings" (action)="triggerExpansion('csdLoanMapping')" [expanded]="loanBaseMappingPanelExpanded">
    <div class="content">
      <kendo-grid [data]="loanBaseMappingGrid"
                  [pageSize]="20"
                  [sortable]="true"
                  [pageable]="true"
                  [selectable]="true"
                  [scrollable]="'scrollable'"
                  (selectionChange)="selectionChange($event)">
        <kendo-grid-column field="name" title="Name" [width]="200"></kendo-grid-column>
        <kendo-grid-column field="updatedDate" title="Updated Date" [width]="200">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.updatedDate | date :'MM/dd/yyyy h:mm a'}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="baseMappingId" title="Go To" [width]="50" [filterable]="false" [sortable]="false">
          <ng-template kendoGridCellTemplate let-dataItem>
            <a [routerLink]="['/editBaseMapping', dataItem.baseMappingId]" target="_blank">
              <button mat-flat-button color="primary" (click)="stopPropagation($event)">Navigate</button>
            </a>
          </ng-template>
        </kendo-grid-column>
        <ng-template kendoGridNoRecordsTemplate>
          <div class="empty_spacing_for_norecords">
            <div class="k-i-loading" *ngIf="!csdLoanLoaded"></div>
            <div class="no-records-message" *ngIf="csdLoanLoaded && loanBaseMappingGrid">No records found.</div>
            <div class="no-records-message" *ngIf="csdLoanLoaded && !loanBaseMappingGrid">Loading failed.</div>
          </div>
        </ng-template>
      </kendo-grid>
    </div>
  </kendo-expansionpanel>
  <kendo-expansionpanel #employeeBaseMappingPanel title="Employee Base Mappings" (action)="triggerExpansion('csdEmployeeMapping')" [expanded]="employeeBaseMappingPanelExpanded">
    <div class="content">
      <kendo-grid [data]="employeeBaseMappingGrid"
                  [pageSize]="20"
                  [sortable]="true"
                  [pageable]="true"
                  [selectable]="true"
                  [scrollable]="'scrollable'"
                  (selectionChange)="selectionChange($event)">
        <kendo-grid-column field="name" title="Name" [width]="200"></kendo-grid-column>
        <kendo-grid-column field="updatedDate" title="Updated Date" [width]="200">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.updatedDate | date :'MM/dd/yyyy h:mm a'}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="baseMappingId" title="Go To" [width]="50" [filterable]="false" [sortable]="false">
          <ng-template kendoGridCellTemplate let-dataItem>
            <a [routerLink]="['/editBaseMapping', dataItem.baseMappingId]" target="_blank">
              <button mat-flat-button color="primary" (click)="stopPropagation($event)">Navigate</button>
            </a>
          </ng-template>
        </kendo-grid-column>
        <ng-template kendoGridNoRecordsTemplate>
          <div class="empty_spacing_for_norecords">
            <div class="k-i-loading" *ngIf="!csdEmployeeLoaded"></div>
            <div class="no-records-message" *ngIf="csdEmployeeLoaded && employeeBaseMappingGrid">No records found.</div>
            <div class="no-records-message" *ngIf="csdEmployeeLoaded && !employeeBaseMappingGrid">Loading failed.</div>
          </div>
        </ng-template>
      </kendo-grid>
    </div>
  </kendo-expansionpanel>
  <kendo-expansionpanel #branchBaseMappingPanel title="Branch Base Mappings" (action)="triggerExpansion('csdBranchMapping')" [expanded]="branchBaseMappingPanelExpanded">
    <div class="content">
      <kendo-grid [data]="branchBaseMappingGrid"
                  [pageSize]="20"
                  [sortable]="true"
                  [pageable]="true"
                  [selectable]="true"
                  [scrollable]="'scrollable'"
                  (selectionChange)="selectionChange($event)">
        <kendo-grid-column field="name" title="Name" [width]="200"></kendo-grid-column>
        <kendo-grid-column field="updatedDate" title="Updated Date" [width]="200">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.updatedDate | date :'MM/dd/yyyy h:mm a'}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="baseMappingId" title="Go To" [width]="50" [filterable]="false" [sortable]="false">
          <ng-template kendoGridCellTemplate let-dataItem>
            <a [routerLink]="['/editBaseMapping', dataItem.baseMappingId]" target="_blank">
              <button mat-flat-button color="primary" (click)="stopPropagation($event)">Navigate</button>
            </a>
          </ng-template>
        </kendo-grid-column>
        <ng-template kendoGridNoRecordsTemplate>
          <div class="empty_spacing_for_norecords">
            <div class="k-i-loading" *ngIf="!csdBranchLoaded"></div>
            <div class="no-records-message" *ngIf="csdBranchLoaded && branchBaseMappingGrid">No records found.</div>
            <div class="no-records-message" *ngIf="csdBranchLoaded && !branchBaseMappingGrid">Loading failed.</div>
          </div>
        </ng-template>
      </kendo-grid>
    </div>
  </kendo-expansionpanel>
  <kendo-expansionpanel #totalExpertBaseMappingPanel title="Total Expert Base Mappings" (action)="triggerExpansion('tebaseMapping')" [expanded]="totalExpertBaseMappingPanelExpanded">
    <div class="content">
      <kendo-grid [data]="totalExpertBaseMappingGrid"
                  [pageSize]="20"
                  [sortable]="true"
                  [pageable]="true"
                  [selectable]="true"
                  [scrollable]="'scrollable'"
                  (selectionChange)="selectionChange($event)">
        <kendo-grid-column field="name" title="Name" [width]="200"></kendo-grid-column>
        <kendo-grid-column field="updatedDate" title="Updated Date" [width]="200">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.updatedDate | date :'MM/dd/yyyy h:mm a'}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="baseMappingId" title="Go To" [width]="50" [filterable]="false" [sortable]="false">
          <ng-template kendoGridCellTemplate let-dataItem>
            <a [routerLink]="['/editBaseMapping', dataItem.baseMappingId]" target="_blank">
              <button mat-flat-button color="primary" (click)="stopPropagation($event)">Navigate</button>
            </a>
          </ng-template>
        </kendo-grid-column>
        <ng-template kendoGridNoRecordsTemplate>
          <div class="empty_spacing_for_norecords">
            <div class="k-i-loading" *ngIf="!totalExpertLoaded"></div>
            <div class="no-records-message" *ngIf="totalExpertLoaded && totalExpertBaseMappingGrid">No records found.</div>
            <div class="no-records-message" *ngIf="totalExpertLoaded && !totalExpertBaseMappingGrid">Loading failed.</div>
          </div>
        </ng-template>
      </kendo-grid>
    </div>
  </kendo-expansionpanel>
</div>
