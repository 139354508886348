<h1>{{ client?.name }} Configuration
  <button class="btn" (click)="openClientInfoDialog()">
    <i class="bi bi-info-circle"></i>
  </button>
  <button *ngIf="contractsLoaded" class="btn" (click)="openClientContractDialog()">
    <i class="bi bi-archive"></i>
  </button>
</h1>

<div *ngIf="hasNoIntegrations">
  <br>
  <br>
  <h3>This client has no integrations.</h3>
  <br>
  <button mat-raised-button (click)="goToAddIntegration()">Add Integration</button>
  <br>
  <br>
  <button mat-raised-button [disabled]="savingInformation" (click)="deleteClient()">Delete Client</button>
</div>

<div *ngIf="integrations != undefined && integration != undefined && !hasNoIntegrations">
  <div class="inline">

    <mat-button-toggle-group
      class="integration-toggle"
      [multiple]="false"
      [hideSingleSelectionIndicator]="true"
      (change)="selectNewIntegration($event.value)">
      <mat-button-toggle
        *ngFor="let i of integrations"
        [value]="i"
        [checked]="i == integration">
          {{ i.dataSourceTypeId}}-{{ i.name }}
      </mat-button-toggle>
    </mat-button-toggle-group>

    <br />
    <br />

  </div>

  <kendo-tabstrip #tabstrip (tabSelect)="onTabSelect($event)">
    <kendo-tabstrip-tab [title]="'Configuration Information'" [selected]="tabId == undefined || tabId == 0">
      <ng-template kendoTabContent>
        <br />
        <br />
        <div *ngIf="integrationSelected != null">
          <div class="container flexWrap" style="margin-bottom: 10px">
            <div class="row">
              <div class="col-md">
                <span class="text-large inline">{{integration.name}} - ({{serverDescription}})</span>
                <br/>
                <span class="text-medium inline"><b>Updated Date:</b> {{ integration.updatedDate | date: 'EEEE, MMMM d, y, h:mm:ss a ZZZZ'}}</span>
                <br/>
                <br />
                <span *ngIf="checkAccess(['LOSTalker.Admin'])" class="text-large inline">Change Running Server</span>
                <mat-form-field style="margin-left: 5px" *ngIf="checkAccess(['LOSTalker.Admin'])" appearance="fill">
                  <mat-label>Select an option</mat-label>
                  <mat-select [formControl]="serverSelector" (selectionChange)="changeServer($event.value)">
                    <mat-option *ngFor="let server of serverList" [value]="server">
                      <div *ngIf="server.serverId != '0';then app else none"></div>
                      <ng-template #app>
                        App {{server.serverId}} - Count: {{server.integrationCount}}
                      </ng-template>
                      <ng-template #none>
                        Remove from servers
                      </ng-template>
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <fieldset>
                  <label class="k-form-field">
                    <input type="checkbox" class="k-checkbox" (change)="updateLock()" id="intLocked"
                      [disabled]="!checkAccess(['LOSTalker.Admin'])" [checked]="integration.locked">
                    <label class="k-checkbox-label" for="intLocked" *ngIf="!integration.locked">Integration Locked</label>
                    <label class="k-checkbox-label" for="intLocked" *ngIf="integration.locked" style="color: red;">Integration Locked</label>
                  </label>
                  <label class="k-form-field" style="margin-left: 20px">
                    <input type="checkbox" class="k-checkbox" (change)="updateRestrictIP()" id="ipRest"
                      [disabled]="!checkAccess(['LOSTalker.Admin'])" [checked]="integration.ipRestricted">
                    <label class="k-checkbox-label" for="ipRest" *ngIf="!integration.ipRestricted">IP Restricted</label>
                    <label class="k-checkbox-label" for="ipRest" *ngIf="integration.ipRestricted" style="color: red;">IP Restricted</label>
                  </label>
                  <label class="k-form-field" style="margin-left: 20px">
                    <input type="checkbox" class="k-checkbox" (change)="updateSelfHosted()" id="selfHost"
                      [disabled]="!checkAccess(['LOSTalker.Admin'])" [checked]="integration.selfHosted">
                    <label class="k-checkbox-label" for="selfHost" *ngIf="!integration.selfHosted">Self Hosted</label>
                    <label class="k-checkbox-label" for="selfHost" *ngIf="integration.selfHosted" style="color: red;">Self Hosted</label>
                  </label>
                  <button kendoButton style="margin-left: 20px" *ngIf="checkAccess(['LOSTalker.Admin'])" [disabled]="savingInformation" (click)="openEditIntegrationDialog()">Edit Integration</button>
                </fieldset>
              </div>

              <div class="col-md inline id-comments-div" style="margin-bottom: -40px" *ngIf="showIdentifiersComments" id="identifiersTable">
                <p class="id-table-heading mb-0">Identifiers:</p>
                <div *ngFor="let id of identifiers" class="col mb-4">
                  <div class="card">
                    <div class="card-header bg-secondary text-white" style="display: flex; align-items: center; vertical-align: middle; justify-content: space-between;">
                      <p style="margin-bottom: 0px; float:left">Identifier Name: {{id.identifierNameValue}}</p>
                    </div>
                    <div class="card-body w-75" style="width:100%!important">
                      <div class="row">
                        <div class="col-3">Integration ID</div>
                        <div class="col">{{id.integrationId}}</div>
                      </div>
                      <div class="row">
                        <div class="col-3">Identifier ID</div>
                        <div class="col-4">{{id.identifierIdValue}}</div>
                        <div class="col-3">CanFastFetch</div>
                        <div class="col-2">{{id.canFastFetch}}</div>
                      </div>
                      <div class="row">
                        <div class="col-3">Destination</div>
                        <div class="col-4">{{id.destinationName}}</div>
                        <div class="col-3">CanDataSwap</div>
                        <div class="col-2">{{id.canDataSwap}}</div>
                      </div>
                      <div class="row">
                        <div class="col">Comments: {{id.comments}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <kendo-switch style="margin-bottom: 5px;" [(ngModel)]="xmlSelected" [onLabel]="'XML'" [offLabel]="'JS'" (valueChange)="saveData($event)">
          </kendo-switch>

          <div class="inline" *ngIf="!xmlSelected && integration.javaScript.length > 0 && destinationEndpoints.length > 0">
            <mat-button-toggle-group (change)="switchJavaScript($event.value)" [hideSingleSelectionIndicator]="true">
              <mat-button-toggle *ngFor="let j of integration.javaScript" [value]="j" [checked]="j == javaScript">
                {{ getEndpointName(j.destinationEndpointId) }}
              </mat-button-toggle>
            </mat-button-toggle-group>
            <mat-button-toggle-group>
              <mat-button-toggle *ngIf="checkAccess(['LOSTalker.Admin']) && this.integration.javaScript.length < 3"
                (click)="addNewJavaScript()">+</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <app-monaco-editor *ngIf="xmlSelected || integration.javaScript.length > 0" #monaco
            (textChange)="monacoData = $event" [editorData]="monacoData" [editAccess]="checkAccess(['LOSTalker.Admin'])"
            [lang]="lang">
          </app-monaco-editor>

        </div>
        <div class="integration-commands" *ngIf="xmlSelected">
          <br />
          <button class="config-comm-btn" kendoButton (click)="goBack()">Back To Clients</button>
          <button kendoButton class="config-comm-btn" [primary]="true" *ngIf="checkAccess(['LOSTalker.Admin'])"
            [disabled]="savingInformation" (click)="updateIntegration()">Save Changes</button>
          <button kendoButton class="config-comm-btn" *ngIf="checkAccess(['LOSTalker.Admin'])"
            [disabled]="savingInformation" (click)="deleteIntegration()">Delete Integration</button>
          <br/>
          <br/>
          <button kendoButton class="config-comm-btn" [disabled]="savingInformation" *ngIf="!isGenericIntegration() && checkAccess(['LOSTalker.Admin'])"
            (click)="createGenericIntegration()">Convert to Generic</button>
          <button kendoButton class="config-comm-btn" [disabled]="savingInformation" *ngIf="isGenericIntegration() && !isAPIIntegration() && checkAccess(['LOSTalker.Admin'])"
            (click)="createAPIIntegration()">Convert to API</button>
          <mat-checkbox [(ngModel)]="convertCreateNew" *ngIf="!isGenericIntegration() && checkAccess(['LOSTalker.Admin'])" class="checkbox-padding">
            Create new integration
          </mat-checkbox>
        </div>
        <div *ngIf="xmlSelected == false">
          <div *ngIf="integration.javaScript.length > 0;then editJs else addJs"></div>
          <ng-template #editJs>
            <br />
            <div class="integration-commands">
              <button class="config-comm-btn" kendoButton (click)="goBack()">Back To Clients</button>
              <button class="config-comm-btn" kendoButton [primary]="true" *ngIf="checkAccess(['LOSTalker.Admin'])"
                [disabled]="savingInformation" (click)="updateIntegration()">Save Changes</button>
              <button class="config-comm-btn" kendoButton *ngIf="checkAccess(['LOSTalker.Admin'])" [disabled]="savingInformation"
                (click)="deleteIntegration()">Delete Integration</button>
              <button class="config-comm-btn" kendoButton *ngIf="checkAccess(['LOSTalker.Admin'])" [disabled]="savingInformation"
                (click)="deleteJavaScript()">Delete JavaScript</button>
            </div>
          </ng-template>
          <ng-template #addJs>
            <div class="inline">
              <div>There are no existing Custom JavaScript for {{integration.name}}. Would you like to create one
                for CSDataSwap or Surefire3?
              </div>
              <button kendoButton *ngIf="checkAccess(['LOSTalker.Admin'])" (click)="addNewJavaScript()">+</button>
            </div>
            <br />
          </ng-template>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab *ngIf="checkAccess(['LOSTalker.Integration'])" [title]="'Integration Run Times'">
      <ng-template kendoTabContent>
        <div>
          <app-integration-runtime [integration]="integration" (runTimesPopulated)="setRunTimesPopulated($event)"
            #integrationRunTime></app-integration-runtime>
          <br />
          <br />
        </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab *ngIf="checkAccess(['LOSTalker.Integration'])" [title]="'Integration Push Backs'">
      <ng-template kendoTabContent>
        <div>
          <app-integration-pushback [integration]="integration"
            #integrationPushBack></app-integration-pushback>
          <br />
          <br />
        </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab *ngIf="checkAccess(['LOSTalker.Integration'])" [title]="'Integration Errors'">
      <ng-template kendoTabContent>
        <div>
            <app-error-messages-v2 #errorListV2 id="errorListV2"
                [integration]="integration"
                [currentClient]="client"
                [isInTab]="true">
            </app-error-messages-v2>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab *ngIf="checkAccess(['LOSTalker.DevOps'])" [title]="'Revision History'">
      <ng-template kendoTabContent>
        <app-integration-revision [integration]="integration" [client]="client" [destinationEndpoints]="destinationEndpoints" #revisionHistory
          (dataChanged)="updateFromHistory($event)">
        </app-integration-revision>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab *ngIf="checkAccess(['LOSTalker.Integration'])" [title]="'Identifiers'">
      <ng-template kendoTabContent>
        <div>
          <app-identifier-view #identifierTab [client]="client" [integration]="integration" [baseData]="identifiers"
            (identifier)="updateIntegrationIdentifier($event)">
          </app-identifier-view>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab [title]="'Process Data'">
      <ng-template kendoTabContent>
        <div class="card-group" *ngIf="showProcessDataCards">
          <div class="card" (click)="showFastFetchView()">
            <div class="card-header bg-secondary text-white"
              style="display: flex; align-items: center; vertical-align: middle; justify-content: space-between;">Fast
              Fetch Loans</div>
            <div class="card-body w-75">
              <p class="card-text">Send loans to queue for processing</p>
            </div>
          </div>
          <div class="card" (click)="showDataSwapView()">
            <div class="card-header bg-secondary text-white"
              style="display: flex; align-items: center; vertical-align: middle; justify-content: space-between;">Get
              Processed Info</div>
            <div class="card-body w-75">
              <p class="card-text">Get loan and file xmls already processed</p>
            </div>
          </div>
        </div>

        <div *ngIf="!showProcessDataCards">
          <ng-container *ngIf="showDataSwap">
            <app-lba-dataswap [client]="client" [integration]="integration" [isInTab]="true" [integrationIdentifiers]="identifiers" #lbaDataSwap>
            </app-lba-dataswap>
          </ng-container>
          <ng-container *ngIf="showFastFetch">
            <app-fastfetch [integrationInfo]="integrationInfo" [isInTab]="true" #fastFetch>
            </app-fastfetch>
          </ng-container>
          <button kendoButton [primary]="true" (click)="showProcessDataCardView()">Go Back</button>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab *ngIf="checkAccess(['LOSTalker.Integration'])" [title]="'Failed Loans'">
      <ng-template kendoTabContent>
        <div class="lts-min-tab-content">
          <app-failed-loans-view [integration]="integration" #failedLoanView></app-failed-loans-view>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab *ngIf="checkAccess(['LOSTalker.Admin']) && integrationHasQueries" [title]="'Custom SQL Queries'">
      <ng-template kendoTabContent>
        <div>
          <app-sql-queries #queriesTab [integration]="integration">
          </app-sql-queries>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>

  </kendo-tabstrip>
</div>

<div style="margin: auto; margin-top:0%; text-align: center;" *ngIf="integrations == undefined && !hasNoIntegrations">
  <div class="k-i-loading"></div>
</div>

<ng-template #addJsDialog>
  <h2 matDialogTitle>Add JavaScript to Integration</h2>
  <mat-dialog-content>
    <mat-form-field appearance="fill">
      <mat-select [(value)]="destNameSelected">
        <mat-option *ngFor="let destName of availableDestinations" [value]="destName">
          {{destName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button matDialogClose [disabled]="!destNameSelected"
      (click)="selectNewJavaScript(destNameSelected)">Create</button>
    <button mat-button matDialogClose>Close</button>
  </mat-dialog-actions>
</ng-template>

<ng-template #clientInfoDialog>
  <app-edit-client [client]="client" [integrations]="integrations">
  </app-edit-client>
</ng-template>

<ng-template #clientContractDialog >

  <app-client-contracts [client]="client" [integrations]="integrations" [integrationIdentifiers]="clientIdentifiers" [clientContracts]="clientContracts" [selectedIntegrationId]="integration.integrationId"
    (outputContract)="updateClientContract($event)">
  </app-client-contracts>

</ng-template>

<ng-template #editIntegrationDialog>
  <app-edit-integration [integration]="integration" [integrations]="integrations" #editIntegrationName>
  </app-edit-integration>
</ng-template>


<ng-template #clientContractDeleteConfirmationDialog>
  <h2 matDialogTitle>Add Contract Term Date to Client Contract</h2>
  <mat-dialog-content>
    <p>Integration deleted is the last integration mapped for Client Contract. Please set Date for ContractTermDate for Client Contract.</p>
    <div class="example-wrapper">
      <kendo-datetimepicker
          style="width: 210px"
          placeholder="Enter a date and time"
          format="MM/dd/yyyy hh:mm:ss a"
          [(value)]="clientContractTermDate">
      </kendo-datetimepicker>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button matDialogClose (click)="updateClientContractTermDate()" *ngIf="clientContractTermDate != null">Update</button>
    <button mat-button matDialogClose>Close</button>
  </mat-dialog-actions>
</ng-template>
