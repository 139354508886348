import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Observable, throwError, of, BehaviorSubject, merge } from 'rxjs';
import { retry, catchError, filter, map, tap } from 'rxjs/operators';

import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { protectedResources } from '../auth-config';
import { ToastService } from './toast.service';
import { environment } from 'src/environments/environment';

const DEFAULT_TITLE = 'LOS Talker';

@Injectable()
export class LOSTalkerTitleService {
    title = new BehaviorSubject<string>(DEFAULT_TITLE);

    private titleRoute: Observable<string | undefined> =
      this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.getPageTitle(this.activatedRoute.firstChild))
      );

    private titleState = 
        merge(this.title, this.titleRoute)
            .pipe(
                filter((title) => title !== undefined),
                tap((title) => {
                    this.titleService.setTitle(`${title}`);
                })
            );

    constructor(
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title
    ) {
        this.titleState.subscribe();
    }

    private getPageTitle (activatedRoute: ActivatedRoute | null): string | undefined {
        while (activatedRoute) {
          if (activatedRoute.firstChild) {
            activatedRoute = activatedRoute.firstChild;
          } else if (
            activatedRoute.snapshot.data &&
            activatedRoute.snapshot.data['title']
          ) {
            return activatedRoute.snapshot.data['title'] as string;
          } else {
            return undefined;
          }
        }
        return undefined;
    }

    public setUpTitle(x: string) {

        if (environment.environmentName == "Test" 
            || environment.environmentName == "Staging" ) {
            x = environment.environmentName + " - " 
                + x + " - " + DEFAULT_TITLE;
        }
        else {
          if (!x.startsWith(DEFAULT_TITLE)) {
            x = DEFAULT_TITLE + " - " + x;
          }
        }
        
        this.title.next(x);
    }
}