import { ClientContract } from "./client-contract";

export class InvoicePeriod {
    invoicePeriodId: string;
    clientContractId: string;
    dateStart: Date;
    dateEnd: Date;
    currentInvoice: boolean;
    previousBilledInvoice: boolean;
    lastBilledInvoice: boolean;
    invoiceDate: Date;
    jsonFieldInfoCollection: Map<string,string>;

    contract?: ClientContract = null;

    constructor(init?: Partial<InvoicePeriod>, c?: ClientContract){
      this.invoicePeriodId = init.invoicePeriodId;
      this.clientContractId = init.clientContractId;
      this.currentInvoice = init?.currentInvoice ?? false;
      this.previousBilledInvoice = init?.previousBilledInvoice ?? false;
      this.lastBilledInvoice = init?.lastBilledInvoice ?? false;
      this.jsonFieldInfoCollection = init.jsonFieldInfoCollection;

      if (init?.dateStart != null) {
        this.dateStart = new Date(init.dateStart)
      }

      if (init?.dateEnd != null) {
        this.dateEnd = new Date(init.dateEnd)
      }

      if (init?.invoiceDate != null) {
        this.invoiceDate = new Date(init.invoiceDate)
      }

      if (c != null) {
        this.contract = c;
      }
      else {
        this.contract = null;
      }
    }
  }
  