import { BaseEndpointMappingType } from "./base-endpoint-mapping-type";

export class BaseEndpointMapping {
  baseMappingId?: string;
  genericEndpointId?: string;
  name: string;
  xmlConfig?: string;
  updatedDate?: Date;
  importTypeId: number;
  destinationEndpointId: number;
  javaScript?: string;
  mappingType: string;
  typeDescription?: string;
  endpointName?: string;

  baseEndpointMappingType?: BaseEndpointMappingType;
  baseEndpointMappingId?: string;

  routerUrl?: string = "";

  constructor(init?: Partial<BaseEndpointMapping>) {
    this.baseEndpointMappingType = init?.baseEndpointMappingType;
    this.baseMappingId = init?.baseMappingId;
    this.genericEndpointId = init?.genericEndpointId;
    this.name = init.name;
    this.xmlConfig = init?.xmlConfig;
    this.importTypeId = init.importTypeId;
    this.destinationEndpointId = init.destinationEndpointId;
    this.javaScript = init?.javaScript;
    this.mappingType = init.mappingType;
    this.typeDescription = init?.typeDescription;
    this.endpointName = init?.endpointName;

    if (init?.baseMappingId != null && init?.baseMappingId != undefined && init?.baseMappingId != "") {
      this.baseEndpointMappingId = init.baseMappingId;
      this.baseEndpointMappingType = BaseEndpointMappingType.BaseMapping;
      this.routerUrl = "/editBaseMapping/" + init.baseMappingId;
    }
    else if (init?.genericEndpointId != null && init?.genericEndpointId != undefined && init?.genericEndpointId != "") {
      this.baseEndpointMappingId = init.genericEndpointId;
      this.baseEndpointMappingType = BaseEndpointMappingType.GenericEndpoint;
      this.routerUrl = "/editGenericEndpoint/" + init.genericEndpointId;
    }

    if (init?.updatedDate != null) {
      this.updatedDate = new Date(init.updatedDate)
    }
    else {
        this.updatedDate = null;
    }

    if (this.baseEndpointMappingType == null) {
      if (this.mappingType == "BaseMapping") {
        this.baseEndpointMappingType = BaseEndpointMappingType.BaseMapping;
        this.routerUrl = "/editBaseMapping/" + init.baseMappingId;
      }
      else if (this.mappingType == "GenericEndpoint") {
        this.baseEndpointMappingType = BaseEndpointMappingType.GenericEndpoint;
        this.routerUrl = "/editGenericEndpoint/" + init.genericEndpointId;
      }
    }
  }
}
