import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { IntegrationErrorMessage } from '../models/integration-error-message';
import { IntegrationErrorMessagesResponse } from '../models/integration-error-messages-response';
import { ErrorStatus } from '../models/error-status';
import { protectedResources } from '../auth-config';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IntegrationMetrics } from '../models/integration-metrics';
import { IntegrationLog } from '../models/integration-log';
import { CommonErrorFilter } from '../models/common-error-filter';
import { CsvSummary } from '../models/csv-summary';
import { LoanMetrics } from '../models/loan.metrics';
import { environment } from 'src/environments/environment';
import { ToastService } from './toast.service';
import { ErrorCategory } from '../models/error-category';
import { ServiceError } from '../models/service-error';

@Injectable()
export class ErrorLoggingService {
  private baseUrl: string = protectedResources.LOSTalkerAPI.endpoint;
  private actionUrl: string = "";
  private Url: string = "";
  private headers = new HttpHeaders();
  
  private errorHandler(error: HttpErrorResponse, ts: ToastService): Observable<any> {
    ts.toastCreate("An HTTP transport error occured: " + error.message, "Warning", {
      keepAfterRouteChange: true
    });
    
    if (environment.enableDebuggingTools) {
      console.error("An HTTP transport error occured: " + error.message);
    }

    return throwError(error);
  }

  constructor(private http: HttpClient, private toastService: ToastService) { }

  //--------------------------- Integration Error Functions ------------------------------------------//

  getAllErrorsById(integrationId: string): Observable<IntegrationErrorMessagesResponse> {
    this.actionUrl = "IntegrationErrorMessages/GetAllErrors/";
    this.Url = this.baseUrl.concat(this.actionUrl, integrationId);
    return this.http
      .get<IntegrationErrorMessagesResponse>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getAllErrors(): Observable<IntegrationErrorMessagesResponse> {
    this.actionUrl = "IntegrationErrorMessages/GetAllErrors/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    return this.http
      .get<IntegrationErrorMessagesResponse>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // get all errors
  getAllStoppedErrors(): Observable<IntegrationErrorMessage[]> {
    this.actionUrl = "IntegrationErrorMessages/GetAllStoppedErrors/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    return this.http
      .get<IntegrationErrorMessage[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // get all errors by integration
  getAllStoppedErrorsById(integrationId: string): Observable<IntegrationErrorMessage[]> {
    this.actionUrl = "IntegrationErrorMessages/GetAllStoppedErrors/";
    this.Url = this.baseUrl.concat(this.actionUrl, integrationId);
    return this.http
      .get<IntegrationErrorMessage[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // get all errors
  getAllMaintenanceErrors(): Observable<IntegrationErrorMessage[]> {
    this.actionUrl = "IntegrationErrorMessages/GetAllMaintenanceErrors/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    return this.http
      .get<IntegrationErrorMessage[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // get all errors by integration
  getAllMaintenanceErrorsById(integrationId: string): Observable<IntegrationErrorMessage[]> {
    this.actionUrl = "IntegrationErrorMessages/GetAllMaintenanceErrors/";
    this.Url = this.baseUrl.concat(this.actionUrl, integrationId);
    return this.http
      .get<IntegrationErrorMessage[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // get all errors
  getAllOtherErrors(): Observable<IntegrationErrorMessage[]> {
    this.actionUrl = "IntegrationErrorMessages/GetAllOtherErrors/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    return this.http
      .get<IntegrationErrorMessage[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // get all errors by integration
  getAllOtherErrorsById(integrationId: string): Observable<IntegrationErrorMessage[]> {
    this.actionUrl = "IntegrationErrorMessages/GetAllOtherErrors/";
    this.Url = this.baseUrl.concat(this.actionUrl, integrationId);
    return this.http
      .get<IntegrationErrorMessage[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // GET - Integration Error Messages
  getUnresolvedErrors(): Observable<IntegrationErrorMessage[]> {
    this.actionUrl = "IntegrationErrorMessages/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<IntegrationErrorMessage[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // PUT Update Error
  updateError(error: IntegrationErrorMessage): Observable<any> {
    this.actionUrl = "IntegrationErrorMessage/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .put(this.Url, error, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // POST Support Send Help Desk
  sendHelpDesk(error: IntegrationErrorMessage) {
    this.actionUrl = "Support/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .post(this.Url, JSON.stringify(error), { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  //--------------------------- Integration Metrics ------------------------------------------//

  // GET Active Integration Metrics
  getActiveMetric(integrationId: string, integrationTypeId: string): Observable<IntegrationMetrics> {
    //console.log("getActiveMetric | integrationId: " + integrationId + " | integrationTypeId: " + integrationTypeId);
    this.actionUrl = "IntegrationMetrics/";
    this.Url = this.baseUrl.concat(
      this.actionUrl,
      integrationId,
      "/",
      integrationTypeId
    );
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<IntegrationMetrics>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // GET Integration Metrics Lifetime
  getLifetimeMetrics(integrationId: string): Observable<IntegrationMetrics[]> {
    this.actionUrl = "IntegrationMetrics/Lifetime/";
    this.Url = this.baseUrl.concat(this.actionUrl, integrationId);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<IntegrationMetrics[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // GET Integration Metrics All
  getAllIntegrationMetrics(integrationId: string): Observable<IntegrationMetrics[]> {
    this.actionUrl = "IntegrationMetrics/";
    this.Url = this.baseUrl.concat(this.actionUrl, integrationId);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<IntegrationMetrics[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  //--------------------------- Integration Logs ------------------------------------------//

  // GET Integration Logs
  getIntegrationLogs(integrationId: string): Observable<IntegrationLog[]> {
    this.actionUrl = "IntegrationLog/";
    this.Url = this.baseUrl.concat(this.actionUrl, integrationId);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<IntegrationLog[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  //--------------------------- Error Filters ------------------------------------------//

  // GET Error Filter
  getAllCommonErrorFilters(integrationId: string): Observable<CommonErrorFilter[]> {
    this.actionUrl = "CommonErrorFilter/";
    this.Url = this.baseUrl.concat(this.actionUrl, integrationId);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<CommonErrorFilter[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // POST Save Error Filter - ?? is update or insert ??
  saveErrorFilter(ef: CommonErrorFilter): Observable<string> {
    this.actionUrl = "CommonErrorFilter/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .post<string>(this.Url, JSON.stringify(ef), { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  // DELETE Error Filter
  deleteErrorFilter(id: string): Observable<string> {
    this.actionUrl = "CommonErrorFilter/";
    this.Url = this.baseUrl.concat(this.actionUrl, id);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .delete<string>(this.Url, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  //--------------------------- Error Status ------------------------------------------//

  // GET - Get All Error Statuses
  getAllErrorStatuses(): Observable<ErrorStatus[]> {
    this.actionUrl = "IntegrationErrorMessages/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<ErrorStatus[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  //--------------------------- Loan Metrics ------------------------------------------//

  // GET Integration Metrics Lifetime
  getAllLoanMetrics(integrationId: string): Observable<LoanMetrics[]> {
    this.actionUrl = "LoanMetrics/";
    this.Url = this.baseUrl.concat(this.actionUrl, integrationId);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .get<LoanMetrics[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  //--------------------------- CSV Summary ------------------------------------------//

  // GET CSV Blob File
  //getCSV(details: CsvSummary): Observable<any> {
  //  this.actionUrl = "CSVExport/";
  //  this.Url = this.baseUrl.concat(this.actionUrl);
  //  this.headers.append("Content-Type", "application/json");
  //  this.headers.append("Accept", "application/octet-stream");

  //  return this.http
  //    .post<any>(this.Url, JSON.stringify(details), { headers: this.headers })
  //    .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  //}

  //--------------------------- Error Categories ------------------------------------------// 
 
  getAllErrorCategories(): Observable<ErrorCategory[]> { 
    this.actionUrl = "ErrorCategories/"; 
    this.Url = this.baseUrl.concat(this.actionUrl); 
    return this.http 
      .get<ErrorCategory[]>(this.Url) 
      .pipe(catchError(err => this.errorHandler(err, this.toastService))); 
  } 
 
  getErrorCategoryById(errorCategoryId: string): Observable<ErrorCategory> { 
    this.actionUrl = "ErrorCategory/"; 
    this.Url = this.baseUrl.concat(this.actionUrl, errorCategoryId); 
    return this.http 
      .get<ErrorCategory>(this.Url) 
      .pipe(catchError(err => this.errorHandler(err, this.toastService))); 
  } 
 
  updateErrorCategory(errorCategory: ErrorCategory): Observable<any> { 
    this.actionUrl = "ErrorCategory/"; 
    this.Url = this.baseUrl.concat(this.actionUrl); 
    this.headers.append("Content-Type", "application/json"); 
 
    return this.http 
      .put(this.Url, errorCategory, { headers: this.headers }) 
      .pipe(catchError(err => this.errorHandler(err, this.toastService))); 
  } 
 
  insertErrorCategory(errorCategory: ErrorCategory): Observable<ErrorCategory> { 
    this.actionUrl = "ErrorCategory/"; 
    this.Url = this.baseUrl.concat(this.actionUrl); 
    this.headers.append("Content-Type", "application/json"); 
 
    return this.http 
      .post<ErrorCategory>(this.Url, errorCategory, { headers: this.headers }) 
      .pipe(catchError(err => this.errorHandler(err, this.toastService))); 
  } 
  //--------------------------- Service Errors ------------------------------------------//
  getAllServiceErrorsByServerId(serverId: string): Observable<ServiceError[]> {
    this.actionUrl = "ServiceErrors/";
    this.Url = this.baseUrl.concat(this.actionUrl, serverId);
    return this.http
      .get<ServiceError[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getAllServiceErrors(): Observable<ServiceError[]> {
    this.actionUrl = "ServiceErrors/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    return this.http
      .get<ServiceError[]>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  getServiceErrorById(serviceErrorId: string): Observable<ServiceError> {
    this.actionUrl = "ServiceError/";
    this.Url = this.baseUrl.concat(this.actionUrl, serviceErrorId);
    return this.http
      .get<ServiceError>(this.Url)
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  updateServiceError(error: ServiceError): Observable<any> {
    this.actionUrl = "ServiceError/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .put(this.Url, error, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

  insertServiceError(error: ServiceError): Observable<ServiceError> {
    this.actionUrl = "ServiceError/";
    this.Url = this.baseUrl.concat(this.actionUrl);
    this.headers.append("Content-Type", "application/json");

    return this.http
      .post<ServiceError>(this.Url, error, { headers: this.headers })
      .pipe(catchError(err => this.errorHandler(err, this.toastService)));
  }

}
