import { Component, OnInit, OnDestroy, Input, Output, ViewChild, AfterViewInit, EventEmitter, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { State, DataSourceRequestState, orderBy, CompositeFilterDescriptor, filterBy, SortDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent, GridComponent, SelectionEvent, RowArgs, CellClickEvent } from '@progress/kendo-angular-grid';
import { Observable, Subscription, Subject } from 'rxjs';
import { take, takeUntil } from "rxjs/operators";
import { Integration } from '../../models/integration';
import { SearchTerm } from '../../models/search-term';
import { IntegrationError } from '../../models/integration-error';
import { BulkErrorUpdateScope } from '../../models/bulk-error-update-scope';
import { IntegrationInformation } from '../../models/integration-information';
import { DestinationEndpoint } from '../../models/destination-endpoint';
import { Client } from '../../models/client';
import { ClientIntegrationService } from "../../services/client-integration.service";
import { IntegrationErrorsService } from 'src/app/services/integration-errors.service';
import { ToastService } from '../../services/toast.service';
import { TypeConstantService } from 'src/app/services/type-constant.service';
import { User } from '../../models/user';
import * as internal from 'stream';
import { IntegrationType } from 'src/app/models/integration-type';
import { UntypedFormControl } from "@angular/forms";
import { ErrorCategory } from 'src/app/models/error-category';
import { CurrentStatus } from 'src/app/models/currentStatus';
import { ImportType } from 'src/app/models/import-type';
import { TooltipDirective } from "@progress/kendo-angular-tooltip";
import { environment } from '../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { ErrorSearchFilter } from 'src/app/models/error-search-filter';
import { ReportErrorSearchFilter } from 'src/app/models/report-error-search-filter';

const flatten = (filter) => {
  const filters = filter.filters;
  if (filters) {
    return filters.reduce(
      (acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]),
      []
    );
  }
  return [];
};

@Component({
  selector: 'app-error-list-grid-v2',
  templateUrl: './error-list-grid-v2.component.html',
  styleUrls: ['./error-list-grid-v2.component.scss'],
  providers: [ClientIntegrationService, TypeConstantService],
})
export class ErrorListGridV2Component implements OnInit, OnDestroy {
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  componentDestroyed$: Subject<boolean> = new Subject();
  @ViewChild("errorGrid") public errorGrid: GridComponent;
  hasData = false;

  @Input() data: IntegrationError[] = [];
  @Input() clients: Client[];
  @Input() categories: ErrorCategory[];
  @Input() statuses: CurrentStatus[];
  @Input() destinations: DestinationEndpoint[];
  @Input() importTypes: ImportType[];
  @Input() loading: boolean; // show loading icon from parent
  @Input() sorting: SortDescriptor[];
  @Input() integrationsMap: Map<string, Integration>;// ask parent for updated data
  @ViewChild('updateErrorStatusDialog', { static: true }) updateErrorStatusDialog: TemplateRef<any>;
  @ViewChild('bulkUpdateDialog', { static: true }) bulkUpdateDialog: TemplateRef<any>;

  selectedStatus: CurrentStatus;
  dayCount: number = 20;
  addJiraURL: boolean = false;
  selectedError: IntegrationError;
  public selectedErrorIds: string[] = []; // selected integrations in grid
  selectedRow: RowArgs;
  selectedRows: RowArgs[] = [];
  statusUpdating: boolean = false;

  // Grid Row Selected Boolean Check
  public isRowSelected = (e: RowArgs) =>
    this.selectedErrorIds.indexOf(e.dataItem.integrationErrorId) >= 0;


  public expandedDetailKeys: any[] = [];
  public expandDetailsBy = (dataItem: any): any => {
    return dataItem.integrationErrorId;
  };

  gridView: GridDataResult;
  gridViewIsLoading = true;
  currentFiltersFromGrid: CompositeFilterDescriptor;
  type: 'numeric' | 'input' = 'input';
  public pageSizes: number[] = [10, 20, 30, 50, 100];
  public state: DataSourceRequestState = {
    skip: 0,
    take: 10,
    sort: []
  };

  filteredDestinations: DestinationEndpoint[];
  public selectedDestination: DestinationEndpoint;
  destinationsFiltered: Observable<DestinationEndpoint[]>;
  pageLoading = true;
  activeUser: User;

  bulkUpdateDetails: BulkErrorUpdateScope = {
    integrationErrorIdList: []
  };

  bulkUpdateSelectedStatus: CurrentStatus;

  filteredData: IntegrationError[];

  // error status vars
  public statusSearchTerm: string = "";
  hasStatusFilter = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private errorService: IntegrationErrorsService,
    private toastService: ToastService,
    protected typeService: TypeConstantService
  ) {
    this.filteredData = [];
  }

  public ngOnInit(): void {
    this.state.sort = this.sorting;
    this.refresh();
    this.categories = this.categories.sort((a, b) => a.errorCategoryId - b.errorCategoryId)
  }

  public ngAfterViewInit(): void {
    this.fitColumns();
  }

  public refreshFromParent(): void {
    this.refresh();
    this.updateFilteredData(this.currentFiltersFromGrid);
  }

  private fitColumns(): void {
    var cols = this.errorGrid.columnList.filter(x => x.title != "Log Message");
    this.errorGrid.autoFitColumns(cols);
  }

  public refresh(): void {
    this.gridViewIsLoading = true;
    this.selectedErrorIds = [];
    if (this.data != null) {
      if (this.data.length > 0) {
        this.data.forEach(x => x.receivedDate = new Date(x.receivedDate));
        this.filteredData = this.data;
        this.state.skip = 0;
        this.mapData();
      }
      else {
        this.gridView = { data: [], total: 0 };
        this.gridViewIsLoading = false;
      }
    }
  }

  public mapData(): void {
    if (this.data.length > 0) {
      this.data.forEach(x => {
        x.receivedDate = new Date(x.receivedDate);
        x.initialErrorDate = new Date(x.initialErrorDate);
        if (x.resolutionDate != undefined)
          x.resolutionDate = new Date(x.resolutionDate);
      });
      // if integrations finished before errors
      if (this.integrationsMap != null && this.integrationsMap.size > 0) {
        this.data.forEach(x => {
          let i = this.integrationsMap.get(x.integrationId);

          if (i != null) {
            x.dataSourceTypeId = i.dataSourceTypeId;
            x.integrationServerId = i.serverId;
            x.integrationName = i.name;
            x.locked = i.locked;
            x.clientId = i.clientId;
            if (this.clients != null && this.clients.length > 0) {
              x.clientName = this.clients.find(c => c.clientId == i.clientId)?.name;
            }
          }
          else{
            x.integrationName = 'Not Populated, likely Deleted';
          }

          if (this.importTypes != null && this.importTypes.length > 0) {
            x.typeDescription = this.importTypes.find(c => c.importTypeId == x.importTypeId)?.typeDescription;
          }

          if (this.destinations != null && this.destinations.length > 0) {
            x.endpointName = this.destinations.find(c => c.destinationEndpointId == x.destinationEndpointId)?.endpointName;
          }

          if (this.categories != null && this.categories.length > 0) {
            let currentCategory = this.categories.find(c => c.errorCategoryId == x.errorCategoryId);
            x.categoryName = currentCategory?.categoryName;
            x.categoryPriority = currentCategory?.categoryPriority;
          }

          if (this.statuses != null && this.statuses.length > 0) {
            x.statusName = this.statuses.find(s => s.currentStatusId == x.currentStatusId)?.statusName;
          }
        });
      }
    }
    this.updateGrid();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.updateGrid();
  }

  pageChange(state: DataStateChangeEvent): void {
    this.state.skip = state.skip;
    this.state.take = state.take;
    this.updateGrid();
  }

  sortChange(sort: SortDescriptor[]) {
    this.state.skip = 0;
    this.state.sort = sort;
    this.updateGrid();
  }

  public updateFilteredData(filterFromGrid: CompositeFilterDescriptor): void {
    if (this.data != null) {
      this.filteredData = this.data;
      this.state.skip = 0;
      this.currentFiltersFromGrid = filterFromGrid;
      this.selectedErrorIds = [];
      this.updateGrid();
    }
    else {
      this.gridViewIsLoading = false;
    }
  }

  statusFilterChange(checked: string): void {
    this.state.skip = 0;
    this.hasStatusFilter = true;
    this.statusSearchTerm = checked;
    this.updateGrid();
  }

  clearStatusFilter(): void {
    this.statusSearchTerm = null;
    this.hasStatusFilter = false;
    this.updateGrid();
  }

  clearFilters() {
    this.state.skip = 0;
    this.currentFiltersFromGrid = null;
    this.statusSearchTerm = null;
    this.hasStatusFilter = false;
    this.updateGrid();
  }

  updateGrid() {

    this.filteredData = this.data;

    if (this.hasStatusFilter) {
      this.filteredData = filterBy(this.filteredData, {
        logic: 'and',
        filters: [
          { field: "statusName", operator: "eq", value: this.statusSearchTerm, ignoreCase: true }
        ]
      });
    }

    if (this.currentFiltersFromGrid != null) {
      this.filteredData = filterBy(this.filteredData, this.currentFiltersFromGrid);
    }

    if (this.filteredData.length == 0) {
      this.toastService.toastCreate("Filters produced no results. The filters have been reset", "Warning", {
        keepAfterRouteChange: false, autoClose: true
      });

      this.clearFilters();
    }

    let orderedData = orderBy(this.filteredData.length > 0 ? this.filteredData : this.data, this.state.sort);
    this.gridView = {
      data: orderedData.slice(this.state.skip, this.state.skip + this.state.take),
      total: orderedData.length
    };
    this.gridViewIsLoading = false;
  }

  triggerUpdateStatusMessage(error: IntegrationError, errorStatusName: string) {
    this.selectedError = error;
    this.selectedStatus = this.statuses.find(s => s.statusName == errorStatusName)
    this.statusUpdating = true;
    if (errorStatusName == "Resolved") {
      this.dialog.open(this.updateErrorStatusDialog);
    }
    else {
      this.updateError();
    }
  }

  updateError() {
    this.selectedError.resolutionDate = new Date();
    this.selectedError.currentStatusId = this.selectedStatus.currentStatusId;
    if (this.selectedStatus.statusName == "Resolved") {
      this.selectedError.criticalError = false;
      this.selectedError.actionNeeded = false;
      if (this.selectedError.resolutionComment == undefined || this.selectedError.resolutionComment == "") {
        this.toastService.toastCreate("Resolution Comment not populated, error not updated", "Warning", {
          autoClose: false
        });
        return;
      }
    }
    this.errorService.updateIntegrationError(this.selectedError)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (value) => {
          this.data.find(d => d.integrationErrorId == this.selectedError.integrationErrorId).currentStatusId = this.selectedStatus.currentStatusId;
          this.data.find(d => d.integrationErrorId == this.selectedError.integrationErrorId).statusName = this.selectedStatus.statusName;
          this.updateGrid();
          if(this.statusUpdating){
            this.statusUpdating = false;
          }
        }, error => {
          this.toastService.toastCreate("Failed To Update Integration Error", "Warning", {
            autoClose: false
          });
          if (this.statusUpdating) {
            this.statusUpdating = false;
          }
        });
  }

  updateJiraURL(item: IntegrationError) {
    this.errorService.updateJiraURLForError(item)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (value) => {
          this.addJiraURL = false;
          this.toastService.toastCreate("JIRA Url added to integrationError", "Success", {
            autoClose: true,
            keepAfterRouteChange: true
          });
        }, error => {
          this.toastService.toastCreate("Failed To Update JIRA URL for Error", "Warning", {
            autoClose: false
          });
        });
  }

  selectionChange(event: any) {
    if (event.selectedRows.length > 0) {
      this.expandedDetailKeys = [];
      this.expandedDetailKeys.push(event.selectedRows[0].dataItem?.integrationErrorId);
    }
  }

  clearSearch(item: SearchTerm) {
    item.clear();
    this.state.skip = 0;
    this.updateGrid();
  }

  updateSearch(item: SearchTerm) {
    item.save();
    this.state.skip = 0;
    this.updateGrid();
  }

  formatDate(dateValue: Date): string {
    let rc = undefined;
    if (dateValue != undefined) {
      var tmp = new Date(dateValue);
      rc = tmp.toLocaleString();
    }
    return rc;
  }

  generateJiraTicket(item: IntegrationError){
    var shortMsg = "";
    if (item.statusDescription != null && item.statusDescription != "") {
      shortMsg = item.statusDescription;
    }
    else if (item.categoryName != null && item.categoryName != "") {
      shortMsg = item.categoryName;
    }
    else {
      shortMsg = "Generated from LOSTalker Site";
    }
    this.errorService.generateJiraTicketForError(item, shortMsg)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (value) => {
          if (value == "TEST Environment. No Issue Created.") {
            this.addJiraURL = true;
            this.toastService.toastCreate("JIRA Ticket attempted to generate in Non-Production Environment. No issue sent to Jira. See logs for CreateIssue body information.", "Danger", {
              autoClose: true,
              keepAfterRouteChange: true
            });
          }
          else {
            item.jiraStoryURL = value;
            this.addJiraURL = false;
            this.toastService.toastCreate("JIRA Ticket Generated and JIRA URL added to integrationError", "Success", {
              autoClose: true,
              keepAfterRouteChange: true
            });
          }

        }, error => {
          this.addJiraURL = false;
          this.toastService.toastCreate("Failed To Generate JIRA Ticket and update JIRA URL for Error", "Warning", {
            autoClose: false
          });
        });
  }

  filterByReportFilters(errorSearch: ReportErrorSearchFilter): void {
    let filters: FilterDescriptor[] = [];
    this.gridViewIsLoading = true;
    this.selectedErrorIds = [];
    this.filteredData = this.data;

    if (errorSearch.startReceivedDate != undefined) {
      filters.push({ field: "receivedDate", operator: "gte", value: errorSearch.startReceivedDate });
    }

    if (errorSearch.endReceivedDate != undefined) {
      filters.push({ field: "receivedDate", operator: "lte", value: errorSearch.endReceivedDate });
    }

    if (errorSearch.startInitialErrorDate != undefined) {
      filters.push({ field: "initialErrorDate", operator: "gte", value: errorSearch.startInitialErrorDate });
    }

    if (errorSearch.endInitialErrorDate != undefined) {
      filters.push({ field: "initialErrorDate", operator: "lte", value: errorSearch.endInitialErrorDate });
    }

    if (errorSearch.startResolutionDate != undefined) {
      filters.push({ field: "resolutionDate", operator: "gte", value: errorSearch.startResolutionDate });
    }

    if (errorSearch.endResolutionDate != undefined) {
      filters.push({ field: "resolutionDate", operator: "lte", value: errorSearch.endResolutionDate });
    }

    if (errorSearch.logMessage != undefined) {
      filters.push({ field: "logMessage", operator: "contains", value: errorSearch.logMessage });
    }

    if (errorSearch.errorCategoryId != undefined) {
      filters.push({ field: "errorCategoryId", operator: "eq", value: errorSearch.errorCategoryId });
    }

    if (errorSearch.currentStatusId != undefined) {
      filters.push({ field: "currentStatusId", operator: "eq", value: errorSearch.currentStatusId });
    }

    if (errorSearch.importTypeId != undefined) {
      filters.push({ field: "importTypeId", operator: "eq", value: errorSearch.importTypeId });
    }

    if (errorSearch.destinationEndpointId != undefined) {
      filters.push({ field: "destinationEndpointId", operator: "eq", value: errorSearch.destinationEndpointId });
    }

    this.currentFiltersFromGrid = {
      logic: 'and',
      filters: filters
    }

    this.filteredData = filterBy(this.filteredData, this.currentFiltersFromGrid);
    let orderedData = orderBy(this.filteredData.length > 0 ? this.filteredData : this.data, this.state.sort);
    this.gridView = {
      data: orderedData.slice(this.state.skip, this.state.skip + this.state.take),
      total: orderedData.length
    };
    this.gridViewIsLoading = false;
  }

  navigateToClient(dataItem: IntegrationError) {
    if (this.clients != null && (dataItem.clientName == "" || dataItem.clientName == null)) {
      dataItem.clientName = this.clients.filter(c => c.clientId == dataItem.clientId)[0].name;
    }
    if (dataItem.clientName != null && dataItem.clientName != "") {
      this.router.navigate(['/editConfig', dataItem.clientName, dataItem.integrationName]);
    }
  }

  public saveData(component: any): void {
    component.save();
  }

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.className === 'k-column-title')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  public onCellClick(event: CellClickEvent): void {
    let findKeyState = this.checkIfIdExist(event.dataItem.integrationErrorId);
    if (findKeyState === -1) {
      this.expandedDetailKeys.push(event.dataItem.integrationErrorId);
    }
    else {
      this.expandedDetailKeys.splice(findKeyState, 1);
    }
    this.expandedDetailKeys = [...this.expandedDetailKeys];
  }

  public checkIfIdExist(id: string): number {
    return this.expandedDetailKeys.findIndex((i) => i === id);
  }

  public openBulkUpdateDialog(): void {
    this.bulkUpdateDetails.integrationErrorIdList = [...this.selectedErrorIds]
    this.dialog.open(this.bulkUpdateDialog);
  }

  public updateBulkStatus(event: any): void {
    this.bulkUpdateDetails.currentStatusId = event.value.currentStatusId;
    this.bulkUpdateDetails.statusName = event.value.statusName;
  }

  public updateExistingErrorsBulkChanges(updateScope: BulkErrorUpdateScope): void {
    updateScope.integrationErrorIdList.forEach(errorId => {
      let dataIndex = this.data.findIndex(d => d.integrationErrorId == errorId);

      if (updateScope.currentStatusId != undefined) {
        this.data[dataIndex].currentStatusId = updateScope.currentStatusId;
        this.data[dataIndex].statusName = updateScope.statusName;
        if (updateScope.statusName == "Resolved") {
          this.data[dataIndex].resolutionComment = updateScope.resolutionComment;
          if (this.data[dataIndex].resolutionDate == undefined) {
            this.data[dataIndex].resolutionDate = updateScope.resolutionDate;
          }
        }
      }

      if(updateScope.actionNeeded != undefined){
        this.data[dataIndex].actionNeeded = new Boolean(updateScope.actionNeeded).valueOf();
      }

      if (updateScope.automatedResolution != undefined) {
        this.data[dataIndex].automatedResolution = new Boolean(updateScope.automatedResolution).valueOf();
      }

      if (updateScope.criticalError != undefined) {
        this.data[dataIndex].criticalError = new Boolean(updateScope.criticalError).valueOf();
      }

      if (updateScope.jiraStoryURL != undefined && updateScope.jiraStoryURL != "") {
        this.data[dataIndex].jiraStoryURL = updateScope.jiraStoryURL;
      }

    });

  }

  public bulkUpdateErrors(): void {
    if(this.bulkUpdateDetails.statusName == "Resolved"){
      this.bulkUpdateDetails.resolutionDate = new Date();
    }

    this.errorService.bulkUpdateIntegrationErrors(this.bulkUpdateDetails)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        () => {
          this.updateExistingErrorsBulkChanges(this.bulkUpdateDetails)
          this.bulkUpdateDetails  = {
            integrationErrorIdList: []
          };
          this.selectedErrorIds = [];
          this.bulkUpdateSelectedStatus = null;
          this.updateGrid();
        }, error => {
          this.toastService.toastCreate("Failed To Update Selected errors" + error, "Warning", {
            autoClose: false
          });
        });
  }
}
