import { ClientContract } from "../models/client-contract"
import { InvoicePeriod } from "../models/invoice-period";
import { ColumnSettings } from "../models/column-settings";
import { InvoicingLoanType } from "../models/invoicing-loan-type";
import { InvoicePeriodMetrics } from "../models/invoice-period-metrics";

export class InvoicingUtils {

/**
 *
 * @param column column to hide or unhide
 * @param hidden (optional) unhide or hide; true = hide column, false = unhide column; default false (unhide)
 * @param allColumns (optional) array of all columns to check if need to unhide/hide parents
 * @returns updated column
 */
  public setHiddenForColumnAndSubColumns(column: ColumnSettings, hidden: boolean = false, allColumns: ColumnSettings[] = []) : ColumnSettings {
    // set value for col and sub cols
    var rc = this.setHiddenForSubColumns(column, hidden);

    // check parent and parent's sub cols to see if need to update parent
    if (column.isSubColumn && column.parentColumnTitle != "" && allColumns.length > 0) {
      var parent = allColumns.find(x => x.title == column.parentColumnTitle);
      if (parent != null) {
        // unhide parent if sub is unhidden
        // else if child is hidden, check if need to hide parent
        if (!hidden) {
          parent.hidden = false;
        }
        else {
          var hasSomeUnhidden = parent.subColumns.filter(x => !x.hidden);
          if (hasSomeUnhidden == null || hasSomeUnhidden.length == 0) {
            parent.hidden = true;
          }
        }
      }
    }

    return rc;
  }

  public setHiddenForSubColumns(column: ColumnSettings, hidden: boolean = false) : ColumnSettings{
    // set column hidden value
    column.hidden = hidden;
    // check if has sub columns
    if (column.hasSubColumns && column.subColumns?.length > 0) {
      // process each sub column
      column.subColumns.forEach(x => {
        x = this.setHiddenForSubColumns(x, hidden);
      });
    }
    else {
      return column;
    }

  }

    public defaultInvoicePeriodMetricsColumnSettings: ColumnSettings[] = [
      { field: 'contract.clientFullName', title: 'Client Name', sticky: true },
      { field: 'contract.identifierIdValue', title: 'Company ID', sticky: true },
      { field: 'totalLoanCount', title: 'Total Count', type: 'numeric', sticky: false },
      { field: 'loanVolume', title: 'Total Volume', type: 'numeric', format: '{0:c}', sticky: false },
      { field: 'contract.disabled', title: 'Disabled', type: 'boolean', sticky: false },
      { field: 'dateStart', title: 'Period Start', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
      { field: 'dateEnd', title: 'Period End', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
    ];

    public defaultClientInvoicePeriodMetricsGroupedColumnSettings: ColumnSettings[] = [
      { field: 'dateEnd', title: 'Period', hasSubColumns: true, sticky: true,
        subColumns: [
          { field: 'dateEndMonth', title: 'Month', sticky: true, isSubColumn: true, parentColumnTitle: 'Period', fullField: 'dateEndMonth' },
          { field: 'dateEndYear', title: 'Year', sticky: true, isSubColumn: true, parentColumnTitle: 'Period', fullField: 'dateEndYear' }
        ]
      }
    ];

    public defaultClientInvoicePeriodMetricsColumnSettings: ColumnSettings[] = [
      { field: 'contract.clientFullName', title: 'Client Name', hidden: true },
      { field: 'contract.identifierIdValue', title: 'Company ID', sticky: true },
      { field: 'totalLoanCount', title: 'Total Count', type: 'numeric' },
      { field: 'loanVolume', title: 'Total Volume', type: 'numeric', format: '{0:c}' },
    ];

    public defaultCompletedLoanColumnSettings: ColumnSettings[] = [
        { field: 'completedLoanId', title: 'Completed Loan Id', hidden: true, hideInExport: true },
        { field: 'clientContractId', title: 'Client Contract Id', hidden: true, hideInExport: true },
        { field: 'loanNumber', title: 'Loan Number', type: 'text', },
        { field: 'loanAmount', title: 'Loan Amount', type: 'numeric', pipeFormat: '1.2' },
        { field: 'loanGuid', title: 'Loan Guid', type: 'text', hidden: true, hideInExport: true },
        { field: 'channel', title: 'Channel', type: 'text', },
        { field: 'completedDate', title: 'Funded Completed', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'losUpdatedDate', title: 'LOS Updated Date', type: 'date', format: '{0:yyyy-MM-dd hh:mm a}', pipeFormat: 'yyyy-MM-dd hh:mm a' },
        { field: 'postedAsOf', title: 'PostedAsOf', type: 'date', format: '{0:yyyy-MM-dd hh:mm a}', pipeFormat: 'yyyy-MM-dd hh:mm a' },
        { field: 'lastLTSModifiedDate', title: 'Last LTS Modified Date', type: 'date', format: '{0:yyyy-MM-dd hh:mm a}', pipeFormat: 'yyyy-MM-dd hh:mm a' },
        { field: 'actionTaken', title: 'ActionTaken', type: 'text', },
        { field: 'companyNMLSNumber', title: 'Company NMLS Number', type: 'text', },
        { field: 'lienPosition', title: 'Lien Position', type: 'text', },
        { field: 'loanOfficerNMLS', title: 'LO NMLS', type: 'text', },
        { field: 'loanOfficerEmail', title: 'LO Email', type: 'text', },
        { field: 'loanPurpose', title: 'Loan Purpose', type: 'text', },
        { field: 'loanSource', title: 'Loan Source', type: 'text', },
        { field: 'actionTakenDate', title: 'Action Taken Date', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'actualClosingDate', title: 'Actual Closing Date', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'applicationDate', title: 'Application Date', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'approvedDate', title: 'Approved Date', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'createdDate', title: 'Created Date', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'disbursementDate', title: 'Disbursement Date', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'estClosingDate', title: 'Est Closing Date', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'startedDate', title: 'Started Date', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'signedDate', title: 'Signed Date', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'includeInInvoice', title: 'Include in Invoice', type: 'text' }
      ];

      public defaultInvoicedLoanColumnSettings: ColumnSettings[] = [
        { field: 'invoicedLoanId', title: 'Invoiced Loan Id', hidden: true, hideInExport: true },
        { field: 'clientContractId', title: 'Client Contract Id', hidden: true, hideInExport: true },
        { field: 'loanNumber', title: 'Loan Number', type: 'text', },
        { field: 'loanAmount', title: 'Loan Amount', type: 'numeric', pipeFormat: '1.2' },
        { field: 'loanGuid', title: 'Loan Guid', type: 'text', hidden: true, hideInExport: true },
        { field: 'channel', title: 'Channel', type: 'text', },
        { field: 'completedDate', title: 'Funded Completed', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'postedAsOf', title: 'Posted As Of', type: 'date', format: '{0:yyyy-MM-dd hh:mm a}', pipeFormat: 'yyyy-MM-dd hh:mm a' },
        { field: 'invoicedAsOf', title: 'Invoiced As Of', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'invoiceCreditDate', title: 'Invoice Credit Date', type: 'date', format: '{0:yyyy-MM-dd hh:mm a}', pipeFormat: 'yyyy-MM-dd hh:mm a', hidden: true, hideInExport: true },
        { field: 'lastLTSModifiedDate', title: 'Last LTS Modified Date', type: 'date', format: '{0:yyyy-MM-dd hh:mm a}', pipeFormat: 'yyyy-MM-dd hh:mm a' },
        { field: 'completedDateRemoved', title: 'Completed Date Removed', type: 'boolean', hidden: true, hideInExport: true },
        { field: 'lostAccess', title: 'Lost Access to Loan', type: 'boolean', hidden: true, hideInExport: true },
        { field: 'actionTaken', title: 'ActionTaken', type: 'text', },
        { field: 'companyNMLSNumber', title: 'Company NMLS Number', type: 'text', },
        { field: 'lienPosition', title: 'Lien Position', type: 'text', },
        { field: 'loanOfficerNMLS', title: 'LO NMLS', type: 'text', },
        { field: 'loanOfficerEmail', title: 'LO Email', type: 'text', },
        { field: 'loanPurpose', title: 'Loan Purpose', type: 'text', },
        { field: 'loanSource', title: 'Loan Source', type: 'text', },
        { field: 'auditComment', title: 'Audit Comment', type: 'text', hidden: true, hideInExport: true }, 
        { field: 'actionTakenDate', title: 'Action Taken Date', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'actualClosingDate', title: 'Actual Closing Date', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'applicationDate', title: 'Application Date', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'approvedDate', title: 'Approved Date', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'createdDate', title: 'Created Date', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'disbursementDate', title: 'Disbursement Date', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'estClosingDate', title: 'Est Closing Date', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'startedDate', title: 'Started Date', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'signedDate', title: 'Signed Date', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'losUpdatedDate', title: 'LOS Updated Date', type: 'date', format: '{0:yyyy-MM-dd hh:mm a}', pipeFormat: 'yyyy-MM-dd hh:mm a' },
        { field: 'includeInInvoice', title: 'Include in Invoice', type: 'text' }
      ];

      public defaultBaseLoanColumnSettings: ColumnSettings[] = [
        { field: 'loanId', title: 'Invoiced Loan Id', hidden: true, hideInExport: true },
        { field: 'invoicedLoanId', title: 'Invoiced Loan Id', hidden: true, hideInExport: true },
        { field: 'completedLoanId', title: 'Completed Loan Id', hidden: true, hideInExport: true },
        { field: 'clientContractId', title: 'Client Contract Id', hidden: true, hideInExport: true },
        { field: 'loanNumber', title: 'Loan Number', type: 'text', },
        { field: 'loanAmount', title: 'Loan Amount', type: 'numeric', pipeFormat: '1.2' },
        { field: 'loanGuid', title: 'Loan Guid', type: 'text', hidden: true, hideInExport: true },
        { field: 'channel', title: 'Channel', type: 'text', },
        { field: 'completedDate', title: 'Funded Completed', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'losUpdatedDate', title: 'LOS Updated Date', type: 'date', format: '{0:yyyy-MM-dd hh:mm a}', pipeFormat: 'yyyy-MM-dd hh:mm a' },
        { field: 'postedAsOf', title: 'Posted As Of', type: 'date', format: '{0:yyyy-MM-dd hh:mm a}', pipeFormat: 'yyyy-MM-dd hh:mm a' },
        { field: 'invoicedAsOf', title: 'Invoiced As Of', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'invoiceCreditDate', title: 'Invoice Credit Date', type: 'date', format: '{0:yyyy-MM-dd hh:mm a}', pipeFormat: 'yyyy-MM-dd hh:mm a', hidden: true, hideInExport: true },
        { field: 'lastLTSModifiedDate', title: 'Last LTS Modified Date', type: 'date', format: '{0:yyyy-MM-dd hh:mm a}', pipeFormat: 'yyyy-MM-dd hh:mm a' },
        { field: 'completedDateRemoved', title: 'Completed Date Removed', type: 'boolean', hidden: true, hideInExport: true },
        { field: 'lostAccess', title: 'Lost Access to Loan', type: 'boolean', hidden: true, hideInExport: true },
        { field: 'actionTaken', title: 'ActionTaken', type: 'text', },
        { field: 'companyNMLSNumber', title: 'Company NMLS Number', type: 'text', },
        { field: 'lienPosition', title: 'Lien Position', type: 'text', },
        { field: 'loanOfficerNMLS', title: 'LO NMLS', type: 'text', },
        { field: 'loanOfficerEmail', title: 'LO Email', type: 'text', },
        { field: 'loanPurpose', title: 'Loan Purpose', type: 'text', },
        { field: 'loanSource', title: 'Loan Source', type: 'text', },
        { field: 'auditComment', title: 'Audit Comment', type: 'text', hidden: true, hideInExport: true }, 
        { field: 'actionTakenDate', title: 'Action Taken Date', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'actualClosingDate', title: 'Actual Closing Date', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'applicationDate', title: 'Application Date', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'approvedDate', title: 'Approved Date', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'createdDate', title: 'Created Date', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'disbursementDate', title: 'Disbursement Date', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'estClosingDate', title: 'Est Closing Date', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'startedDate', title: 'Started Date', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'signedDate', title: 'Signed Date', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' }
      ];

      public defaultReportLoanColumnSettings: ColumnSettings[] = [
        { field: 'loanId', title: 'Invoiced Loan Id', hidden: true, hideInExport: true },
        { field: 'invoicedLoanId', title: 'Invoiced Loan Id', hidden: true, hideInExport: true },
        { field: 'completedLoanId', title: 'Completed Loan Id', hidden: true, hideInExport: true },
        { field: 'clientContractId', title: 'Client Contract Id', hidden: true, hideInExport: true },
        { field: 'loanNumber', title: 'Loan Number', type: 'text', },
        { field: 'loanAmount', title: 'Loan Amount', type: 'numeric', pipeFormat: '1.2'},
        { field: 'loanGuid', title: 'Loan Guid', type: 'text', hidden: true, hideInExport: true },
        { field: 'channel', title: 'Channel', type: 'text', },
        { field: 'completedDate', title: 'Funded Completed', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'losUpdatedDate', title: 'LOS Updated Date', type: 'date', format: '{0:yyyy-MM-dd hh:mm a}', pipeFormat: 'yyyy-MM-dd hh:mm a' },
        { field: 'postedAsOf', title: 'Posted As Of', type: 'date', format: '{0:yyyy-MM-dd hh:mm a}', pipeFormat: 'yyyy-MM-dd hh:mm a' },
        { field: 'invoicedAsOf', title: 'Invoiced As Of', type: 'date', format: '{0:yyyy-MM-dd}' },
        { field: 'invoiceCreditDate', title: 'Invoice Credit Date', type: 'date', format: '{0:yyyy-MM-dd hh:mm a}', pipeFormat: 'yyyy-MM-dd hh:mm a', hidden: true, hideInExport: true },
        { field: 'lastLTSModifiedDate', title: 'Last LTS Modified Date', type: 'date', format: '{0:yyyy-MM-dd hh:mm a}', pipeFormat: 'yyyy-MM-dd hh:mm a' },
        { field: 'completedDateRemoved', title: 'Completed Date Removed', type: 'boolean', hidden: true, hideInExport: true },
        { field: 'lostAccess', title: 'Lost Access to Loan', type: 'boolean', hidden: true, hideInExport: true },
        { field: 'actionTaken', title: 'ActionTaken', type: 'text', },
        { field: 'companyNMLSNumber', title: 'Company NMLS Number', type: 'text', },
        { field: 'lienPosition', title: 'Lien Position', type: 'text', },
        { field: 'loanOfficerNMLS', title: 'LO NMLS', type: 'text', },
        { field: 'loanOfficerEmail', title: 'LO Email', type: 'text', },
        { field: 'loanPurpose', title: 'Loan Purpose', type: 'text', },
        { field: 'loanSource', title: 'Loan Source', type: 'text', },
        { field: 'auditComment', title: 'Audit Comment', type: 'text', hidden: true, hideInExport: true }, 
        { field: 'actionTakenDate', title: 'Action Taken Date', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'actualClosingDate', title: 'Actual Closing Date', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'applicationDate', title: 'Application Date', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'approvedDate', title: 'Approved Date', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'createdDate', title: 'Created Date', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'disbursementDate', title: 'Disbursement Date', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'estClosingDate', title: 'Est Closing Date', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'startedDate', title: 'Started Date', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
        { field: 'signedDate', title: 'Signed Date', type: 'date', format: '{0:yyyy-MM-dd}', pipeFormat: 'yyyy-MM-dd' },
      ];

      getDefaultColumns(type: InvoicingLoanType) : ColumnSettings[] {
        if (type == InvoicingLoanType.CompletedLoan) {
            return this.defaultCompletedLoanColumnSettings;
        }
        else if (type == InvoicingLoanType.InvoicedLoan){
            return this.defaultInvoicedLoanColumnSettings;
        }
        else if (type == InvoicingLoanType.ReportLoan){
          return this.defaultReportLoanColumnSettings;
        }
        else {
            return [];
        }
      }

      isNumeric(n: any): boolean {
        return !isNaN(parseFloat(n)) && isFinite(n);
      }

      mergeMetricsForContract(data: InvoicePeriodMetrics[]): InvoicePeriodMetrics {
        var rc: InvoicePeriodMetrics = new InvoicePeriodMetrics(data[0]);
        var initialValue = 0;
        // total loan count
        let totalLoanCount = data.map(x => x.totalLoanCount)
                              .reduce((p,c) => p + c, initialValue);
        // total loan volume
        let totalLoanVolume = data.map(x => x.loanVolume)
                              .reduce((p,c) => p + c, initialValue);

        var jsonObj = {};
        // filter InvoicePeriodMetrics[] for only ones with aggregate fields
        let filtered = data.filter(x => x.jsonFieldCollection != null);

        // get all unique aggregate fields keys
        var k = Array.from(new Set(
          filtered
            .map(x => Object.keys(x.jsonFieldCollection))
            .reduce((prev, curr) => [...prev, ...curr])
        ));

        if (k != null && k.length > 0) {
          k.forEach(aggKey => {
            let aggJObj = {};

            // filter InvoicePeriodMetrics[] for only ones with current aggregate key
            let aggSubKeyMap = filtered
                  .filter(x => x.jsonFieldCollection.hasOwnProperty(aggKey))
                  .map(y => y.jsonFieldCollection[aggKey]);

            // if any InvoicePeriodMetrics[] has current aggregate key
            if (aggSubKeyMap != null && aggSubKeyMap.length > 0) {

              // get all unique sub keys for current aggregate key
              let aggSubsKeys = Array.from(new Set(
                  aggSubKeyMap
                    .map(z => Array.from(Object.keys(z)))
                    .reduce((prev, curr) => [...prev, ...curr])
              ));

              // if any sub keys for current aggregate key exist
              if (aggSubsKeys != null && aggSubsKeys.length > 0) {
                // get totals for each sub key
                aggSubsKeys.forEach(subKey => {
                  let subKeyTotal = aggSubKeyMap
                                .filter(x => x.hasOwnProperty(subKey))
                                .map(y => y[subKey])
                                .reduce((p,c) => p + c, initialValue);

                  if (subKeyTotal != null && this.isNumeric(subKeyTotal)) {
                    aggJObj[subKey] = subKeyTotal;
                  }
                  else {
                    aggJObj[subKey] = 0;
                  }
                });
              }
            }

            if (Object.keys(aggJObj).length > 0) {
              jsonObj[aggKey] = aggJObj;
            }
          });
        }

        rc.totalLoanCount = totalLoanCount;
        rc.loanVolume = totalLoanVolume;
        rc.jsonFieldCollection = <JSON>jsonObj;

        if (data.length > 0) {
          var startDates = data.map(x => x.dateStart);
          var earliestDate = this.getEarlistDate(startDates);
          rc.dateStart = earliestDate;

          var endDate = data.map(x => x.dateEnd);
          var latestDate = this.getLatestDate(endDate);
          rc.dateEnd = latestDate;
        }

        return rc;
      }

      getEarlistDate(dateList: Date[]): Date {
        if (dateList.length > 0) {
          var sorted = dateList.sort((a,b) => a.getTime() - b.getTime());
          return sorted[0];
        }
        else {
          return dateList[0];
        }
      }

      getLatestDate(dateList: Date[]): Date {
        if (dateList.length > 0) {
          var sorted = dateList.sort((a,b) => b.getTime() - a.getTime());
          return sorted[0];
        }
        else {
          return dateList[0];
        }
      }

      /**
       * Create Column Settings array of Aggregate Key columns for Invoice Period Metrics
       *  and the sub columns for sub keys of the aggregate keys
       * @param data
       * @returns ColumnSettings[]
       */
      getMetricsGroupedColumns(data: InvoicePeriodMetrics[]): ColumnSettings[] {
        var rc: ColumnSettings[] = [];

        // filter InvoicePeriodMetrics[] for only ones with aggregate fields
        let filtered = data.filter(x => x.jsonFieldCollection != null);

        if (filtered != null && filtered != undefined && filtered.length > 0) {
          // get all unique aggregate fields keys
          var k = Array.from(new Set(
            filtered
              .map(x => Object.keys(x.jsonFieldCollection))
              .reduce((prev, curr) => [...prev, ...curr])
          ));

          // if any aggregate keys exist
          if (k != null && k.length > 0) {
            k.forEach(aggKey => {

              // filter InvoicePeriodMetrics[] for only ones with current aggregate key
              let aggSubKeyMap = filtered
                    .filter(x => x.jsonFieldCollection.hasOwnProperty(aggKey))
                    .map(y => y.jsonFieldCollection[aggKey]);

              // if any InvoicePeriodMetrics[] has current aggregate key
              if (aggSubKeyMap != null && aggSubKeyMap.length > 0) {

                // get all unique sub keys for current aggregate key
                let aggSubsKeys = Array.from(new Set(
                    aggSubKeyMap
                      .map(z => Array.from(Object.keys(z)))
                      .reduce((prev, curr) => [...prev, ...curr])
                ));

                // if any sub keys for current aggregate key exist
                if (aggSubsKeys != null && aggSubsKeys.length > 0) {

                  let hiddenIfOverMaxKeyLength: boolean = aggSubsKeys.length > 10 ? true : false;

                  // create columns for each sub key of current aggregate key
                  let t = aggSubsKeys.map(subKey => new ColumnSettings({
                    field: subKey,
                    title: this.overrideTitle(subKey),
                    isSubColumn: true,
                    parentColumnTitle: aggKey,
                    fullField: this.getFullJsonField(aggKey, subKey),
                    hidden: hiddenIfOverMaxKeyLength}));

                  // if any columns created
                  if (t != null && t.length > 0) {

                    // add to array new aggregate column and it's containing sub key columns
                    rc.push(new ColumnSettings({
                      field: aggKey,
                      title: aggKey,
                      hasSubColumns: true,
                      subColumns: t,
                      hidden: hiddenIfOverMaxKeyLength
                    }));
                  }

                }
              }
            });
          }
        }

        return rc;
      }

      public getFullJsonField(s: string, t: string): string {
        // var rc = "jsonFieldCollection[" + s + "][" + t + "]";
        var rc = "jsonFieldCollection." + s + "." + t;
        return rc;
      }

      public overrideTitle(s: string): string {
        if (s == "" || s == null) {
          return "NullOrEmpty";
        }
        else {
          return s;
        }
      }

      getAggregatteKeysForMetrics(data: InvoicePeriodMetrics[]): string[] {
        var rc: string[] = [];

        // filter InvoicePeriodMetrics[] for only ones with aggregate fields
        let filtered = data.filter(x => x.jsonFieldCollection != null);

        // get all unique aggregate fields keys
        var k = Array.from(new Set(
          filtered
            .map(x => Object.keys(x.jsonFieldCollection))
            .reduce((prev, curr) => [...prev, ...curr])
        ));

        rc = k;

        return rc;
      }

      public objectToMap(o) {
        let m = new Map();

        for (let k of Object.keys(o)) {
          if (o[k] instanceof Object) {
            m.set(k, this.objectToMap(o[k]));
          }
          else {
            m.set(k, o[k]);
          }
        }
        return m;
      }

    getFieldDefsForInvoicePeriods(periods: InvoicePeriod[]): Map<string,string> {
      var rc = new Map<string,string>();
      periods = periods.filter(i => i.jsonFieldInfoCollection != null && i.jsonFieldInfoCollection.size > 0);
      if (periods.length == 1) {
        rc = periods[0].jsonFieldInfoCollection;
      }
      else if (periods.length > 1) {
        periods.forEach(u => {
          u.jsonFieldInfoCollection.forEach((value: string, key: string) => {
            // only takes first value for key
            if (!rc.has(key)) {
              rc.set(key,value);
            }
          });
        });
      }
      return rc;
    }
}
