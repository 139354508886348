import { Component, OnInit, OnDestroy, Input, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from '../../models/user';
import { MonacoPageComponent } from '../monaco-editor/monaco-editor.component';
import { BaseMappingService } from '../../services/base-mapping.service';
import { ToastService } from '../../services/toast.service';
import { BaseMapping } from 'src/app/models/base-mapping';
import { MonacoDiffEditorComponent } from '@materia-ui/ngx-monaco-editor';
import { MonacoDiffPageComponent } from '../monaco-diff-editor/monaco-diff-editor.component';
import { LOSTalkerTitleService } from "../../services/lostalker-title-service.service";
import { TypeConstantService } from 'src/app/services/type-constant.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-edit-base-mapping',
  templateUrl: './edit-base-mapping.component.html',
  styleUrls: ['./edit-base-mapping.component.scss'],
  providers: [BaseMappingService, LOSTalkerTitleService]
})
export class EditBaseMappingComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(
    private baseMappingService: BaseMappingService,
    private typeConstantService: TypeConstantService,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private location: Location,
    private user: User,
    private titleService: LOSTalkerTitleService,
    private router: Router
  ) { }

  componentDestroyed$: Subject<boolean> = new Subject();
  @ViewChild('monaco') public child: MonacoPageComponent;
  @ViewChild('monacoHistory') public historyChild: MonacoDiffPageComponent;
  baseMapping: BaseMapping;
  showHistory: boolean = false;
  convertingToGeneric: boolean = false;
  baseHistory: BaseMapping[];
  selectedHistory: BaseMapping;
  sub: any;
  historyNames: string[] = [];
  name: string;
  genericName: string;
  js: string = "javascript";
  xml: string = "xml";
  mappingSelected: boolean = false;
  mappingChanged: boolean = false;
  updateInitiated: boolean = false;
  checked: boolean = true;
  historyChecked: boolean = true;
  monacoData: string = '<xml></xml>';
  monacoHistoryData: string = '';
  monacoHistoryLanguage: string = 'xml';
  monacoOriginalData: string = '<xml/>';

  originalBaseMappingXML: string = '<xml/>';
  originalBaseMappingJS: string = '';
  pageLoading: boolean = true;
  convertToGeneric: boolean = true;
  useV2Logic: boolean = false;

  ngOnInit(): void {
    if(environment.enableAPIVersioning && environment.apiVersion == "v2")
    {
      this.useV2Logic = true;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(_ => this.initializeMonacoComponent());
  }

  initializeMonacoComponent(): void {

    this.sub = this.route.params.subscribe(params => {
      // this.getValidName(params['baseMappingId']);
      this.baseMappingService.getBaseMappingById(params['baseMappingId']).subscribe(bm => {
        this.baseMapping = bm;
        this.name = this.baseMapping.name;
        
        if (this.baseMapping != null) {
          this.titleService.setUpTitle('Edit Base Mapping - ' + this.baseMapping.name);
        }

        this.monacoOriginalData = bm.xmlConfig;

        if (true === this.checked) {
          this.monacoData = bm.xmlConfig;

        } else {
          this.monacoData = bm.javaScript;
        }
        
        this.originalBaseMappingXML = this.baseMapping.xmlConfig;
        this.originalBaseMappingJS = this.baseMapping.javaScript;

        this.genericName = this.name + "-" + this.baseMapping.endpointName + "-" + this.baseMapping.typeDescription;

        if(this.useV2Logic)
        {
          this.baseMappingService.getBaseMappingHistoryById(this.baseMapping.baseMappingId).subscribe(bh => {
            this.baseHistory = bh;
            if(this.baseHistory != null)
            {
              this.showHistoryDropDown();
            }
            this.pageLoading = false;
          }, error => {
            this.pageLoading = false;
          });
        }
        else
        {
          this.baseMappingService.getBaseMappingHistory(this.baseMapping.name, this.baseMapping.destinationEndpointId, this.baseMapping.importTypeId).subscribe(bh => {
            this.baseHistory = bh;
            if(this.baseHistory != null)
            {
              this.showHistoryDropDown();
            }
            this.pageLoading = false;
          }, error => {
            this.pageLoading = false;
          });
        }
        
      }, error => {
        this.pageLoading = false;
      });
    });
  }


  ngOnDestroy(): void {
    if (this.sub != undefined) {
      this.sub.unsubscribe();
    }
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  goBack(): void {
    this.location.back();
  }

  toggleDataXMLJS(changingToXML: boolean): void {
    if (true === changingToXML) {
      this.baseMapping.javaScript = this.monacoData;
      this.child?.changeLanguage("xml");
      this.monacoData = this.baseMapping.xmlConfig;
    } else {
      this.baseMapping.xmlConfig = this.monacoData;
      this.child?.changeLanguage("javascript");
      this.monacoData = this.baseMapping.javaScript;
    }
  }

  toggleDataHistoryXMLJS(changingToXML: boolean): void {
    if (true === changingToXML) {
      this.monacoOriginalData = this.originalBaseMappingXML;
      this.monacoHistoryData = this.selectedHistory.xmlConfig;
      this.historyChild.changeLanguage("xml");
    } else {
      this.monacoOriginalData = this.originalBaseMappingJS;
      this.monacoHistoryData = this.selectedHistory.javaScript;
      this.historyChild.changeLanguage("javascript");
    }
  }

  canDeactivate(): boolean {
    let canLeave = true;

    if (true == this.checked) {
      this.baseMapping.xmlConfig = this.monacoData;
    } else {
      this.baseMapping.javaScript = this.monacoData;
    }

    if (this.originalBaseMappingXML != this.baseMapping.xmlConfig) {
      canLeave = false;
    }

    if (this.originalBaseMappingJS != this.baseMapping.javaScript) {
      canLeave = false;
    }
    
    return canLeave;
  }

  checkAccess(role: string[]): boolean {
    if (this.user.checkAccess(role)) {
      return true;
    } else {
      return false;
    }
  }

  // getValidName(n: string): void {
  //   if (n.includes("%2F") || n.includes("/")) {
  //     this.name = n.replace("%2F", "_");
  //     this.name = n.replace("/", "_");
  //   }
  //   else {
  //     this.name = n;
  //   }
  // }

  showHistoryDropDown(): void {
    this.historyNames = [];
    for (var h of this.baseHistory) {
      var str = h.updatedDate + "-" + h.name;
      this.historyNames.push(str);
    }
    this.showHistory = true;
  }

  selectBaseHistory(name: string): void {
    this.selectedHistory = this.baseHistory.find(i => ((i.updatedDate + "-" + i.name) == name));

    if (true == this.historyChecked) {
      this.monacoHistoryData = this.selectedHistory.xmlConfig;
    } else {
      this.monacoHistoryData = this.selectedHistory.javaScript;
    }
  }

  // could reload page and omit this method
  selectBaseMapping(b: BaseMapping): void {
    this.baseMapping = b;

    if (true == this.checked) {
      this.monacoData = b.xmlConfig;
    } else {
      this.monacoData = b.javaScript;
    }

    this.mappingSelected = true;
    if (this.mappingChanged) {
      this.mappingChanged = false;
    }
    else {
      this.mappingChanged = true;
    }
    this.selectedHistory = null;
    if (this.baseHistory != null) {
      this.baseMappingService.getBaseMappingHistory(this.baseMapping.name, this.baseMapping.destinationEndpointId, this.baseMapping.importTypeId).subscribe(i => {
        this.baseHistory = i
        this.showHistoryDropDown();
      });
    }
  }

  updateDataModel($event) {
    this.monacoData = $event;
  }

  updateHistoryDataModel($event) {
    this.monacoHistoryData = $event;
  }

  updateBaseMapping(): void {
    this.updateInitiated = true;
    if (true == this.checked) {
      this.baseMapping.xmlConfig = this.monacoData;
    } else {
      this.baseMapping.javaScript = this.monacoData;
    }

    this.baseMapping.updatedDate = new Date();

    this.baseMappingService.updateBaseMapping(this.baseMapping)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        () => {
          // on success
          this.toastService.toastCreate("Successfully updated " + this.baseMapping.name + " base mapping.", "Success", {
            autoClose: false
          });
          this.originalBaseMappingJS = this.baseMapping.javaScript;
          this.originalBaseMappingXML = this.baseMapping.xmlConfig;
          this.monacoOriginalData = this.originalBaseMappingXML;

          this.updateInitiated = false;
        },
        (error) => {
          this.toastService.toastCreate("Failed to update BaseMapping " + error, "Warning", {
            autoClose: false
          });
          this.updateInitiated = false;
        });
  }

  updateBaseMappingFromHistory(): void {
    this.updateInitiated = true;
    this.baseMapping.xmlConfig = this.selectedHistory.xmlConfig;
    this.baseMapping.javaScript = this.selectedHistory.javaScript;

    // update the base mapping
    var updatedDate = this.selectedHistory.updatedDate;

    this.baseMappingService.updateBaseMapping(this.baseMapping)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        () => {
          // on success
          this.toastService.toastCreate("Successfully reverted " + this.baseMapping.name + " to " + updatedDate + ".", "Success", {
            autoClose: false
          });
          this.originalBaseMappingJS = this.baseMapping.javaScript;
          this.originalBaseMappingXML = this.baseMapping.xmlConfig;
          this.selectBaseMapping(this.baseMapping);

          if (this.historyChecked) {
            this.monacoOriginalData = this.originalBaseMappingXML;
          }
          else{
            this.monacoOriginalData = this.originalBaseMappingJS;
          }

          // update page
          this.selectedHistory = undefined;
          this.showHistory = false;
          this.updateInitiated = false;

        },
        (error) => {
          this.toastService.toastCreate("Failed to update BaseMapping " + error, "Warning", {
            autoClose: false
          });
          this.updateInitiated = false;
        });
  }

  createGenericEndpoint(): void {
    this.convertingToGeneric = true;
    this.baseMappingService.convertBaseMappingToGeneric(this.baseMapping, this.genericName)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (id) => {
          this.router.navigate(["/editGenericEndpoint/" + id]);
        }
      )

  }
}
