import { Client } from './client';
import { IntegrationJavaScript } from './integration-javascript';
import { IntegrationRunTime } from './integration-runtime';
import { IntegrationInformation } from './integration-information';

export class Integration {

  constructor(i?: IntegrationInformation) {
    this.integrationId = i?.integrationId;
    this.clientId = i?.clientId;
    this.clientName = i?.clientName;
    this.dataSourceTypeId = i?.dataSourceType;
    this.name = i?.integrationName;
    this.locked = i?.locked;
    this.serverId = i?.serverId;
    this.errorRetentionDays = i?.errorRetentionDays;
    this.selfHosted = i?.selfHosted;
    this.updatedDate = i?.integrationUpdatedDate;
  }

  clientId: number;
  clientName: string;
  integrationId: string;
  name: string;
  dataSourceTypeId: string;
  xmlConfig?: string;
  javaScript?: IntegrationJavaScript[];
  updatedDate: Date;
  locked: boolean;
  serverId: string;
  ipRestricted: boolean;
  integrationTypesAgg?: string;
  deleted?: boolean;
  errorRetentionDays: number;
  selfHosted: boolean;

  //runTimes?: RunTime[];
  client?: Client;
  integrationRunTimes?: IntegrationRunTime[];

  clientContractIds?: string[];
}

export class IntegrationResponseMessage {
  integrationId: string;
  message: string;
  status: string;
}

export class IntegrationFlags {
  deleted?: boolean;
  locked?: boolean;
  serverId?: string;
}

