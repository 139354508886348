import { Component, OnInit, OnChanges, OnDestroy, Input, Output, ViewChild, EventEmitter, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { State, DataSourceRequestState, orderBy, CompositeFilterDescriptor, filterBy, SortDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent, GridComponent, SelectionEvent, RowArgs } from '@progress/kendo-angular-grid';
import { Observable, Subscription, Subject } from 'rxjs';
import { takeUntil } from "rxjs/operators";
import { Integration } from '../../models/integration';
import { SearchTerm } from '../../models/search-term';
import { Client } from '../../models/client';
import { ClientIntegrationService } from "../../services/client-integration.service";
import { ErrorLoggingService } from 'src/app/services/logging.service';
import { ToastService } from '../../services/toast.service';
import { ServiceError } from 'src/app/models/service-error';
import { ErrorCategory } from 'src/app/models/error-category';
import { CurrentStatus } from 'src/app/models/currentStatus';
import { ImportType } from 'src/app/models/import-type';

const flatten = (filter) => {
    const filters = filter.filters;
    if (filters) {
        return filters.reduce(
            (acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]),
            []
        );
    }
    return [];
};

@Component({
  selector: 'app-service-error-list-grid',
  templateUrl: './service-error-list-grid.component.html',
  styleUrls: ['./service-error-list-grid.component.scss'],
})
export class ServiceErrorListGridComponent implements OnInit, OnDestroy {

  componentDestroyed$: Subject<boolean> = new Subject();
  @ViewChild("errorGrid") public errorGrid: GridComponent;
  //clients: Client[];
  hasData = false;

  @Input() data: ServiceError[] = [];
  @Input() clients: Client[];
  @Input() loading: boolean; // show loading icon from parent
  @Input() categories: ErrorCategory[];
  @Input() statuses: CurrentStatus[];
  @Input() importTypes: ImportType[];
  @Output() getUpdated = new EventEmitter<string>(); // ask parent for updated data

  selectedError: ServiceError;
  selectedRow: RowArgs;
  selectedRows: RowArgs[] = [];
  public expandedDetailKeys: any[] = [];
  public expandDetailsBy = (dataItem: ServiceError): any => {
    return dataItem.serviceErrorId;
  };

  gridView: GridDataResult;
  gridViewIsLoading = true;
  filters: CompositeFilterDescriptor;
  currentFiltersFromGrid: CompositeFilterDescriptor;
  public state: DataSourceRequestState = {
      skip: 0,
      take: 10,
      sort: []
  };

  filteredData: ServiceError[];
  // search vars
  public stackSearchTerm: SearchTerm;
  public logSearchTerm: SearchTerm;

  // error status vars
  public statusSearchTerm: string = "";
  hasStatusFilter = false;
  statusSelectedFilter: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private clientService: ClientIntegrationService,
    private errorService: ErrorLoggingService,
    private toastService: ToastService,
  ) {
    this.state.sort = [{ field: "receivedDate", dir: 'desc' }];
    this.filteredData = [];
    this.stackSearchTerm = new SearchTerm();
    this.logSearchTerm = new SearchTerm();
  }

  ngOnInit(): void {
    this.refresh();
  }

  public refresh(): void {
    if (this.data != null) {
      if (this.data.length > 0) {
        this.mapData();
        this.updateFilteredData();
      }
      else
      {
        this.gridView = {data: [], total: 0};
        this.gridViewIsLoading = false;
      }
    }
  }

  public mapData(): void {
    if (this.data.length > 0) {
      this.data.forEach(x => x.receivedDate = new Date(x.receivedDate));

        this.data.forEach(x => {

            if (this.categories != null && this.categories.length > 0) {
              let currentCategory = this.categories.find(c => c.errorCategoryId == x.errorCategoryId);
              x.errorCategoryName = currentCategory?.categoryName;
              x.errorCategoryPriority = currentCategory?.categoryPriority;
            }

            if (this.statuses != null && this.statuses.length > 0) {
              x.currentStatusName = this.statuses.find(s => s.currentStatusId == x.currentStatusId)?.statusName;
            }
        });
    }
    this.updateGrid();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.updateFilteredData();
  }

  pageChange(state: DataStateChangeEvent): void {
    this.state.skip = state.skip;
    this.state.take = state.take;
    this.updateFilteredData();
  }

  sortChange(sort: SortDescriptor[]) {
    this.state.sort = sort;
    this.updateFilteredData();
  }

  updateFilteredData(filterFromGrid: CompositeFilterDescriptor = null): void {
    if (this.data != null) {
      this.filteredData = [];
      this.filteredData = this.data;

      if (filterFromGrid != null) {
        this.currentFiltersFromGrid = filterFromGrid;
        this.filteredData = filterBy(this.filteredData, this.currentFiltersFromGrid); // combine with below?
      }
      else if (filterFromGrid == null && this.currentFiltersFromGrid != null) {
        this.filteredData = filterBy(this.filteredData, this.currentFiltersFromGrid);
      }

      if (this.stackSearchTerm.hasSearchTerm) {
        this.filteredData = filterBy(this.filteredData, {
          logic: 'and',
          filters: [
            { field: "stackTrace", operator: "contains", value: this.stackSearchTerm.currentSearchValue, ignoreCase: true }
          ]
        });
      }

      if (this.logSearchTerm.hasSearchTerm) {
        this.filteredData = filterBy(this.filteredData, {
          logic: 'and',
          filters: [
            { field: "logMessage", operator: "contains", value: this.logSearchTerm.currentSearchValue, ignoreCase: true }
          ]
        });
      }

      if (this.hasStatusFilter) {
        this.filteredData = filterBy(this.filteredData, {
          logic: 'and',
          filters: [
            { field: "errorCategoryId", operator: "eq", value: this.statusSearchTerm, ignoreCase: true }
          ]
        });
      }

      this.updateGrid();
    }
    else {
      this.gridViewIsLoading = false;
    }
  }

  updateError(error: ServiceError, currentStatusName: string) {
    this.selectedError = error;
    this.selectedError.currentStatusId = this.statuses.find(s => s.statusName == currentStatusName)?.currentStatusId;

    if (currentStatusName == "Resolved") {
        this.selectedError.criticalError = false;
    }

    this.errorService.updateServiceError(this.selectedError)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(
      (value) => {
        //this.getUpdated.emit('true');
        this.updateFilteredData();
      });
  }

  statusFilterChange(checked: string): void {
    this.state.skip = 0;
    this.hasStatusFilter = true;
    this.statusSearchTerm = checked;
    this.updateFilteredData();
  }

  clearStatusFilter(): void {
    this.statusSearchTerm = null;
    this.hasStatusFilter = false;
    this.updateFilteredData();
  }

  clearFilters() {
    this.currentFiltersFromGrid = null;
    this.stackSearchTerm.clear();
    this.logSearchTerm.clear();
    this.statusSearchTerm = null;
    this.hasStatusFilter = false;
    this.updateFilteredData();
  }

  updateGrid() {
    let orderedData = orderBy(this.filteredData ? this.filteredData : this.data, this.state.sort);
    this.gridView = {
        data: orderedData.slice(this.state.skip, this.state.skip + this.state.take),
        total: orderedData.length
    };
    this.gridViewIsLoading = false;
  }

  selectionChange(event: SelectionEvent) {
    if (event.selectedRows.length > 0) {
      this.expandedDetailKeys = [];
      this.expandedDetailKeys.push(event.selectedRows[0].dataItem?.serviceErrorId);
    }
  }

  clearSearch(item: SearchTerm) {
    item.clear();
    this.state.skip = 0;
    this.updateFilteredData();
  }

  updateSearch(item: SearchTerm) {
    item.save();
    this.state.skip = 0;
    this.updateFilteredData();
  }

  formatDate(error: ServiceError): string {
    var tmp = new Date(error.receivedDate);
    return tmp.toLocaleString();
  }

  navigateToIntegrationsByServer(dataItem: ServiceError) {
    this.router.navigate(['/serverintegrations', dataItem.serverId]);
  }

  public saveData(component: any): void {
    component.save();
  }

}
