export const environment = {
  production: true,
  enableDebuggingTools: false,
  enableImpersonation: true,
  enableAPIVersioning: false,
  apiVersion: "v1",
  baseURL: "https://www.lostalker.com",
  apiIdentifier: "api://1c21de89-9dc5-40de-b3f2-5521523aa9b9",
  spaClientId: "83597d3d-af8f-4383-bb8a-19dc5a5e7b68",
  azureTenantId: "0bf31a5e-7914-4ea5-b3d0-5832f9d20378",
  environmentName: "Prod"
};
