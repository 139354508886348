<ng-container *ngIf="isInTab == false">
  <h1 style="text-align:center;">Client Contracts</h1>
</ng-container>
<ng-container *ngIf="isInTab == true">
  <h4 style="text-align:center;">Client Contracts</h4>
</ng-container>
<br />

<br>
<br>

<kendo-grid #grid [data]="gridView" [pageSize]="state.take" [skip]="state.skip"
  [sortable]="{mode: 'single',allowUnsort: false}" [pageable]="true" [scrollable]="'scrollable'" [sort]="state.sort"
  [selectable]="true" [filter]="filter" [filterable]="true" (sortChange)="sortChange($event)"
  (pageChange)="pageChange($event)" (selectionChange)="expand($event)"
  (filterChange)="filterChange($event)">

  <kendo-grid-column field="losTalkerClientId" title="Go To" [width]="115" [filterable]="false" [sortable]="false">
    <ng-template kendoGridCellTemplate let-dataItem>
      <button mat-flat-button color="primary" (click)="navigateToClient(dataItem)">Navigate</button>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="losTalkerClientId" title="Client ID" [width]="100">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [showOperators]="true" [column]="column" [filter]="filter">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="clientFullName" title="Name" [width]="200">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [showOperators]="true" [column]="column" [filter]="filter">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="clientShortName" title="Short Name" [width]="150">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [showOperators]="true" [column]="column" [filter]="filter">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="endpointName" title="Endpoint Name" [width]="140">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [showOperators]="true" [column]="column" [filter]="filter">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="identifierIdValue" title="Identifier ID" [width]="110">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [showOperators]="true" [column]="column" [filter]="filter">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="identifierNameValue" title="Identifier Name" [width]="175">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [showOperators]="true" [column]="column" [filter]="filter">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="disabled" title="Disabled" [width]="100">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [showOperators]="true" [column]="column" [filter]="filter">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="comments" title="Comments" [width]="100">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [showOperators]="true" [column]="column" [filter]="filter">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="currentCompletedField" title="Completed Date Field" [width]="150">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [showOperators]="true" [column]="column" [filter]="filter">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="updatedDate" title="Updated Date" [width]="150" [filterable]="false">
    <ng-template #running kendoGridCellTemplate let-dataItem>
      <span [innerHTML]="formatUpdatedDate(dataItem)"></span>
    </ng-template>
  </kendo-grid-column>


  <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
    <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
    <kendo-pager-info></kendo-pager-info>
    <kendo-pager-next-buttons></kendo-pager-next-buttons>
  </ng-template>

  <ng-template kendoGridNoRecordsTemplate>
    <div class="empty_spacing_for_norecords">
      <div class="k-i-loading" *ngIf="gridViewIsLoading"></div>
      <div class="no-records-message" *ngIf="!gridViewIsLoading && gridView">No records found.</div>
      <div class="no-records-message" *ngIf="!gridViewIsLoading && !gridView">Loading failed.</div>
    </div>
  </ng-template>

</kendo-grid>

<button type="button" class="k-button" (click)="saveData(excelexport)">
  Export To Excel
</button>
<kendo-excelexport [data]="contractsList" [collapsible]="true" fileName="ClientContracts.xlsx" #excelexport>
  <kendo-excelexport-column field="losTalkerClientId" title="Client ID"></kendo-excelexport-column>
  <kendo-excelexport-column field="clientFullName" title="Name"></kendo-excelexport-column>
  <kendo-excelexport-column field="clientShortName" title="Short Name"></kendo-excelexport-column>
  <kendo-excelexport-column field="clientDeleted" title="Client Deleted"></kendo-excelexport-column>
  <kendo-excelexport-column field="endpointName" title="Endpoint Name"></kendo-excelexport-column>
  <kendo-excelexport-column field="destinationEndpointId" title="Endpoint ID"></kendo-excelexport-column>
  <kendo-excelexport-column field="identifierIdValue" title="Identifier Id"></kendo-excelexport-column>
  <kendo-excelexport-column field="identifierNameValue" title="Identifier Name"></kendo-excelexport-column>
  <kendo-excelexport-column field="identifierKeyValue" title="Identifier Key"></kendo-excelexport-column>
  <kendo-excelexport-column field="disabled" title="Disabled"></kendo-excelexport-column>
  <kendo-excelexport-column field="recreateInvoicesForAuditPeriod" title="Recreate Invoices For Audit Period"></kendo-excelexport-column>
  <kendo-excelexport-column field="comments" title="Comments"></kendo-excelexport-column>
  <kendo-excelexport-column field="currentCompletedField" title="Completed Date"></kendo-excelexport-column>
  <kendo-excelexport-column field="updatedDate" title="Updated Date"></kendo-excelexport-column>
</kendo-excelexport>