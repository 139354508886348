import { ClientContract } from "./client-contract";
import { InvoicePeriod } from "./invoice-period";
import { InvoicingUtils } from "../utilities/invoicing-utilities";

export class InvoicePeriodMetrics {
    invoicingUtils?: InvoicingUtils;

    invoicePeriodMetricsId: string;
    invoicePeriodId: string;
    totalLoanCount: number;
    loanVolume: number;

    destinationEndpointId: number;

    contract?: ClientContract = null;
    invoicePeriod?: InvoicePeriod = null;

    jsonFieldCollection: JSON;

    dateStart?: Date;
    dateEnd?: Date;
    invoiceDate?: Date;

    dateEndMonth?: string;
    dateEndYear?: string;

    clientContractId?: string;

    constructor(init?: Partial<InvoicePeriodMetrics>, c?: ClientContract, p?: InvoicePeriod) {
      this.invoicingUtils = new InvoicingUtils();

      this.invoicePeriodMetricsId = init.invoicePeriodMetricsId;
      this.invoicePeriodId = init.invoicePeriodId;
      this.totalLoanCount = init.totalLoanCount;
      this.loanVolume = init.loanVolume;

      this.destinationEndpointId = init.destinationEndpointId;

      if (init?.clientContractId != null) {
        this.clientContractId = init.clientContractId;
      }

      if (c != null) {
        this.contract = c;
        this.clientContractId = c.clientContractId;
      }
      else if (init?.contract != null) {
        this.contract = init.contract;
        this.clientContractId = init.contract.clientContractId;
      }
      else {
        this.contract = null;
      }

      if (p != null) {
        this.invoicePeriod = p;
      }
      else if (init?.invoicePeriod != null) {
        this.invoicePeriod = init.invoicePeriod;
      }
      else {
        this.invoicePeriod = null;
      }

      if (init.jsonFieldCollection != null) {
        this.jsonFieldCollection = init.jsonFieldCollection;
      }

      if (init?.dateStart != null) {
        this.dateStart = new Date(init.dateStart)
      }
      else {
        this.dateStart = null;
      }

      if (init?.dateEnd != null) {
        this.dateEnd = new Date(init.dateEnd)
        this.dateEndMonth = this.dateEnd.toLocaleString('default', {month: 'long'});
        this.dateEndYear = this.dateEnd.toLocaleString('default', {year: 'numeric'});
      }
      else {
        this.dateEnd = null;
      }

      if (init?.invoiceDate != null) {
        this.invoiceDate = new Date(init.invoiceDate)
      }
      else {
        this.invoiceDate = null;
      }

    }
  }

