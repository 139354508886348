import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription, forkJoin, Subject } from "rxjs";
import { GridDataResult } from "@progress/kendo-angular-grid";
import { ExpansionPanelComponent } from '@progress/kendo-angular-layout';
import { BaseMappingService } from "../../services/base-mapping.service";
import { User } from "../../models/user";
import { takeUntil } from "rxjs/operators";
import { BaseMapping } from "src/app/models/base-mapping";
import { ClientIntegrationService } from "src/app/services/client-integration.service";
import { ImportType } from "src/app/models/import-type";
import { DestinationEndpoint } from "src/app/models/destination-endpoint";
import { TypeConstantService } from "src/app/services/type-constant.service";

@Component({
  selector: "app-base-mapping",
  templateUrl: "./base-mapping.component.html",
  styleUrls: ["./base-mapping.component.scss"],
  providers: [BaseMappingService],
})
export class BaseMappingComponent implements OnInit, OnDestroy {

  // Child components
  surefire3BaseMappingPanelExpanded: boolean = false;
  loanBaseMappingPanelExpanded: boolean = false;
  branchBaseMappingPanelExpanded: boolean = false;
  employeeBaseMappingPanelExpanded: boolean = false;
  totalExpertBaseMappingPanelExpanded: boolean = false;

  // Grid data
  surefire3BaseMappingGrid: GridDataResult;
  loanBaseMappingGrid: GridDataResult;
  employeeBaseMappingGrid: GridDataResult;
  branchBaseMappingGrid: GridDataResult;
  totalExpertBaseMappingGrid: GridDataResult;

  // Constants
  importTypes: ImportType[];
  destinations: DestinationEndpoint[];

  csDataSwapDestinationId: number;
  surefire3DestinationId: number;
  totalExpertDestinationId: number;

  loanImportTypeId: number;
  branchImportTypeId: number;
  employeeImportTypeId: number;

  // Status variables
  backgroundLoading: boolean = true;
  sf3Loaded: boolean = false;
  totalExpertLoaded: boolean = false;
  csdLoanLoaded: boolean = false;
  csdEmployeeLoaded: boolean = false;
  csdBranchLoaded: boolean = false;

  // Component variables
  private sub: any;
  paramStr: string;
  componentDestroyed$: Subject<boolean> = new Subject();

  constructor(
    private typeConstantService: TypeConstantService,
    protected baseMappingService: BaseMappingService,
    private route: ActivatedRoute,
    private router: Router,
    private user: User
  ) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      if (params['id'] !== null && params['id'] !== '') {
        this.paramStr = params['id'];
      }
      else {
        this.paramStr = 'sf3baseMapping';
      }
    });
    this.triggerExpansion();
    forkJoin({
        destinationsData: this.typeConstantService.getAllDestinationEndpoints(),
        importTypesData: this.typeConstantService.getAllImportTypes()
    })
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(({ destinationsData, importTypesData}) => {
      this.destinations = destinationsData;

      for(let destination of this.destinations)
      {
        if(destination.endpointName == "CSDataSwap")
        {
          this.csDataSwapDestinationId = destination.destinationEndpointId;
        }
        else if(destination.endpointName == "Surefire3")
        {
          this.surefire3DestinationId = destination.destinationEndpointId;
        }
        else if(destination.endpointName == "TotalExpert")
        {
          this.totalExpertDestinationId = destination.destinationEndpointId;
        }
      }

      this.importTypes = importTypesData;

      for(let importType of this.importTypes)
      {
        if(importType.typeDescription == "Loan")
        {
          this.loanImportTypeId = importType.importTypeId;
        }
        else if(importType.typeDescription == "Branch")
        {
          this.branchImportTypeId = importType.importTypeId;
        }
        else if(importType.typeDescription == "Employee")
        {
          this.employeeImportTypeId = importType.importTypeId;
        }
      }

      this.backgroundLoading = false;
      this.loadExpandedPanels();

    });
  }

  triggerExpansion(paramStr: string = this.paramStr) {
    if(this.backgroundLoading)
    {
      switch (paramStr) {
        case 'sf3baseMapping':
          this.surefire3BaseMappingPanelExpanded = true;
          break;
        case 'tebaseMapping':
          this.totalExpertBaseMappingPanelExpanded = true;
          break;
        case 'csdLoanMapping':
          this.loanBaseMappingPanelExpanded = true;
          break;
        case 'csdEmployeeMapping':
          this.employeeBaseMappingPanelExpanded = true;
          break;
        case 'csdBranchMapping':
          this.branchBaseMappingPanelExpanded = true;
          break;
      }
    }
    else
    {
      switch (paramStr) {
        case 'sf3baseMapping':
          this.loadSF3BaseMappings();
          this.surefire3BaseMappingPanelExpanded = true;
          break;
        case 'tebaseMapping':
          this.loadTotalExpertBaseMappings();
          this.totalExpertBaseMappingPanelExpanded = true;
          break;
        case 'csdLoanMapping':
          this.loadCSDataSwapLoanBaseMappings();
          this.loanBaseMappingPanelExpanded = true;
          break;
        case 'csdEmployeeMapping':
          this.loadCSDataSwapEmployeeBaseMappings();
          this.employeeBaseMappingPanelExpanded = true;
          break;
        case 'csdBranchMapping':
          this.loadCSDataSwapBranchBaseMappings();
          this.branchBaseMappingPanelExpanded = true;
          break;
      }
    }
  }

  loadExpandedPanels() {
    if(this.surefire3BaseMappingPanelExpanded)
    {
      this.loadSF3BaseMappings();
    }
    if(this.totalExpertBaseMappingPanelExpanded)
    {
      this.loadTotalExpertBaseMappings();
    }
    if(this.employeeBaseMappingPanelExpanded)
    {
      this.loadCSDataSwapEmployeeBaseMappings();
    }
    if(this.loanBaseMappingPanelExpanded)
    {
      this.loadCSDataSwapLoanBaseMappings();
    }
    if(this.branchBaseMappingPanelExpanded)
    {
      this.loadCSDataSwapBranchBaseMappings();
    }

  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

  goToSelection(n: string, routerUrl: string): void {
    if (this.checkAccess(["LOSTalker.BaseMapping"])) {
      this.router.navigate([routerUrl, n]);
    }
  }

  selectionChange(event: any): void {
    let selectedBaseMapping = event.selectedRows[0].dataItem;
    this.goToSelection(selectedBaseMapping.baseMappingId, "/editBaseMapping");
  }

  checkAccess(role: string[]): boolean {
    if (this.user.checkAccess(role)) {
      return true;
    } else {
      return false;
    }
  }

  validateUrl(name: string): string {
    name = encodeURI(name);
    if (name.includes("/")) {
      name = name.replace("/", "%2F");
    }
    return name;
  }

  loadSF3BaseMappings(): void {
    if(!this.sf3Loaded)
    {
      this.baseMappingService.getBaseMappingsByDestination(this.surefire3DestinationId).subscribe(baseMappings => {
        this.surefire3BaseMappingGrid = {data: baseMappings, total: baseMappings.length };
        this.sf3Loaded = true;
      });
    }
  }

  loadTotalExpertBaseMappings(): void {
    if(!this.totalExpertLoaded)
    {
      this.baseMappingService.getBaseMappingsByDestination(this.totalExpertDestinationId).subscribe(baseMappings => {
        this.totalExpertBaseMappingGrid = {data: baseMappings, total: baseMappings.length };
        this.totalExpertLoaded = true;
      });
    }
  }

  loadCSDataSwapLoanBaseMappings(): void {
    if(!this.csdLoanLoaded)
    {
      this.baseMappingService.getBaseMappingsByDestinationAndImportType(this.csDataSwapDestinationId, this.loanImportTypeId).subscribe(baseMappings => {
        this.loanBaseMappingGrid = {data: baseMappings, total: baseMappings.length };
        this.csdLoanLoaded = true;
      });
    }
  }

  loadCSDataSwapEmployeeBaseMappings(): void {
    if(!this.csdEmployeeLoaded)
    {
      this.baseMappingService.getBaseMappingsByDestinationAndImportType(this.csDataSwapDestinationId, this.employeeImportTypeId).subscribe(baseMappings => {
        this.employeeBaseMappingGrid = {data: baseMappings, total: baseMappings.length };
        this.csdEmployeeLoaded = true;
      });
    }
  }

  loadCSDataSwapBranchBaseMappings(): void {
    if(!this.csdBranchLoaded)
    {
      this.baseMappingService.getBaseMappingsByDestinationAndImportType(this.csDataSwapDestinationId, this.branchImportTypeId).subscribe(baseMappings => {
        this.branchBaseMappingGrid = {data: baseMappings, total: baseMappings.length };
        this.csdBranchLoaded = true;
      });
    }
  }

  stopPropagation(event: Event): void {
    event.stopPropagation();
  }
}
