import { Component, OnInit, OnDestroy, Input, Output, ViewChild, ViewEncapsulation, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { forkJoin, Subscription } from 'rxjs';
import { process } from "@progress/kendo-data-query";
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { State, DataResult, DataSourceRequestState, SortDescriptor, orderBy, filterBy, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { ClientContractService } from "src/app/services/client-contract.service";
import { ClientInformationService } from "src/app/services/client-information.service";
import { ClientIntegrationService } from '../../../services/client-integration.service';
import { ToastService } from '../../../services/toast.service';
import { ReportService } from '../../../services/report.service';
import { Report } from '../../../models/report';
import { Server } from '../../../models/server';
import { DestinationEndpoint } from '../../../models/destination-endpoint';
import { ImportType } from '../../../models/import-type';
import { DataSourceType } from '../../../models/data-source-type';
import { IntegrationType } from '../../../models/integration-type';
import { Integration } from '../../../models/integration';
import { Toast, ToastType } from '../../../models/toast';
import { GridDataResult, DataStateChangeEvent, GridComponent, PageChangeEvent, RowArgs, ExcelModule, SelectionEvent } from '@progress/kendo-angular-grid';
import { Client } from "src/app/models/client";
import { ClientContract } from "src/app/models/client-contract";
import { ClientInformation } from "src/app/models/client-information";
import { IntegrationIdentifier } from "src/app/models/integration-identifier";
import { LOSTalkerTitleService } from '../../../services/lostalker-title-service.service';
import { DEFAULT_IFRAME_TIMEOUT_MS } from '@azure/msal-browser/dist/config/Configuration';

const flatten = filter => {
  const filters = filter.filters;
  if (filters) {
    return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};

@Component({
  selector: 'client-contract-report',
  templateUrl: './client-contract-report.component.html',
  styleUrls: ['./client-contract-report.component.scss'],
  providers: [ClientIntegrationService, ReportService, ToastService, ClientInformationService, ClientContractService]
})
export class ClientContractReportComponent implements OnInit {
  @Input() reportId = "";
  @Input() currentReportToShow: Report;

  @ViewChild("grid") public grid: GridComponent;

  //Constants
  _currReport: Report;
  type: 'numeric' | 'input' = 'input';
  isInTab: boolean;
  excelFileName: string = "ClientContracts.xlsx";

  //Table Filter Variables
  statusSelectedFilter: any;
  hasStatusFilter: boolean = false;
  public statusFilter: CompositeFilterDescriptor;
  public state: DataSourceRequestState = {
    skip: 0,
    take: 10,
    sort: []
  };
  totalClientContracts: number;
  rowSelected: RowArgs;
  selectedContract: ClientContract;
  gridViewIsLoading = true;
  gridView: GridDataResult;
  contractsList: ClientContract[];
  filter: CompositeFilterDescriptor;
  filteredGroup: ClientContract[];
  filteredData: ClientContract[];

  private serviceSubscription: Subscription;

  constructor(
    protected _clientService: ClientIntegrationService,
    protected _reportService: ReportService,
    protected _toastService: ToastService,
    protected _clientContractService: ClientContractService,
    protected _clientInformationService: ClientInformationService,
    private location: Location,
    private router: Router,
    private titleService: LOSTalkerTitleService) {

    this.filteredData = [];

    this.state.sort = [{ field: "name", dir: 'asc' }];
    this.contractsList = [];
  }

  ngOnInit(): void {
    this.currentReportToShow = new Report(this.currentReportToShow);

    if (this.currentReportToShow) {
      this.titleService.setUpTitle('Report - ' + this.currentReportToShow.title);

      this._currReport = this.currentReportToShow;

      if (this._currReport.exportFileName !== ""
        && this._currReport.exportFileName !== undefined) {
        this.excelFileName = this._currReport.exportFileName;
      }
    }

    if (this._currReport?.dataSproc !== "" && this._currReport?.dataSproc !== undefined) {
      this.getClientContractData();
    }
  }

  public getClientContractData() {
    this.gridViewIsLoading = true;
    this.gridView = {
      total: 0,
      data: []
    }

    this._reportService.getClientContractTypeQueryData(this.currentReportToShow.reportId)
    .subscribe(
      (data) => {
        this.contractsList = data;

        if(this.filter == null) {
          this.loadDataIntoGridView();
        }
        else {
          this.filterChange(this.filter);
        }

        this.gridViewIsLoading = false;
      },
      error => {
        this.gridViewIsLoading = false;
      }
    );
  }

  public goBack(e: Event) {
    this.location.back();
  }

  ngOnDestroy(): void {
    if (this.serviceSubscription) {
        this.serviceSubscription.unsubscribe();
    }
  }

  expand(event: SelectionEvent) {
    if (this.rowSelected !== undefined) {
        this.grid.collapseRow(this.rowSelected.index);
    }
    this.rowSelected = event.selectedRows[0];
    this.grid.expandRow(event.selectedRows[0].index);
  }

  navigateToClient(dataItem: ClientContract) {
    //Pull real client name
    this._clientService.getClientById(dataItem.losTalkerClientId)
    .subscribe((client) => {
      let clientName = encodeURI(client.name).replace("(", "%28").replace(")", "%29");

      //Get mapping if it exists
      this._clientContractService.getIntegrationMappingsByClientContractId(dataItem.clientContractId)
      .subscribe((mapping) => {
        //If not mapped go to client page
        if (mapping == null || mapping.length > 1) {
          this.router.navigate(["/editConfig", clientName]);
        }
        //If mapped go to integration page
        else {
          this._clientService.getIntegrationById(mapping[0].integrationId)
          .subscribe((integration) => {
            let integrationName = encodeURI(integration.name).replace("(", "%28").replace(")", "%29");
            this.router.navigate(["/editConfig", clientName, integrationName, 0]);
          });
        }
      });
    });
  }

  formatUpdatedDate(contract: ClientContract): string {
    var tmp = new Date(contract.updatedDate);
    tmp.setMinutes(tmp.getMinutes() - tmp.getTimezoneOffset());
    return tmp.toLocaleString();
  }

  pageChange(state: DataStateChangeEvent): void {
    this.state.skip = state.skip;
    this.state.take = state.take;
    this.updateGrid();
  }

  loadDataIntoGridView() {
    this.gridView = {
        data: this.contractsList.slice(this.state.skip, this.state.skip + this.state.take),
        total: this.contractsList.length
    };
  }

  updateGrid() {
    let orderedData = orderBy(this.filteredGroup ? this.filteredGroup : this.contractsList, this.state.sort);
    this.gridView = {
      data: orderedData.slice(this.state.skip, this.state.skip + this.state.take),
      total: orderedData.length
    };
  }

  public saveData(component: any): void {
    component.save();
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.state.skip = 0;
    this.filter = filter;
    this.filteredGroup = filterBy(this.contractsList, filter);
    let orderedData = orderBy(this.filteredGroup, this.state.sort);
    this.gridView = {
        data: orderedData.slice(this.state.skip, this.state.skip + this.state.take),
        total: orderedData.length
    };
  }

  sortChange(sort: SortDescriptor[]): void {
    this.state.sort = sort;

    this.updateGrid();
  }
}
