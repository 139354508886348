export class InvoicingDetails {
    clientContractIds?: string[];
    dateStart: Date;
    dateEnd: Date;
    includeDisabled?: boolean;

    constructor(init?: Partial<InvoicingDetails>) {
        this.clientContractIds = init?.clientContractIds;

        if (init.dateStart != null) {
            this.dateStart = new Date(init.dateStart)
        }

        if (init.dateEnd != null) {
            this.dateEnd = new Date(init.dateEnd)
        }

        this.includeDisabled = true;
    }
}